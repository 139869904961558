import { Grid, ThemeProvider, Typography, createTheme, useTheme } from "@mui/material";

const Footer = () => {
    const theme = useTheme();
    const defaultMaterialTheme = createTheme();

    const bgColor = theme.palette.mode === "dark" ? theme.palette.background.paper : "#f5f5f5"; // Background color for dark and light modes
    const textColor = theme.palette.text.primary;

    return (
        <ThemeProvider theme={defaultMaterialTheme}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: bgColor,
                    color: textColor,
                    padding: "12px",
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    boxShadow: theme.palette.mode === "dark" ? "0px 0px 10px rgba(255, 255, 255, 0.1)" : "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    zIndex: 1000,
                }}
            >
                <Typography variant="body2" align="center">
                {/* Powered by Qseap Infotech Pvt Ltd */}
                Powered by armor360
                </Typography>
            </Grid>
        </ThemeProvider>
    );
};

export default Footer;
