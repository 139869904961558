import LoadingButton from "@mui/lab/LoadingButton";
import { InputLabel, Stack, TextField, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { dispatch, useSelector } from "store";
import {
  generateScanReport,
  getScanReportHistory,
} from "store/slices/scanReducer";

const GenerateReport = ({ reportFileFormat, handleCloseDialog, scanName }) => {
  const theme = useTheme();
  const [reportName, setReportName] = useState(scanName + " Report");
  const params = useParams();
  const clientOrgId = useMemo(() => sessionStorage.getItem("clientOrgId"), []);
  const setDataloading = useSelector((state) => state?.userState?.setDataloading);

  const handleGenerateReport = useCallback(() => {
    const payload = {
      reportName: reportName,
      scanId: params?.scanId,
      clientOrgId: clientOrgId,
      reportFileFormat: reportFileFormat,
    };
    dispatch(generateScanReport(payload))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getScanReportHistory(params?.scanId, clientOrgId));
          handleCloseDialog();
        }
      })
      .catch(() => {});
  }, [reportName, params, clientOrgId, reportFileFormat, handleCloseDialog]);

  const buttonSx = useMemo(() => ({
    boxShadow: theme.customShadows.secondary,
    ":hover": {
      boxShadow: "none",
    },
  }), [theme]);

  const handleReportNameChange = useCallback((e) => {
    setReportName(e.target.value);
  }, []);

  return (
    <>
      <Stack spacing={2}>
        <InputLabel>Report Name</InputLabel>
        <TextField
          size="large"
          type="text"
          fullWidth
          value={reportName}
          onChange={handleReportNameChange}
        />
      </Stack>

      <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
        <LoadingButton
          size="large"
          variant="contained"
          color="secondary"
          sx={buttonSx}
          onClick={handleGenerateReport}
          loading={setDataloading}
        >
          Generate
        </LoadingButton>
      </Stack>
    </>
  );
};

export default GenerateReport;
