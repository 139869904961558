import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch } from "store";
import { getAffectedAssets } from "store/slices/scanReducer";
import { addVulnerability } from "store/slices/vulnerabilityReducer";
import Accordion from "ui-component/extended/Accordion";
import VmTable from "ui-component/table";
import { NO_VALUE } from "constant";

const AddAffectedAssets = (props) => {
  const [selectedIds, setSelectedIds] = useState();
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [affectedAssetsList, setAffectedAssetsList] = useState([]);
  const { addVulnerabilityRepose } = useSelector(
    (state) => state?.vulnerabilityState
  );
  const clientOrgId = sessionStorage.getItem("clientOrgId");
  const { affectedAssets } = useSelector((state) => state?.scansState);
  useEffect(() => {
    dispatch(getAffectedAssets(params?.scanId, clientOrgId));
  }, []);
  useEffect(() => {
    setAffectedAssetsList(affectedAssets?.data);
  }, [affectedAssets]);
  // const basicData = [
  //   {
  //     id: 'basic1',
  //     title: 'Filters',
  //     disabled: false,
  //     defaultExpand: true,
  //     icon: <FilterAltIcon />,
  //     // content: (
  //     //   <AssetsFiltersAccordion
  //     //     initialFilers={initialFilers}
  //     //     setUnmappedAssetsList={setUnmappedAssetsList}
  //     //     filters={filters}
  //     //     setFilters={setFilters}
  //     //   />
  //     // ),
  //   },
  // ];
  const columns = useMemo(
    () => [
      {
        accessorKey: "assetName",
        header: "Asset Name",
        size: 150,
      },
      {
        accessorKey: "assetTypeName",
        header: "Asset Type",
        size: 150,
      },
      {
        accessorKey: "assetSubTypeName",
        header: "Asset Sub Type",
        Cell: ({ cell, row }) => (
          <>
            {row?.original?.assetSubTypeName
              ? row?.original?.assetSubTypeName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "assetURL",
        header: "IP Address/URL",
        size: 150,
        Cell: ({ row }) => (
          <>{row?.original?.assetURL ? row?.original?.assetURL : NO_VALUE}</>
        ),
      },
    ],
    []
  );
  const handleChangeRowSelect = (selectedIds) => {
    setSelectedIds(selectedIds);
  };
  const handleSubmit = () => {
    const payload = {
      ...props?.vulnerabilityDetails,
      assetIdList: selectedIds,
      scanId: params?.scanId,
      vmId: props?.vulnerabilityDetails?.vulnerabilityId,
      owaspcategory: props?.vulnerabilityDetails?.owaspCategory,
    };
    delete payload?.vulnerabilityId;
    dispatch(addVulnerability(payload))
      .then((res) => {
        if (res.status === 200) {
          handleGoBack();
        }
      })
      .catch(() => {});
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  const AssetsList = affectedAssetsList?.map((val) => {
    return { ...val.assetDetails };
  });

  return (
    <>
      {" "}
      {/* <Accordion data={basicData} defaultExpandedId={true} /> */}
      <VmTable
        enableRowSelection={true}
        columns={columns}
        data={AssetsList || []}
        selectedIdsField={"assetId"}
        selectedIds={selectedIds}
        handleChangeRowSelect={handleChangeRowSelect}
      />
      <Stack
        mt={3}
        justifyContent={"flex-end"}
        width={"100%"}
        direction={"row"}
        spacing={2}
      >
        <Button size="large" variant="outlined" onClick={handleGoBack}>
          Cancel
        </Button>
        <Button
          disabled={!selectedIds?.length}
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            boxShadow: theme.customShadows.secondary,
            ":hover": {
              boxShadow: "none",
            },
          }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Stack>
    </>
  );
};

export default AddAffectedAssets;
