import { memo, useMemo } from 'react';

// material-ui
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import LogoSection from '../LogoSection';
import MenuList from '../MenuList';

import { LAYOUT_CONST } from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';

import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import MiniDrawerStyled from './MiniDrawerStyled';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const { layout, drawerType } = useConfig();
  const { logout, user } = useAuth();
//   const handleLogout = async () => {
//     await logout();
//   };

// // Set a flag when the page is being unloaded
// window.addEventListener('beforeunload', function () {
//   // Only set the flag if the tab is being closed or the window is being unloaded, not just on a refresh.
//   if (document.visibilityState === 'hidden') {
//       localStorage.setItem('shouldClear', 'true');
//   }
// });

// // Handle the actual clearing of local storage and logout
// if (localStorage.getItem('shouldClear') === 'true') {
//   handleLogout().then(() => {
//       localStorage.clear();
//       localStorage.setItem('shouldClear', 'false');
//   });
// }
  const logo = (
    // <Box sx={{ display: 'flex', p: 2, mx: 'auto', bgcolor: theme.palette.mode === "dark" ? "dark.800" : "#455f95", }}>
    <Box sx={{ display: 'flex', mx: 'auto', bgcolor: theme.palette.mode === "dark" ? "#000000" : "#000000" }}>
      <LogoSection />
    </Box>
  )
  const drawerContent = (
    <>
      <MenuList />
    </>
  );

  const drawerSX = {
    paddingLeft: drawerOpen ? '16px' : 0,
    paddingRight: drawerOpen ? '16px' : 0,
    // marginTop: drawerOpen ? 0 : '20px',
  };

  const drawer = useMemo(
    () => (
      <>
        {matchDownMd ? (
          <Box sx={{

            ...drawerSX,

          }}>{drawerContent}</Box>
        ) : (
          <PerfectScrollbar
            component="div"
            style={{
              // background: "#455f95",
              background: theme.palette.mode === "dark" ? "#000000" : "#000000",
              height: !matchUpMd
                ? 'calc(100vh - 56px)'
                : 'calc(100vh - 88px)',
              ...drawerSX,
            }}
          >
            {drawerContent}
          </PerfectScrollbar>
        )}
      </>
    ),
    // eslint-  sable-next-line react-hooks/exhaustive-deps
    [matchUpMd, drawerOpen, drawerType, theme.palette.mode]
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      {matchDownMd ||
        (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
        <>
          <Drawer
            variant={'temporary'}
            anchor="left"
            open={drawerOpen}
            onClose={() => dispatch(openDrawer(!drawerOpen))}
            sx={{
              '& .MuiDrawer-paper': {
                mt: 0,
                zIndex: 1099,
                width: drawerWidth,
                // background: '#455f95',
                background: theme.palette.mode === "dark" ? "#000000" : "#000000",
                // background: theme.palette.background.default,
                color: theme.palette.text.primary,
                borderRight: 'none',
              },
            }}
            ModalProps={{ keepMounted: true }}
            color="inherit"
          >
            {/* {matchDownMd && logo} */}
            {logo}
            {drawer}
          </Drawer>
        </>
      ) : (
        <>
          <MiniDrawerStyled variant="permanent" open={drawerOpen}>
            {logo}
            {drawer}
          </MiniDrawerStyled>
        </>
      )}
    </Box>
  );
};

export default memo(Sidebar);
