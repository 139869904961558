import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "store";
import { getUserDetail } from "store/slices/user";

const DisplayLoginUserName = () => {
  const { loginUserDetail, orgDetail } = useSelector(
    (state) => state?.userState
  );
  useEffect(() => {
    dispatch(getUserDetail());
  }, []);
  return (
    <Box>
      <Typography
        // pl={3}
        color="white"
      >
        {loginUserDetail?.displayName}
      </Typography>
      <Typography
        // pl={3}
        color="grey"
        fontSize={{ xs: 10, md: 12 }} // Adjust font size for responsiveness
      >
        {orgDetail?.organizationName}
      </Typography>
    </Box>
  );
};

export default DisplayLoginUserName;
