import { memo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Divider, List, Typography, useMediaQuery } from "@mui/material";

// project imports
import NavItem from "./NavItem";
// import menu from 'menu-items';
import NavGroup from "./NavGroup";
import useConfig from "hooks/useConfig";

import { useSelector } from "store";
import { LAYOUT_CONST } from "constant";
import { HORIZONTAL_MAX_ITEM } from "config";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const theme = useTheme();
  const { layout } = useConfig();
  const { drawerOpen, menu } = useSelector((state) => state.menu);
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  // last menu-item to show in horizontal menu bar
  const lastItem =
    layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
      ? HORIZONTAL_MAX_ITEM
      : null;
  let lastItemIndex = menu?.length - 1;
  let remItems = [];
  let lastItemId;
  if (lastItem && lastItem < menu?.length) {
    lastItemId = menu[lastItem - 1].menuCode;
    lastItemIndex = lastItem - 1;
    remItems = menu.slice(lastItem - 1, menu?.length)?.map((item) => {
      return {
        menuDesc: item.menuDesc,
        elements: item.menuChildren,
        menuIcon: item.menuIcon,
        ...(item.menuPath && {
          remItems: item.menuPath,
        }),
      };
    });
  }

  const navItems = menu.slice(0, lastItemIndex + 1)?.map((item) => {
    if (item?.menuChildren?.length) {
      if (
        !item?.menuChildren?.length &&
        item.menuPath &&
        item.menuCode !== lastItemId
      ) {
        return (
          <List
            key={item.menuCode}
            {...(drawerOpen &&
              layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT &&
              {
                //   sx: { mt: 1.5 },
              })}
            disablePadding={
              !drawerOpen || layout === LAYOUT_CONST.HORIZONTAL_LAYOUT
            }
          >
            <NavItem item={item} level={1} isParents />
          </List>
        );
      }
      return (
        <NavGroup
          key={item.menuCode}
          item={item}
          lastItem={lastItem}
          remItems={remItems}
          lastItemId={lastItemId}
        />
      );
    } else {
      if (item.menuPath && item.menuCode !== lastItemId) {
        return (
          <List
            key={item.menuCode}
            {...(drawerOpen && layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && {})}
            disablePadding={
              !drawerOpen || layout === LAYOUT_CONST.HORIZONTAL_LAYOUT
            }
          >
            <NavItem item={item} level={1} isParents />
          </List>
        );
      }
      return (
        <NavGroup
          key={item.menuCode}
          item={item}
          lastItem={lastItem}
          remItems={remItems}
          lastItemId={lastItemId}
        />
      );
    }
  });
  return layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
    (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
    <Box {...(drawerOpen && { sx: { mt: 1.5} })}>{navItems}</Box>
  ) : (
    <>{navItems}</>
  );
};

export default memo(MenuList);
