// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { isLoading, setLoading } from "store/slices/user";
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
const version = "v1";
const commonEndpoint = "user-group/";
const userGroupEndpoint = "user-group";
const initialState = {
  error: null,
  selectedUserGroupDetailsTab: 0,
  unMappedUserList: [],
  mappedUserList: [],
  actionGroups: [],
  menuGroups: [],
  allGroupMenu: [],
  allGroupRoles: [],
  addUserUnmappedGroupResponse: {},
  addUserMappedGroupResponse: {},
  userGroupDetails: {},
  addUserGroupResponse: {},
  updateActionGroupResponse: {},
};

const slice = createSlice({
  name: "userGroup",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    manageSelectedUserGroupDetailsTab(state, action) {
      state.selectedUserGroupDetailsTab = action.payload;
    },

    // --------------------- GET APIs --------------------------------
    // GET USER_GROUP_LIST
    getAllUserSuccess(state, action) {
      state.allUserGroups = action.payload;
    },
    // GET USER_GROUP
    getUserGroupSuccess(state, action) {
      state.userGroupDetails = action.payload;
    },
    // GET UNMAPPED_USER_GROUP
    getUnMappedUserGroupSuccess(state, action) {
      state.unMappedUserList = action.payload;
    },
    // GET MAPPED_USER_GROUP
    getMappedUserGroupSuccess(state, action) {
      state.mappedUserList = action.payload;
    },
    getActionGroupSuccess(state, action) {
      state.actionGroups = action.payload;
    },
    updateMenuGroupSuccess(state, action) {
      state.menuGroups = action.payload;
    },
    updateGroupNameSuccess(state, action) {
      state.menuGroups = action.payload;
    },
    getUserGroupMenus(state, action) {
      state.allGroupMenu = action.payload;
    },
    getUserGroupRoles(state, action) {
      state.allGroupRoles = action.payload;
    },
    // --------------------- POST APIs --------------------------------
    // ADD USER_GROUP
    addUserGroupSuccess(state, action) {
      state.addUserGroupResponse = action.payload;
    },
    addUnMappedUserGroupSuccess(state, action) {
      state.addUserUnmappedGroupResponse = action.payload;
    },
    addMappedUserGroupSuccess(state, action) {
      state.addUserMappedGroupResponse = action.payload;
    },

    // --------------------- DELETE APIs --------------------------------
    // UPDATE_USER_GROUP
    updateActionGroupSuccess(state, action) {
      state.updateActionGroupResponse = action.payload;
    },
    // --------------------- DELETE APIs --------------------------------
    // DELETE USER_GROUP
    deleteUserGroupSuccess(state, action) {
      state.addUserGroupResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const manageSelectedUserGroupDetailsTab = (tab) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(slice.actions.manageSelectedUserGroupDetailsTab(tab));
    try {
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const addUserGroup = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.post(
        `${userGroupApiEndpoint}/${version}/${userGroupEndpoint}`,
        payload
      );
      dispatch(slice.actions.addUserGroupSuccess(response));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const deleteUserGroup = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.post(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}`,
        { data: payload }
      );
      dispatch(slice.actions.deleteUserGroupSuccess(response));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getAllUserGroup = (params) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}list`,
        { params }
      );
      dispatch(slice.actions.getAllUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getUserGroup = (userGroupId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}${userGroupId}`
      );
      dispatch(slice.actions.getUserGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getUnMappedUserGroup = (params) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}unmapped-user/${params?.userGroupId}`,
        { params }
      );
      dispatch(slice.actions.getUnMappedUserGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getMappedUserGroup = (params) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}mapped-user/${params?.userGroupId}`,
        { params }
      );
      dispatch(slice.actions.getMappedUserGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const addUnMappedUserGroup = (userGroupId, payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.post(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}unmap-users/${userGroupId}`,
        payload
      );
      dispatch(slice.actions.addUnMappedUserGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const addMappedUserGroup = (userGroupId, payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.post(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}map-users/${userGroupId}`,
        payload
      );
      dispatch(slice.actions.addMappedUserGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getActionGroup = (allowAction) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}${
          allowAction === "allow" ? "user-actions" : "user-actions-deny"
        }`
      );
      dispatch(slice.actions.getActionGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const updateActionGroupData = (payload, actionType) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.put(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}${
          actionType === "allow" ? "action-codes" : "action-codes-deny"
        }`,
        payload
      );

      dispatch(slice.actions.updateActionGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const updateMenuGroupData = (payload, actionType) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.put(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}${
          actionType === "allow" ? "menu-codes" : "menu-codes-deny"
        }`,
        payload
      );

      dispatch(slice.actions.updateMenuGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getUserGroupMenu = (allowAction) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/user-group/${
          allowAction === "allow" ? "menus" : "menus-deny"
        }`
      );

      dispatch(slice.actions.getUserGroupMenus(response.data.menus));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const updateGroupName = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.put(
        `${userGroupApiEndpoint}/${version}/${userGroupEndpoint}`,
        payload
      );

      dispatch(slice.actions.updateGroupNameSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchUserGroupRoles = () => {
  return async () => {
    dispatch(setLoading(true));

    const userGroupApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? "consultant"
        : localStorage.getItem("organizationType") === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/user-role/list`
      );
      dispatch(slice.actions.getUserGroupRoles(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
