import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeIcon from "@mui/icons-material/Home";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { IconDashboard } from "@tabler/icons-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// import { DocumentScannerIcon as DocumentScannerIcon } from '@mui/icons-material';
// material-ui
import {
  Avatar,
  ButtonBase,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import { IconAffiliate, IconHeartRateMonitor } from "@tabler/icons-react";
import { LAYOUT_CONST } from "constant";
import useConfig from "hooks/useConfig";
import { useDispatch, useSelector } from "store";
import { activeID, activeItem, openDrawer } from "store/slices/menu";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //
const NavItem = ({ item, level, parentId, isParents = false }) => {
  const theme = useTheme();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(["dashboard"]);
  const [isSelected, setIsSelected] = useState(false);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { layout, borderRadius } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const iconMapping = {
    AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
    DocumentScannerIcon: <DocumentScannerIcon />,
    PeopleOutlineIcon: <PeopleOutlineIcon />,
    CorporateFareIcon: <CorporateFareIcon />,
    HomeIcon: <HomeIcon />,
    IconDashboard: <IconDashboard />,
    TextSnippetIcon: <TextSnippetIcon />,
    HomeRepairServiceOutlinedIcon: <HomeRepairServiceOutlinedIcon />,
    CropFreeOutlinedIcon: <CropFreeOutlinedIcon />,
    AssessmentIcon: <AssessmentIcon />,
    InsightsIcon: <InsightsIcon />,
    IconHeartRateMonitor: <IconHeartRateMonitor />,
    IconAffiliate: <IconAffiliate />,
  };
  useEffect(() => {
    setSelectedItem([location.pathname]);
    setIsSelected(location.pathname.includes(item?.menuCode));
  }, [location]);
  const Icon = item?.menuIcon;
  const itemIcon = item?.menuIcon ? (
    <Icon
      stroke={1.5}
      size={drawerOpen ? "20px" : "24px"}
      style={{
        color: isSelected
          ? theme.palette.secondary.main
          : theme.palette.text.primary,
        ...(layout === LAYOUT_CONST.HORIZONTAL_LAYOUT &&
          isParents && { fontSize: 20, stroke: "1.5" }),
      }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        color: isSelected
          ? theme.palette.secondary.main
          : theme.palette.text.primary,
        width:
          selectedItem.findIndex((id) => id === item?.menuCode) > -1 ? 8 : 6,
        height:
          selectedItem.findIndex((id) => id === item?.menuCode) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  const itemHandler = (id) => {
    dispatch(openDrawer(false));
    dispatch(activeItem([id]));
    // if (matchesSM) dispatch(openDrawer(false));
    dispatch(activeID(parentId));
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.menuCode);
    if (currentIndex > -1) {
      dispatch(activeItem([item.menuCode]));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const subTextColor = theme.palette.mode === "dark" ? "black" : "inherit";
  const iconSelectedColor =
    theme.palette.mode === "dark" && drawerOpen ? "text.primary" : "white";

  return (
    <>
      {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
      (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <>
          <ListItemButton
            component={Link}
            to={item.menuPath}
            target={itemTarget}
            disabled={item.disabled}
            disableRipple={!drawerOpen}
            sx={{
              zIndex: 1201,
              borderRadius: `${borderRadius}px`,
              mb: 0.5,
              pl: drawerOpen ? `${level * 24}px` : 1.25,
              ...(drawerOpen &&
                level === 1 &&
                theme.palette.mode !== "dark" && {
                  "&:hover": {
                    background: theme.palette.secondary.light,
                    "& .MuiListItemText-root .MuiTypography-root": {
                      color: "black",
                    },
                  },
                  "&.Mui-selected": {
                    background: theme.palette.secondary.light,
                    color: iconSelectedColor,
                    "& .MuiListItemText-root .MuiTypography-root": {
                      color: "black",
                    },
                    "&:hover": {
                      color: iconSelectedColor,
                      background: theme.palette.secondary.light,
                    },
                  },
                }),
              ...((!drawerOpen || level !== 1) && {
                py: level === 1 ? 0 : 1,
                "&:hover": {
                  bgcolor: "#e3f2fd",
                  "& .MuiListItemText-root .MuiTypography-root": {
                    color: "black",
                  },
                },
                "&.Mui-selected": {
                  "&:hover": {
                    bgcolor: "#455f95",
                    "& .MuiListItemText-root .MuiTypography-root": {
                      color: "black",
                    },
                  },
                  bgcolor: "#455f95",
                },
              }),
              display: !drawerOpen && "flex",
              flexDirection: !drawerOpen && level == 1 && "column",
              // alignItems: !drawerOpen && "center",
            }}
            selected={isSelected}
            onClick={() => itemHandler(item.menuCode)}
          >
            <ButtonBase
              aria-label="theme-icon"
              sx={{ borderRadius: `${borderRadius}px` }}
              disableRipple={drawerOpen}
            >
              <ListItemIcon
                sx={{
                  minWidth: level === 1 ? 36 : 18,
                  color: level === 1 ? iconSelectedColor : textColor,
                  // color: "white",
                  ...(!drawerOpen &&
                    level === 1 && {
                      borderRadius: `${borderRadius}px`,
                      width: 46,
                      height: 46,
                      alignItems: "center",
                      justifyContent: "center",
                      // "&:hover": {
                      //   bgcolor:
                      //     theme.palette.mode === "dark"
                      //       ? theme.palette.secondary.main + 25
                      //       : "secondary.light",
                      // },
                      ...(isSelected && {
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? theme.palette.secondary.main + 25
                            : "secondary.light",
                        // "&:hover": {
                        //   bgcolor:
                        //     theme.palette.mode === "dark"
                        //       ? theme.palette.secondary.main + 30
                        //       : "secondary.light",
                        // },
                      }),
                    }),
                }}
              >
                {iconMapping[item.menuIcon]}
              </ListItemIcon>
            </ButtonBase>

            {(drawerOpen || !drawerOpen) && (
              <>
                <ListItemText
                  primary={
                    <Typography
                      variant={isSelected ? "h5" : "body1"}
                      color={
                        level == 1
                          ? "white"
                          : drawerOpen
                          ? "white"
                          : subTextColor
                      }
                      sx={
                        level == 1
                          ? {
                              whiteSpace: "pre-wrap", // Allow wrapping within words
                              overflowWrap: "break-word", // Break words when needed
                              textAlign: "center", // Center the text horizontally
                              fontSize: drawerOpen ? "0.8rem" : "0.7rem",
                              "&:hover": {
                                color: "black  !important",
                              },
                            }
                          : ""
                      }
                    >
                      {item.menuDesc}
                    </Typography>
                  }
                  secondary={
                    item.caption && (
                      <Typography
                        variant="caption"
                        sx={{ ...theme.typography.subMenuCaption }}
                        display="block"
                        gutterBottom
                      >
                        {item.caption}
                      </Typography>
                    )
                  }
                />
              </>
            )}

            {drawerOpen && item.chip && (
              <Chip
                color={item.chip.color}
                variant={item.chip.variant}
                size={item.chip.size}
                label={item.chip.label}
                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
              />
            )}
          </ListItemButton>
        </>
      ) : (
        <ListItemButton
          component={Link}
          to={item.menuPath}
          target={itemTarget}
          disabled={item.disabled}
          {...(isParents && {
            onClick: () => {
              dispatch(activeID(item.menuCode));
            },
          })}
          sx={{
            borderRadius: isParents ? `${borderRadius}px` : 0,
            mb: isParents ? 0 : 0.5,
            alignItems: "flex-start",
            backgroundColor: level > 1 ? "transparent !important" : "inherit",
            py: 1,
            pl: 2,
            mr: isParents ? 1 : 0,
          }}
          selected={isSelected}
          onClick={() => itemHandler(item.menuCode)}
        >
          <ListItemIcon
            sx={{
              my: "auto",
              minWidth: !item?.menuIcon ? 18 : 36,
            }}
          >
            {iconMapping[item.menuIcon]}
          </ListItemIcon>

          <ListItemText
            primary={
              <Typography variant={isSelected ? "h5" : "body1"} color="inherit">
                {item.menuDesc}
              </Typography>
            }
            secondary={
              item.caption && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.subMenuCaption }}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )
            }
          />

          {item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  parentId: PropTypes.string,
  isParents: PropTypes.bool,
};

export default NavItem;
