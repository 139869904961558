// third-party
import { createSlice } from "@reduxjs/toolkit";
import { openSnackbar } from "./snackbarReducer";
// project imports
import { isLoading, setLoading } from "store/slices/user";
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";

// ----------------------------------------------------------------------
const vulnerabiltyMasterApi = "/platform/v1/vulnerability-master";
const initialState = {
  error: null,
  vulnerbilityMasterList: [],
  serviceTypeList: [],
  vulnerabiltySans25: [],
  addNewVulnerbility: {},
  uploadVulnerbility: {},
  selectedData: {},
  updatedData: {},
  riskRatingList: [],
  easeOfExploitationList: [],
  OwaspCategoriesList: [],
  deleteVulnerability: "",
  downlaodVulnerabilityMastertemplateResponse: {},
};

const slice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET VULNERBILITY MASTER LIST
    getVulnerbilityMasterSuccess(state, action) {
      state.vulnerbilityMasterList = action.payload;
    },

    // GET SERVICE TYPE LIST
    getServiceTypeSuccess(state, action) {
      state.serviceTypeList = action.payload;
    },

    // GET SANS25 LIST
    getVulnerabiltySans25(state, action) {
      state.vulnerabiltySans25 = action.payload;
    },

    // POST NEW VULNERBILITY
    addNewVulnerbilitySuccess(state, action) {
      state.addNewVulnerbility = action.payload;
    },
    // POST NEW VULNERBILITY
    uploadVulnerbilitySuccess(state, action) {
      state.uploadVulnerbility = action.payload;
    },

    // GET SELECTED VULNERBILITY DATA
    getselectedVulnerbilityDataSuccess(state, action) {
      state.selectedData = action.payload;
    },
    // GET UPDATED VULNERBILITY DATA
    updateNewVulnerbilitySuccess(state, action) {
      state.updatedData = action.payload;
    },

    // GET SELECTED VULNERABILTY
    deleteVulnerabilitySuccess(state, action) {
      state.deleteVulnerability = action.payload;
    },

    // GET RIST_RATING_LIST
    riskRatingListSuccess(state, action) {
      state.riskRatingList = action.payload;
    },

    // GET OWASP_CATEGORY_LIST
    OwaspCategoriesListSuccess(state, action) {
      state.OwaspCategoriesList = action.payload;
    },

    // GET EASE_OF_EXPLOITATION_LIST
    easeOfExploitationListSuccess(state, action) {
      state.easeOfExploitationList = action.payload;
    },

    // DOWNLOAD VULNERABILITY MASTER TEMPLATE
    downlaodvulnerabilityMastertemplateSuccess(state, action) {
      state.downlaodVulnerabilityMastertemplateResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { downlaodvulnerabilityMastertemplateSuccess } = slice.actions;

export const fetchVulnerbilityMasterList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(`${vulnerabiltyMasterApi}/list`, {
        params,
      });
      dispatch(slice.actions.getVulnerbilityMasterSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchServiceType = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${vulnerabiltyMasterApi}/service-type`);
      dispatch(slice.actions.getServiceTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchVulnerbilitySans25 = (serviceTypeId) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${vulnerabiltyMasterApi}/SANS25/{serviceType}?serviceType=${serviceTypeId}`
      );
      dispatch(slice.actions.getVulnerabiltySans25(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addNewVulnerbility = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${vulnerabiltyMasterApi}`, payload);
      dispatch(slice.actions.addNewVulnerbilitySuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const uploadNewVulnerbility = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${vulnerabiltyMasterApi}/upload`,
        payload
      );
      dispatch(slice.actions.uploadVulnerbilitySuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getSelectedVulnerbilityData = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${vulnerabiltyMasterApi}/${payload.vulnerabilityId}`
      );
      dispatch(slice.actions.getselectedVulnerbilityDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const UpdateNewVulnerbility = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(`${vulnerabiltyMasterApi}`, payload);
      dispatch(slice.actions.updateNewVulnerbilitySuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteVulnerability = (id) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.delete(`${vulnerabiltyMasterApi}/${id}`);
      dispatch(slice.actions.deleteVulnerabilitySuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(openSnackbar(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getRiskRatingList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${vulnerabiltyMasterApi}/risk-ratings`);
      dispatch(slice.actions.riskRatingListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getEaseOfExploitationList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${vulnerabiltyMasterApi}/ease-of-exploitation`
      );
      dispatch(slice.actions.easeOfExploitationListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getOwaspCategoriesList = (serviceTypeId) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${vulnerabiltyMasterApi}/owasp-categories/{serviceType}?serviceType=${serviceTypeId}`
      );
      dispatch(slice.actions.OwaspCategoriesListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const downlaodvulnerabilityMastertemplate = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${vulnerabiltyMasterApi}/vul-template/download`
      );
      dispatch(
        slice.actions.downlaodvulnerabilityMastertemplateSuccess(response.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
