import { lazy } from 'react';
import {
  Navigate,
  createHashRouter
} from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// project import
import Loadable from 'ui-component/Loadable';
const ErrorPage = Loadable(lazy(() => import('views/pages/common/errorPage')));

// ==============================|| ROUTING RENDER ||============================== //

const router = createHashRouter(
  [
    {
      path: '/',
      errorElement: <ErrorPage />,
      element: <Navigate to="login" />,
    },
    LoginRoutes,
    MainRoutes,
  ],
  {
    basename: process.env.REACT_APP_BASE_NAME,
  }
);

export default router;
