import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    errorContainer: {
        padding: theme.spacing(2),
        backgroundColor: "#ffebee",
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(2),
    },
    errorText: {
        color: "#f44336",
    },
}));

export default function ErrorDisplay({ errorDetails }) {
    const classes = useStyles();

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Typography >Processed : {errorDetails?.processed || 0}</Typography>
                <Typography >Valid : {errorDetails?.valid || 0}</Typography>
                <Typography >Invalid : {errorDetails?.invalid || 0}</Typography>
            </div>
            <div className={classes.errorContainer}>
                <Typography variant="h6" className={classes.errorText}>
                    Errors:
                </Typography>
                {errorDetails?.errors?.map((error, index) => (
                    <Typography variant="body1" className={classes.errorText} key={index}>
                        {error}
                    </Typography>
                ))}
            </div>
        </>
    );
}
