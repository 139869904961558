import PropTypes from "prop-types";

// material-ui
import {
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ==============================|| FORM CONTROL SELECT ||============================== //
const FormControlSelect = ({
  placeholder,
  captionLabel,
  itemsList,
  formState,
  iconPrimary,
  iconSecondary,
  selected,
  textPrimary,
  textSecondary,
  onChange,
  name,
  optionLabel,
  optionValue,
  errors,
  ...props
}) => {
  const theme = useTheme();
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? (
    <IconPrimary fontSize="small" sx={{ color: theme.palette.grey[700] }} />
  ) : null;

  const IconSecondary = iconSecondary;
  const secondaryIcon = iconSecondary ? (
    <IconSecondary fontSize="small" sx={{ color: theme.palette.grey[700] }} />
  ) : null;

  return (
    <FormControl fullWidth>
      <TextField
        id="outlined-select-currency"
        select
        fullWidth
        name={name}
        placeholder={name}
        sx={{
          textTransform: "capitalize",
        }}
        label={captionLabel}
        onChange={onChange}
        {...props}
        InputProps={{
          startAdornment: (
            <>
              {primaryIcon && (
                <InputAdornment position="start">{primaryIcon}</InputAdornment>
              )}
              {textPrimary && (
                <>
                  <InputAdornment position="start">
                    {textPrimary}
                  </InputAdornment>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                </>
              )}
            </>
          ),
          endAdornment: (
            <>
              {secondaryIcon && (
                <InputAdornment position="end">{secondaryIcon}</InputAdornment>
              )}
              {textSecondary && (
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <InputAdornment position="end">
                    {textSecondary}
                  </InputAdornment>
                </>
              )}
            </>
          ),
        }}
      >
        {itemsList?.map((option, index) => (
          <MenuItem
            {...props}
            key={index}
            value={option[optionValue]}
            sx={{
              textTransform: "capitalize",
            }}
          >
            {option[optionLabel]}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

FormControlSelect.propTypes = {
  captionLabel: PropTypes.string,
  itemsList: PropTypes.array,
  formState: PropTypes.string,
  iconPrimary: PropTypes.object,
  iconSecondary: PropTypes.object,
  selected: PropTypes.string,
  textPrimary: PropTypes.string,
  textSecondary: PropTypes.string,
};

export default FormControlSelect;
