import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import accountReducer from "store/accountReducer";
import { CHANGE_PASSWORD, LOGIN, LOGOUT } from "store/actions";

// project imports
import { useSelector } from "react-redux";
import Loader from "ui-component/Loader";
import axios from "utils/axios";

import { PORTAL_TYPE } from "constant";
import { notificationManager } from "ui-component/notificationManager";

const chance = new Chance();

// constant
const initialState = {
  isInitialized: false,
  isLoggedIn: false,
  organizationId: "",
  organizationType: "",
  passwordExpired: "",
  systemDate: "",
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
  } else {
    localStorage.removeItem("serviceToken");
    localStorage.removeItem("organizationType");
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const { userInfo } = useSelector((state) => state?.userInfoState);
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");
        if (userInfo?.serviceToken) {
          setSession(serviceToken);
          dispatch({
            type: LOGIN,
            payload: {
              ...userInfo,
            },
          });
        } else if (!userInfo?.serviceToken) {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email, password, checked, key = null) => {
    try {
      const response = await axios.post(
        "/authenticate",
        {
          loginName: email,
          loginPassword: password,
          rememberMe: checked,
          passwordKey: key,
        },
        {}
      );
      const {
        idToken,
        organizationId,
        organizationType,
        passwordExpired,
        systemDate,
      } = response.data;
      setSession(idToken);
      localStorage.setItem("organizationType", organizationType);
      localStorage.setItem("serviceToken", idToken);
      dispatch({
        type: LOGIN,
        payload: {
          serviceToken: idToken,
          isLoggedIn: true,
          organizationId,
          organizationType,
          passwordExpired,
          systemDate,
        },
      });
      dispatch(notificationManager(response));
      return response;
    } catch (error) {
      return error;
    }
  };

  const changePassword = async (
    loginName,
    oldPassword,
    newPassword,
    otp,
    key = null
  ) => {
    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? "/consultant/v1/user"
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? "/platform/v1/user"
        : "/client/v1/user";
    const response = await axios.post(
      `${prefix}/change-password`,
      {
        loginName: loginName,
        oldPassword: oldPassword,
        newPassword: newPassword,
        otp: otp,
        passwordKey: key,
      },
      {}
    );
    const {
      idToken,
      organizationId,
      organizationType,
      passwordExpired,
      systemDate,
    } = response.data;
    setSession(idToken);
    localStorage.setItem("organizationType", organizationType);
    localStorage.setItem("serviceToken", idToken);
    dispatch({
      type: CHANGE_PASSWORD,
      payload: {
        serviceToken: idToken,
        isLoggedIn: true,
        organizationId,
        organizationType,
        passwordExpired,
        systemDate,
      },
    });
    return response;
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        changePassword,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
