import { LAYOUT_CONST } from "constant";

export const DASHBOARD_PATH = "/home";
export const DASHBOARD_LABEL = "/dashboard";
export const HORIZONTAL_MAX_ITEM = 7;

const config = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.MINI_DRAWER, // default, mini-drawer
  fontFamily: `'Inter', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: "dark", // light, dark
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
};

export default config;
