import { createSlice } from "@reduxjs/toolkit";

// project imports
import { isLoading, setLoading } from "store/slices/user";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";

const clientVulnerabilityApiEndpoint = "/client/v1/vulnerability";
const clientVulnerabilityEvidenceApiEndpoint = "client/v1/vul-evidence";

const initialState = {
  publishedVulnerabilityList: {},
  selectedPublishedVulnerabilityDetail: {},
  consultantStatusValue: [],
  riskRatingValue: [],
  clientStatusValue: [],
  publishedEvidenceList: [],
  publishedEvidenceImage: {},
  publishedVulStatusHistory: [],
};
const slice = createSlice({
  name: "clientVulnerabilities",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // --------------------- GET APIs --------------------------------
    //Published Vulnerability List
    getClientPublishedVulnerabilitiesListSuccess(state, action) {
      state.publishedVulnerabilityList = action.payload;
    },
    //Selected Published Vulnerability Detail
    getSelectedPublishedVulnerabilityDetailSuccess(state, action) {
      state.selectedPublishedVulnerabilityDetail = action.payload;
    },
    //Consultant Status Value
    getConsultantStatusValueSuccess(state, action) {
      state.consultantStatusValue = action.payload;
    },
    //Risk Rating Value
    getRiskRatingValueSuccess(state, action) {
      state.riskRatingValue = action.payload;
    },
    //Client Status Value
    getClientStatusSuccess(state, action) {
      state.clientStatusValue = action.payload;
    },
    //GET Published Evidence List
    getPublishedEvidenceListSuccess(state, action) {
      state.publishedEvidenceList = action.payload;
    },
    //GET Published Evidence Image
    getPublsihedEvidenceImageSuccess(state, action) {
      state.publishedEvidenceImage = action.payload;
    },
    //GET Published Vulnerability Status History
    getPublishedVulnerabilityStatusHistorySuccess(state, action) {
      state.publishedVulStatusHistory = action.payload;
    },
  },
});

export default slice.reducer;

export const getClientPublishedVulnerabilitiesList = (params) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(`${clientVulnerabilityApiEndpoint}`, {
        params,
      });
      dispatch(
        slice.actions.getClientPublishedVulnerabilitiesListSuccess(
          response.data
        )
      );
      dispatch(
        slice.actions.getSelectedPublishedVulnerabilityDetailSuccess({})
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getSelectedPublishedVulnerabilityDetail = (cvId) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/${cvId}`
      );
      dispatch(
        slice.actions.getSelectedPublishedVulnerabilityDetailSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getConsultantStatusValue = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/consultant-status`
      );
      dispatch(slice.actions.getConsultantStatusValueSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getRiskRatingValue = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientVulnerabilityApiEndpoint}/risk-rating`
        : `/consultant/v1/vulnerability/risk-rating`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getRiskRatingValueSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getClientStatusValue = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/client-status`
      );
      dispatch(slice.actions.getClientStatusSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedEvidenceList = (vulnerabilityId) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityEvidenceApiEndpoint}/evidence-list/${vulnerabilityId}`
      );
      dispatch(slice.actions.getPublishedEvidenceListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedEvidenceImage = (vulnerabilityEvidenceId) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityEvidenceApiEndpoint}/download/${vulnerabilityEvidenceId}`
      );
      dispatch(slice.actions.getPublsihedEvidenceImageSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedVulnerabilityStatusHistory = (
  clientVulnerabilityId
) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/status-history/${clientVulnerabilityId}`
      );
      dispatch(
        slice.actions.getPublishedVulnerabilityStatusHistorySuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
