// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { setLoading } from "./user";
// ----------------------------------------------------------------------

const slaApiEndpoint = `/client/v1/vulnerability`;

const initialState = {
  error: null,
  addSlaData: {},
  slaData: {},
};

const sla = createSlice({
  name: "sla",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    isLoading(state, action) {
      state.setDataloading = action.payload;
    },
    addSlaSuccess(state, action) {
      state.addSlaData = action.payload;
    },
    getSlaSuccess(state, action) {
      state.slaData = action.payload;
    },
  },
});

// Reducer
export default sla.reducer;
export const { isLoading, getOrgDetailSuccess } = sla.actions;

export const addSLA = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(`${slaApiEndpoint}/sla`, payload);
      dispatch(sla.actions.addSlaSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(sla.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getSLA = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${slaApiEndpoint}/sla`);
      dispatch(sla.actions.getSlaSuccess(response.data));
    } catch (error) {
      dispatch(sla.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
