import { Grid, InputLabel, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

export default function ScanAssetDetails(props) {
  const data = props.data;
  // console.log(data, "data")
  return (
    <MainCard>
      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Asset Name:</InputLabel>
          <Typography variant="h5">{data?.assetName ?? "NA"}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Sensitivity:</InputLabel>
          <Typography variant="h5">{data?.sensitivityDesc ?? "NA"}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Operating system:</InputLabel>
          <Typography variant="h5">
            {data?.operatingSystemDesc ?? "NA"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Version :</InputLabel>
          <Typography variant="h5">{data?.version ?? "NA"}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Language:</InputLabel>
          <Typography variant="h5">{data?.programLang ?? "NA"}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Package Link:</InputLabel>
          <Typography variant="h5">{data?.packageLink ?? "NA"}</Typography>
        </Grid>
      </Grid>

      {/* <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Client Name:</InputLabel>
          <Typography variant="h5">{data?.clientOrgName ?? "NA"}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Created By:</InputLabel>
          <Typography variant="h5">{data?.createdByName ?? "NA"}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Created On:</InputLabel>
          <Typography variant="h5">{data?.createdOn ?? "NA"}</Typography>
        </Grid>
      </Grid> */}

      {/* <Grid container spacing={5} style={{ marginBottom: "10px" }}> */}
      {/* <Grid item xs={12} lg={3}>
          <InputLabel>Scan Status:</InputLabel>
          <Typography variant="h5">{data?.displayScanStatus}</Typography>
        </Grid> */}

      {/* <Grid item xs={12} lg={3}>
          <InputLabel>Organization Name:</InputLabel>
          <Typography variant="h5">{data?.organisationName ?? "NA"}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Client Scan Status:</InputLabel>
          <Typography variant="h5">
            {data?.scanStatusClientDescription ?? "NA"}
          </Typography>
        </Grid>
      </Grid> */}

      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        {/* <Grid item xs={12} lg={3}>
          <InputLabel>Scan Mode:</InputLabel>
          <Typography variant="h5">{data?.scanModeDesc}</Typography>
        </Grid> */}
        <Grid item xs={12} lg={3}>
          <InputLabel>Exposure:</InputLabel>
          <Typography variant="h5">{data?.exposureDesc ?? "NA"}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Asset URL/IP Address:</InputLabel>
          <Typography variant="h5">{data?.assetURL}</Typography>
        </Grid>
      </Grid>

      {/* <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Collection File:</InputLabel>
          <Typography variant="h5">{data?.collectionFile ?? "NA"}</Typography>
        </Grid>
      </Grid> */}
      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12}>
          <InputLabel>Description:</InputLabel>
          <Typography variant="h5">{data?.description ?? "NA"}</Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
}
