// material-ui
import {
  Avatar,
  Box,
  FormControlLabel,
  Grid,
  Switch,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// project imports
import useConfig from "hooks/useConfig";
import ProfileSection from "./ProfileSection";
import { useSelector } from "store";
import { openDrawer } from "store/slices/menu";

// assets
import { IconMenu2 } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { dispatch } from "store";
import { getOrgDetail, getOrgDetailSuccess } from "store/slices/user";
import DisplayLoginUserName from "./DisplayLoginUserName";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const { onChangeMenuType, layout } = useConfig();

  const { drawerOpen } = useSelector((state) => state.menu);

  useEffect(() => {
    dispatch(getOrgDetail());
    return () => {
      dispatch(getOrgDetailSuccess({}));
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            overflow: "hidden",
            transition: "all .2s ease-in-out",
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color="inherit"
        >
          <IconMenu2 stroke={1.5} size="20px" />
        </Avatar>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 0.1 }} />
      <ProfileSection />
        <Grid ml={4}></Grid>
    </>
  );
};

export default Header;
