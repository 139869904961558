import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ACTION_TYPE } from 'constant';
import moment from 'moment';
import { useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import VmTable from 'ui-component/table';
import { userActionAccess } from 'utils/helpers';

const RawAction = (props) => {
  const theme = useTheme();
  const handleDeleteFile = () => {
    let allUploadFlies = [...props?.uploadedFiles];
    allUploadFlies.splice(props?.row?.index, 1);
    props?.setUploadedFiles([...allUploadFlies]);
  };

  return (
    <Stack direction={'row'} spacing={1}>
      <IconButton onClick={handleDeleteFile}>
        <DeleteIcon
          sx={{
            color: theme.palette.error.main,
          }}
        />
      </IconButton>
    </Stack>
  );
};

const AddComments = (props) => {
  const theme = useTheme();
  const params = useParams();
  const [comment, setComment] = useState();

  const handleUploadFile = (e) => {
    const newFile = e.target.files[0];
    props.setUploadedFiles((prevUploadedFiles) => [
      ...prevUploadedFiles,
      {
        id: prevUploadedFiles?.length + 1,
        file: newFile,
      },
    ]);
  };

  const handleSubmit = () => {
    props?.handleSubmitComment(comment);
    props?.setUploadedFiles([]);
  };

  const handleChangeEditor = (value) => {
    setComment(value);
  };

  const commentAttachment = useMemo(
    () => [
      {
        accessorKey: 'file.name',
        header: 'File Name',
        size: 150,
      },
      {
        accessorKey: 'size',
        header: 'File Size',
        size: 150,
        Cell: ({ cell, row }) => (
          <>
            {row?.original?.file?.size &&
              (row?.original?.file?.size / 1024 ** 2).toFixed(2) + 'MB'}
          </>
        ),
      },
      {
        accessorKey: 'lastModifiedDate',
        header: 'Uploaded On',
        Cell: ({ cell, row }) => (
          <>
            {row?.original?.file?.lastModifiedDate &&
              moment(row.original.file?.lastModifiedDate).format('Do MMM YYYY')}
          </>
        ),
      },
      {
        header: 'Actions',
        Cell: ({ cell, row }) => (
          <RawAction
            uploadedFiles={props.uploadedFiles}
            setUploadedFiles={props.setUploadedFiles}
            row={row}
          />
        ),
      },
    ],
    [props.uploadedFiles]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack direction={'column'} gap={2}>
          <Typography variant="h4">Description</Typography>
          <ReactQuill onChange={handleChangeEditor} value={comment} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction={'column'} gap={2}>
          <Typography variant="h4">Attachment</Typography>
          <TextField
            type="file"
            id="file-upload"
            fullWidth
            sx={{ display: 'none' }}
            onChange={handleUploadFile}
          />
          <InputLabel
            htmlFor="file-upload"
            sx={{
              background: theme.palette.background.default,
              py: 3.75,
              px: 0,
              textAlign: 'center',
              height: '100px',
              borderRadius: '4px',
              border: '1px dashed gray',
              cursor: 'pointer',
              mb: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CloudUploadIcon sx={{ color: theme.palette.error.main }} /> Drop
            file here to upload
          </InputLabel>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <VmTable
          columns={commentAttachment}
          data={props.uploadedFiles}
        // tableActions={<RawAction />}
        />
      </Grid>
      {userActionAccess(ACTION_TYPE?.ADD_SCAN_CMT) ?
        <Stack
          mt={3}
          justifyContent={'flex-end'}
          width={'100%'}
          direction={'row'}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              boxShadow: theme.customShadows.secondary,
              ':hover': {
                boxShadow: 'none',
              },
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Stack>
        : ''}
    </Grid>
  );
};

export default AddComments;
