import { createSlice } from "@reduxjs/toolkit";

// project imports
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { setLoading } from "./user";

// initial state
const initialState = {
  error: null,
  vulnerabilityCount: {},
  orgRiskScore: {},
  exposureVulnerabilityCount: {},
  assetsCount: {},
  acceptedRiskCount: {},
  acceptedRiskCount: {},
  scanVulnerabilityTrend: {},
  ongoingScan: {},
  vulStatusCount: {},
  vulnerabilityBuckets: {},
  assetNotScanBuckets: {},
  testerDashboardData: {},
  smDashboardData: {},
  OrgRiskTimeLine: [],
  MostOccurringVulnerabilities: [],
  MostOccurringComplianceIssues: [],
  waspCategoryVulnerabilitiesCount: [],
  BoxListData: [],
  assetManagerVulCount: {},
  departmentScanGraph: {},
  assetScanGraphData: {},
  assetSummaryDashboardData: [],
  departmentDashboardList: [],
  assetGroupDashboardList: [],
  AssetSensitivityRiskRatingData: [],
  exportAssetSensitivityRiskRatingRes: {},
  // selectedDeptValue: [],
  // selectedAssetValue: [],
};

// ==============================|| SLICE - CHANGE PASSOWORD ||============================== //
const clientDashboardEndPoint = "/client/v1/dashboard/";
const clientEndPoint = "/client/v1/";
const consultantclientDashboardEndPoint = "/consultant/v1/dashboard/";
const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    vulnerabilityCountSuccess(state, action) {
      state.vulnerabilityCount = action.payload;
    },

    // setselectedDepartmentValue(state, action) {
    //   state.selectedDeptValue = action.payload;
    // },

    // setselectedAssetValue(state, action) {
    //   state.selectedAssetValue = action.payload;
    // },

    orgRiskScoreSuccess(state, action) {
      state.orgRiskScore = action.payload;
    },

    exposureVulnerabilityCountSuccess(state, action) {
      state.exposureVulnerabilityCount = action.payload;
    },

    assetsCountSuccess(state, action) {
      state.assetsCount = action.payload;
    },

    acceptedRiskCountSuccess(state, action) {
      state.acceptedRiskCount = action.payload;
    },

    scanVulnerabilityTrendSuccess(state, action) {
      state.scanVulnerabilityTrend = action.payload;
    },

    ongoingScanSuccess(state, action) {
      state.ongoingScan = action.payload;
    },

    vulStatusCountSuccess(state, action) {
      state.vulStatusCount = action.payload;
    },

    vulnerabilityBucketsSuccess(state, action) {
      state.vulnerabilityBuckets = action.payload;
    },

    assetNotScanBucketsSuccess(state, action) {
      state.assetNotScanBuckets = action.payload;
    },

    uniqueComplianceIssuesCountSuccess(state, action) {
      state.uniqueComplianceIssuesCount = action.payload;
    },

    testerDashboardSuccess(state, action) {
      state.testerDashboardData = action.payload;
    },

    smDashboardSuccess(state, action) {
      state.smDashboardData = action.payload;
    },
    fetchOrgRiskTimeLineSuccess(state, action) {
      state.OrgRiskTimeLine = action.payload;
    },
    fetchMostOccurringVulnerabilitiesSuccess(state, action) {
      state.MostOccurringVulnerabilities = action.payload;
    },
    fetchMostOccurringComplianceIssuesSuccess(state, action) {
      state.MostOccurringComplianceIssues = action.payload;
    },
    fetchWaspCategoryVulnerabilitiesCountSuccess(state, action) {
      state.waspCategoryVulnerabilitiesCount = action.payload;
    },
    fetchfetchBoxListDataSuccess(state, action) {
      state.BoxListData = action.payload;
    },
    fetchAssetManagerVulCountSuccess(state, action) {
      state.assetManagerVulCount = action.payload;
    },

    fetchdepartmentScanGraph(state, action) {
      state.departmentScanGraph = action.payload;
    },
    fetchassetScanGraphData(state, action) {
      state.assetScanGraphData = action.payload;
    },
    fetchAssetSummaryDashboardDataSuccess(state, action) {
      state.assetSummaryDashboardData = action.payload;
    },
    fetchDepartmentDashboardListSuccess(state, action) {
      state.departmentDashboardList = action.payload;
    },
    fetchAssetGroupDashboardListSuccess(state, action) {
      state.assetGroupDashboardList = action.payload;
    },
    fetchAssetSensitivityRiskRatingSuccess(state, action) {
      state.AssetSensitivityRiskRatingData = action.payload;
    },
    exportAssetSensitivityRiskRatingSuccess(state, action) {
      state.exportAssetSensitivityRiskRatingRes = action.payload;
    },
  },
});

export default dashboard.reducer;
export const { exportAssetSensitivityRiskRatingSuccess } = dashboard.actions;

// export const {  setselectedDepartmentValue} = dashboard.actions;

export function fetchAssetGroupScanGraphData(params) {
  return async (dispatch) => {
    // dispatch(setLoading(true));;
    try {
      let url = `${clientDashboardEndPoint}asset-scan-stats`;
      // if (departmentIds || assetGroupIds) {
      //   const params = new URLSearchParams();
      //   if (departmentIds && departmentIds.length > 0) {
      //     params.append("departmentId", departmentIds.join(","));
      //   }
      //   if (assetGroupIds && assetGroupIds.length > 0) {
      //     params.append("assetGroupId", assetGroupIds.join(","));
      //   }
      //   url += `?${params.toString()}`;
      // }
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );

      const response = await axios.get(url, { params: filteredParams });
      dispatch(dashboard.actions.fetchassetScanGraphData(response?.data));
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchDepartmentScanGraphData(params) {
  return async (dispatch) => {
    // dispatch(setLoading(true));;

    try {
      let url = `${clientDashboardEndPoint}scan-stats`;
      // if (departmentIds || assetGroupIds) {
      //   const params = new URLSearchParams();
      //   if (departmentIds && departmentIds.length > 0) {
      //     params.append("departmentId", departmentIds.join(","));
      //   }
      //   if (assetGroupIds && assetGroupIds.length > 0) {
      //     params.append("assetGroupId", assetGroupIds.join(","));
      //   }
      //   url += `?${params.toString()}`;
      // }

      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );

      const response = await axios.get(url, { params: filteredParams });
      dispatch(dashboard.actions.fetchdepartmentScanGraph(response?.data));
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchVulnerabilityCount(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const response = await axios.get(
        `${clientDashboardEndPoint}vulnerabilities-count`,
        { params: filteredParams }
      );
      dispatch(dashboard.actions.vulnerabilityCountSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchOrgRiskScore() {
  return async () => {
    // // dispatch(setLoading(true));;

    try {
      const response = await axios.get(`${clientDashboardEndPoint}org-score`);
      dispatch(dashboard.actions.orgRiskScoreSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchExposureVulnerabilityCount(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const response = await axios.get(
        `${clientDashboardEndPoint}exposure-vulnerability-count`,
        { params: filteredParams }
      );
      dispatch(
        dashboard.actions.exposureVulnerabilityCountSuccess(response.data)
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAssetsCount() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}assets-count`
      );
      dispatch(dashboard.actions.assetsCountSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAcceptedRiskCount() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}accepted-risk-count`
      );
      dispatch(dashboard.actions.acceptedRiskCountSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchScanVulnerabilityTrend(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const response = await axios.get(
        `${clientDashboardEndPoint}scan-vulnerability-trend`,
        { params: filteredParams }
      );
      dispatch(
        dashboard.actions.scanVulnerabilityTrendSuccess(
          response.data?.map((value) => ({
            ...value,
            Scans: value?.sc,
            Vulnerabilities: value?.vc,
          }))
        )
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchOngoingScans() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}ongoing-scan-count`
      );
      dispatch(dashboard.actions.ongoingScanSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchVulStatusCount(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}vulnerabilities-status-count`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(dashboard.actions.vulStatusCountSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchVulnerabilityBuckets() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}vulnerability_buckets
       `
      );
      dispatch(dashboard.actions.vulnerabilityBucketsSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAssetNotScanBuckets() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}asset_not_scan_buckets
       `
      );
      dispatch(dashboard.actions.assetNotScanBucketsSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchUniqueComplianceIssuesCount() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}unique-compliance-issues-count
       `
      );
      dispatch(
        dashboard.actions.uniqueComplianceIssuesCountSuccess(response.data)
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchTesterDashboard() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${consultantclientDashboardEndPoint}tester-dashboard
       `
      );
      dispatch(dashboard.actions.testerDashboardSuccess(response.data));
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchSmDashboard() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${consultantclientDashboardEndPoint}sm-dashboard
       `
      );
      dispatch(dashboard.actions.smDashboardSuccess(response.data));
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchOrgRiskTimeLine() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}org-risk-timeline
       `
      );
      dispatch(dashboard.actions.fetchOrgRiskTimeLineSuccess(response.data));
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchMostOccurringVulnerabilities(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}most-occurring-vulnerabilities`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchMostOccurringVulnerabilitiesSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchMostOccurringComplianceIssues(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}most-occurring-compliance-issues`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchMostOccurringComplianceIssuesSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchoWaspCategoryVulnerabilitiesCount(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const response = await axios.get(
        `${clientDashboardEndPoint}owasp-category-vulnerabilities-count`,
        { params: filteredParams }
      );
      dispatch(
        dashboard.actions.fetchWaspCategoryVulnerabilitiesCountSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchBoxListData(payload) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${consultantclientDashboardEndPoint}${
          payload?.role ? "sm" : "tester"
        }/dashboard-scans/${payload?.scanStatusList}/${payload?.scanMode}
       `
      );
      dispatch(dashboard.actions.fetchfetchBoxListDataSuccess(response.data));
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAssetManagerVulCount() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}scan-type-am-vulnerability-count
       `
      );
      dispatch(
        dashboard.actions.fetchAssetManagerVulCountSuccess(response.data)
      );
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAssetSummaryDashboardData(params) {
  return async (dispatch) => {
    // dispatch(setLoading(true));;

    try {
      const url = `${clientDashboardEndPoint}assets-stats`;

      // Filter out params with empty values (null, undefined, or empty string)
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );

      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchAssetSummaryDashboardDataSuccess(response.data)
      );
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchDepartmentDashboardList() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const url = `${clientEndPoint}department/user-department-list`;
      const response = await axios.get(url);
      dispatch(
        dashboard.actions.fetchDepartmentDashboardListSuccess(response.data)
      );
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}
export function fetchAssetGroupDashboardList(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      // Filter out params with empty values (null, undefined, or empty string)
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const url = `${clientEndPoint}asset/group/user-asset-group-list`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchAssetGroupDashboardListSuccess(response.data)
      );
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}
export function fetchAssetSensitivityRiskRating(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      // Filter out params with empty values (null, undefined, or empty string)
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}asset-sensitivity-risk-rating-heatmap`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchAssetSensitivityRiskRatingSuccess(response.data)
      );
    } catch (error) {
      dispatch(dashboard.actions.hasError(error));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}
export function exportAssetSensitivityRiskRating(params) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      // Filter out params with empty values (null, undefined, or empty string)
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}asset-sensitivity-risk-rating-heatmap/export`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.exportAssetSensitivityRiskRatingSuccess(response.data)
      );
      dispatch(notificationManager(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(dashboard.actions.hasError(error));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}
