// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";

// ----------------------------------------------------------------------
const clientAssetsApiEndpoint = `/client/v1/asset`;
const clientAssetGroupApiEndpoint = `/client/v1/asset/group`;
const initialState = {
  error: null,
  allClientAssets: [],
  OrgAssetsList: [],
  MyAssetsList: [],
  addAsset: {},
  assetGroupList: [],
  assetGroupMyList: [],
  addedAssetGroup: {},
  updatedAssetGroup: {},
  groupMappedAsset: [],
  groupUnmappedAsset: [],
  updatedAssets: {},
  selectedAsset: [],
  mapNewAsset: {},
  unmapAsset: {},
  uploadAsset: {},
  GhUserList: [],
  selectedAssetGroup: {},
  //   deleteAssetResponse: {},
  downloadClientAssetTemplateResponse: {},
  amList: [],
  spocList: [],
  exportAssetDataRes: {},
  OrgAssetsListForFilter: [],
};

const slice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // --------------------- GET APIs --------------------------------

    // GET CLIENT_All_ASSETS
    getAllClientAssetsSuccess(state, action) {
      state.allClientAssets = action.payload;
    },

    // GET ORG_ASSETS
    getOrgAssetsList(state, action) {
      state.OrgAssetsList = action.payload;
    },

    // GET MY_ASSETS
    getMyAssetsList(state, action) {
      state.MyAssetsList = action.payload;
    },
    // GET ORG_ASSETS_FOR_FILTER
    getOrgAssetsListForFilter(state, action) {
      state.OrgAssetsListForFilter = action.payload;
    },

    // ADD ASSET
    addClientAssetsSuccess(state, action) {
      state.addAsset = action.payload;
    },

    // GET ASSET_GROUP
    AssetGroupSuccess(state, action) {
      state.assetGroupList = action.payload;
    },

    // GET MY ASSET_GROUP
    MyAssetGroupSuccess(state, action) {
      state.assetGroupMyList = action.payload;
    },

    // ADD ASSET_GROUP
    addAssetGroupSuccess(state, action) {
      state.addedAssetGroup = action.payload;
    },

    // ADD ASSET_GROUP
    updateAssetGroupSuccess(state, action) {
      state.updatedAssetGroup = action.payload;
    },

    // GET GROUP_MAPPED_ASSET
    groupMappedAssetSuccess(state, action) {
      state.groupMappedAsset = action.payload;
    },

    // GET GROUP_UNMAPPED_ASSET
    groupUnmappedAssetSuccess(state, action) {
      state.groupUnmappedAsset = action.payload;
    },

    // UPDATE ASSET
    updateClientAssetsSuccess(state, action) {
      state.updatedAssets = action.payload;
    },

    // GET SINGLE_ASSET
    getSingleAssetSuccess(state, action) {
      state.selectedAsset = action.payload;
    },

    // ADD NEW_ASSET
    mapNewAssetsSuccess(state, action) {
      state.mapNewAsset = action.payload;
    },

    // REMOVE ASSET
    unmapNewAssetsSuccess(state, action) {
      state.unmapAsset = action.payload;
    },

    // UPLOAD ASSET
    uploadAssetSuccess(state, action) {
      state.uploadAsset = action.payload;
    },

    // GH USER LIST
    GhUserListSuccess(state, action) {
      state.GhUserList = action.payload;
    },

    // SELECTED ASSET GROUP DETAIL
    selectedAssetGroupSuccess(state, action) {
      state.selectedAssetGroup = action.payload;
    },

    // // DELETE ASSET
    // deleteAssetSuccess(state, action) {
    //   state.deleteAssetResponse = action.payload;
    // },

    // DOWNLOAD ASSET TEMPLATE
    downloadClientAssetTemplateSuccess(state, action) {
      state.downloadClientAssetTemplateResponse = action.payload;
    },

    // ASSET MANAGER LIST
    fetchAmListSuccess(state, action) {
      state.amList = action.payload;
    },

    // ASSET SPOC LIST
    fetchSpocListSuccess(state, action) {
      state.spocList = action.payload;
    },
    // EXPORT ASSET DATA
    exportAssetDataSuccess(state, action) {
      state.exportAssetDataRes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { downloadClientAssetTemplateSuccess, exportAssetDataSuccess } =
  slice.actions;

export const fetchClientAsset = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${clientAssetsApiEndpoint}/list`, {
        params,
      });
      dispatch(slice.actions.getOrgAssetsList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchClientMyAsset = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${clientAssetsApiEndpoint}/my-list`, {
        params,
      });
      dispatch(slice.actions.getMyAssetsList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addClientNewAssets = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${clientAssetsApiEndpoint}`, payload);
      dispatch(slice.actions.addClientAssetsSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateClientAssets = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(`${clientAssetsApiEndpoint}`, payload);
      dispatch(slice.actions.updateClientAssetsSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fecthClientSingleAssetDetail = (assetId) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${clientAssetsApiEndpoint}/${assetId}`);
      dispatch(slice.actions.getSingleAssetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchClientAssetGroupList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${clientAssetsApiEndpoint}/group/list`);
      dispatch(slice.actions.AssetGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchClientAssetGroupMyList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientAssetsApiEndpoint}/group/my-list`
      );
      dispatch(slice.actions.MyAssetGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addClientNewAssetGroup = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${clientAssetsApiEndpoint}/group`,
        payload.data
      );
      dispatch(slice.actions.addAssetGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateClientAssetGroup = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${clientAssetsApiEndpoint}/group`,
        payload.data
      );
      dispatch(slice.actions.updateAssetGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchClientGroupMappedAsset = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientAssetsApiEndpoint}/group/${payload?.assetGroupId}/added-asset${
          payload?.assetType ? `?assetType=${payload?.assetType}` : ""
        }`
      );
      dispatch(slice.actions.groupMappedAssetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const ClientAddNewAssetInGroup = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${clientAssetsApiEndpoint}/group/add-asset/${payload?.assetGroupId}`,
        { idList: payload?.idList }
      );
      dispatch(slice.actions.mapNewAssetsSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const ClientUnaddNewAssetInGroup = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${clientAssetsApiEndpoint}/group/remove-asset/${payload?.assetGroupId}`,
        { idList: payload?.idList }
      );
      dispatch(slice.actions.unmapNewAssetsSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchClientGroupUnmappedAsset = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientAssetsApiEndpoint}/group/${params?.assetGroupId}/unadded-asset`,
        { params }
      );
      dispatch(slice.actions.groupUnmappedAssetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const uploadClientAssetFile = (file) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${clientAssetsApiEndpoint}/upload`,
        file,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(slice.actions.uploadAssetSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

// export const deleteClientAssets = (payload) => {
//   return async () => {     dispatch(setLoading(true));

//     try {
//       const response = await axios.delete(
//         `${clientAssetsApiEndpoint}/${payload?.clientOrgId}`,
//         {
//           data: payload?.scanIds,
//         }
//       );
//       dispatch(slice.actions.deleteAssetSuccess(response.data));
//       notificationManager(response);
//     } catch (error) {
//       dispatch(openSnackbar(error));
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// };

export const fetchGhUserList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientAssetGroupApiEndpoint}/gh-user-list`
      );
      dispatch(slice.actions.GhUserListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchSelectedAssetGroup = (assetGroupId) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientAssetsApiEndpoint}/group/{assetGroupId}?assetGroupId=${assetGroupId}`
      );
      dispatch(slice.actions.selectedAssetGroupSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const downloadClientAssetTemplate = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientAssetsApiEndpoint}/asset-template/download`
      );
      dispatch(slice.actions.downloadClientAssetTemplateSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchAmList = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientAssetsApiEndpoint}/am-user-list`
      );
      dispatch(slice.actions.fetchAmListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const fetchSpocList = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientAssetGroupApiEndpoint}/spoc-user-list`
      );
      dispatch(slice.actions.fetchSpocListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const exportAssetData = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(`${clientAssetsApiEndpoint}/export`);
      dispatch(slice.actions.exportAssetDataSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const fetchClientAssetForFilter = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${clientAssetsApiEndpoint}/list`, {
        params,
      });
      dispatch(slice.actions.getOrgAssetsListForFilter(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
