// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project imports
import snackbarReducer from "./slices/snackbarReducer";
import menuReducer from "./slices/menu";
import scanReducer from "./slices/scanReducer";
import assetsReducer from "./slices/assetsReducer";
import clientAssetsReducer from "./slices/clientAssetsReducer";
import vulnerabilityReducer from "./slices/vulnerabilityReducer";
import userInfoReducer from "./slices/userInfoReducer";
import userGroup from "./slices/userGroup";
import userReducer from "./slices/user";
import vulnerbilityMasterReducer from "./slices/vulnerabilityMaster";
import consultantMappingReducer from "./slices/consultantMapping";
import clientConsultantMapReducer from "./slices/clientConsultantMapping";
import platformAddOrgReducer from "./slices/platformAddOrg";
import consultantAddOrg from "./slices/consultantOrganization";
import changePasswordReducer from "./slices/changePassword";
import forgotPasswordReducer from "./slices/forgotPassword";
import securityManager from "./slices/securityManager";
import customizeLayout from "./slices/custmizeLayout";
import clientScanReducer from "./slices/clientScanReducer";
import dashboard from "./slices/dashboardReducer";
import vulException from "./slices/exception";
import downloadReport from "./slices/downloadReport";
import sla from "./slices/SLA";
import clientVulnerabilityReducer from "./slices/clientVulnerabilityReducer";
import clientDepartment from "./slices/departmentReducer";
import TestCase from "./slices/scanTestReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  scansState: scanReducer,
  vulnerbilityMasterState: vulnerbilityMasterReducer,
  assetsState: assetsReducer,
  clientAssetsState: clientAssetsReducer,
  securityManagerState: securityManager,
  vulnerabilityState: vulnerabilityReducer,
  snackbar: snackbarReducer,
  userState: userReducer,
  consultantMapState: consultantMappingReducer,
  clientConsultantState: clientConsultantMapReducer,
  organizationState: platformAddOrgReducer,
  clientOrganizationState: consultantAddOrg,
  changePasswordState: changePasswordReducer,
  forgotPasswordState: forgotPasswordReducer,
  customizeLayoutState: customizeLayout,
  dashboardState: dashboard,
  vulExceptionState: vulException,
  downloadReportState: downloadReport,
  clientVulnerabilityReducerState: clientVulnerabilityReducer,
  slaState: sla,
  scanTestState: TestCase,
  departmentState: clientDepartment,
  userInfoState: persistReducer(
    {
      key: "info",
      storage,
      keyPrefix: "user-",
    },
    userInfoReducer
  ),
  menu: menuReducer,
  userGroupState: userGroup,
  clientScansState: clientScanReducer,
});

export default reducer;
