import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ChecklistRtlRoundedIcon from "@mui/icons-material/ChecklistRtlRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/styles";
import { NO_VALUE } from "constant";
import moment from "moment";
import { useEffect, useState } from "react";
import { dispatch, useSelector } from "store";
import {
    approveNessusUpload,
    fetchNessusUploadAssetSummaryList,
    fetchNessusUploadFileList,
    getScanVulnerabilities,
    rejectNessusUpload,
    uploadNessusCsv,
} from "store/slices/vulnerabilityReducer";
import VmTable from "ui-component/table";
import {
    NESSUS_UPLOAD_FILE_STATUS_CODE,
    UPLOAD_ASSET_UPLOAD_STATUS,
} from "../../../constant";

const ActionButton = ({
  setVisibleFileContent,
  setSelectFileUploadId,
  handleApprove,
  handleReject,
}) => {
  const theme = useTheme();

  const handleGoBack = () => {
    setVisibleFileContent(true);
    setSelectFileUploadId();
  };

  return (
    <>
      <Grid item>
        <Chip
          size="small"
          sx={{
            mr: 2,
            cursor: "pointer ",
          }}
          title="Back"
          icon={
            <ArrowBackIosNewIcon
              onClick={handleGoBack}
              fontSize="small"
              sx={{
                color: "#000000",
              }}
            />
          }
        />
      </Grid>
      <IconButton
        variant="contained"
        size="large"
        color="inherit"
        aria-label="actions"
        title="Reject"
        onClick={() => {
          handleReject(true);
        }}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <IconButton
        variant="contained"
        size="large"
        color="inherit"
        aria-label="actions"
        title="Approve"
        onClick={() => handleApprove()}
      >
        <ChecklistRtlRoundedIcon />
      </IconButton>
    </>
  );
};

const NessusUpload = ({
  scanId,
  clientOrgId,
  normalUpload,
  setOpenNessusUploadDialog,
  handleDownloadTemplate,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [visibleFileContent, setVisibleFileContent] = useState(true);
  const [approvedIds, setApprovedIds] = useState([]);
  const [changeAssetStatus, setChangeAssetStatus] = useState(true);
  const [selectFileUploadId, setSelectFileUploadId] = useState();
  const [demoData, setDemoData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [fileDetail, setFileDetail] = useState([]);
  const theme = useTheme();
  const formData = new FormData();
  const {
    nessusUploadFileList,
    nessusUploadAssetSummaryList,
    uploadNessusReponse,
    downlaodNessusTemplateResponse,
  } = useSelector((state) => state?.vulnerabilityState);

  const handleUpload = () => {
    const fileInput = document.getElementById("fileInput");
    const file = fileInput.files[0];
    if (file) {
      formData.append("file", file);
      setIsLoading(true); // Start loading
      dispatch(uploadNessusCsv(clientOrgId, scanId, formData, normalUpload))
        .then((response) => {
          if (response?.status >= 200 && response.status < 400) {
            dispatch(fetchNessusUploadFileList(scanId, clientOrgId));
          }
        })
        .finally(() => {
          setIsLoading(false); // Stop loading after response
        });
    }
  };
  const handleFileContent = (fileUploadId) => {
    setVisibleFileContent(!visibleFileContent);
    if (!visibleFileContent && fileUploadId) {
    }
    dispatch(
      fetchNessusUploadAssetSummaryList(scanId, clientOrgId, fileUploadId)
    );
  };

  // const handleDownloadTemplate = () => {
  //   dispatch(downlaodNessustemplate());
  // };

  // useEffect(() => {
  //   console.log("useEffect", downlaodNessusTemplateResponse)
  //   if (Object.keys(downlaodNessusTemplateResponse)?.length > 0) {
  //     const { fileContent, fileName } = downlaodNessusTemplateResponse;
  //     downloadTemplateFile(fileContent, fileName);
  //     return () => {
  //       dispatch(downlaodNessusTemplateSuccess({}));
  //     };
  //   }
  // }, [downlaodNessusTemplateResponse]);

  const renderAssetUploadMessage = (row) => {
    const uploadMessage = row?.original?.asset_upload_message?.slice(0, 20);
    if (!uploadMessage) return null;

    return (
      <>
        {/* {uploadMessage.split("\n")?.map((line, index) => (
          <div key={index} className="scan-line">
            {line}
          </div>
        ))} */}

        <span title={row?.original?.asset_upload_message}>
          {uploadMessage}...
        </span>
      </>
    );
  };

  const handleAssetStatusToggle = (assetId, status) => {
    setChangeAssetStatus(status);
    const updatedData = demoData?.map((value) => ({
      ...value,
      isAssetActive: value.assetId === assetId ? status : value.isAssetActive,
    }));
    setDemoData(updatedData);

    if (status) {
      setApprovedIds((prevState) => {
        return [...prevState, assetId];
      });
    } else {
      setApprovedIds((prevState) => {
        return prevState?.filter((item) => item !== assetId);
      });
    }
  };

  const handleApprove = () => {
    // Check if all required values are present and approvedIds has at least one valid item
    if (
      scanId &&
      clientOrgId &&
      selectFileUploadId &&
      approvedIds?.length > 0
    ) {
      const data = approvedIds.filter(Boolean); // Filter out falsy values like undefined or null

      setIsLoading(true); // Start loading

      // Dispatch the approve action
      dispatch(
        approveNessusUpload(scanId, clientOrgId, selectFileUploadId, data)
      )
        .then(() => {
          // Fetch updated scan vulnerabilities after approval
          dispatch(
            getScanVulnerabilities({ scanId, clientOrgId, page: 1, size: 10 })
          );
          dispatch(fetchNessusUploadFileList(scanId, clientOrgId));
          setOpenNessusUploadDialog(false); // Close the upload dialog
        })
        .catch((error) => {
          // Handle any errors (you can log or display the error
        })
        .finally(() => {
          setIsLoading(false); // Stop loading after response or error
        });
    }
  };

  const handleReject = () => {
    if ((scanId, clientOrgId, selectFileUploadId)) {
      setIsLoading(true); // Start loading

      dispatch(rejectNessusUpload(scanId, clientOrgId, selectFileUploadId))
        .then(() => {
          // setApprovedIds([]);/
          dispatch(fetchNessusUploadFileList(scanId, clientOrgId));
          setVisibleFileContent(true);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading after response
        });
    }
  };

  useEffect(() => {
    // Define the function to fetch the file list and check the status
    const checkFileStatus = async () => {
      const response = await dispatch(
        fetchNessusUploadFileList(scanId, clientOrgId)
      );

      // Check if the fileStatus is still "Processing"
      if (response?.data?.some((file) => file.fileStatus !== "P")) {
        // If not "Processing", clear the interval
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(checkFileStatus, 5000);
    checkFileStatus();
    return () => clearInterval(intervalId);
  }, [uploadNessusReponse]);

  useEffect(() => {
    let addNewkeyData = [];
    if (nessusUploadAssetSummaryList?.length > 0) {
      addNewkeyData = nessusUploadAssetSummaryList?.map((value) => ({
        ...value,
        isAssetActive:
          value.svUploadAssetUploadStatus ===
          UPLOAD_ASSET_UPLOAD_STATUS?.SUCCESS
            ? true
            : false,
        color:
          value.svUploadAssetUploadStatus ===
          UPLOAD_ASSET_UPLOAD_STATUS?.SUCCESS
            ? "success"
            : value.svUploadAssetUploadStatus ===
              UPLOAD_ASSET_UPLOAD_STATUS?.WARNING
            ? "info"
            : "error",
      }));

      setApprovedIds(() =>
        nessusUploadAssetSummaryList?.map((value) => {
          if (
            value?.svUploadAssetUploadStatus !=
            UPLOAD_ASSET_UPLOAD_STATUS?.FAILED
          ) {
            return value?.assetId;
          }
        })
      );
    }
    setDemoData(addNewkeyData);
  }, [nessusUploadAssetSummaryList]);

  const columns = [
    {
      accessorKey: "fileName",
      header: "File Name",
    },
    {
      accessorKey: "UploadedBy",
      header: "Created By",
    },
    {
      accessorKey: "createdOn",
      header: "Created On",
      Cell: ({ row }) => (
        <>
          {row?.original?.createdOn
            ? moment(row.original.createdOn).format("Do MMM YYYY")
            : NO_VALUE}
        </>
      ),
    },
    {
      accessorKey: "time",
      header: "Time",
      Cell: ({ row }) => (
        <>
          {row?.original?.createdOn
            ? moment(row?.original?.createdOn).format("LT")
            : NO_VALUE}
        </>
      ),
    },
    {
      accessorKey: "fileStatusDesc",
      header: "File Status",
      muiTableBodyCellProps: ({ row }) => ({
        onClick: (event) => {
          if (
            row?.original?.fileStatus ===
            NESSUS_UPLOAD_FILE_STATUS_CODE?.VERIFICATION_PENDING
          ) {
            handleFileContent(row?.original?.fileUploadId);
            setSelectFileUploadId(row?.original?.fileUploadId);
          }
        },
        sx: {
          cursor:
            row?.original?.fileStatus ===
            NESSUS_UPLOAD_FILE_STATUS_CODE?.VERIFICATION_PENDING
              ? "pointer"
              : null,
          color:
            row?.original?.fileStatus ===
            NESSUS_UPLOAD_FILE_STATUS_CODE?.VERIFICATION_PENDING
              ? theme.palette.secondary.main
              : null,
          textDecoration:
            row?.original?.fileStatus ===
            NESSUS_UPLOAD_FILE_STATUS_CODE?.VERIFICATION_PENDING
              ? "underline"
              : null,
        },
      }),
    },
  ];

  useEffect(() => {
    setFileDetail(nessusUploadFileList);
  }, [nessusUploadFileList]);

  const vulColumns = [
    {
      accessorKey: "assetName",
      header: "Asset Name",
    },
    {
      accessorKey: "assetUrl",
      header: "Asset Url",
    },
    {
      accessorKey: "asset_upload_message",
      header: "Asset Upload Message",
      Cell: ({ row }) => renderAssetUploadMessage(row),
    },
    {
      accessorKey: "svUploadAssetUploadStatus",
      header: "Status",
    },
    {
      accessorKey: "isAssetActive",
      header: "Action",
      size: 200,
      id: "assetId",
      Cell: ({ row }) => (
        // row?.original?.svUploadAssetUploadStatus ===
        // UPLOAD_ASSET_UPLOAD_STATUS?.WARNING ? (
        <Grid item xs={12} lg={6}>
          <Box sx={{ height: "30px" }} />
          <FormControlLabel
            control={
              <Switch
                color={row?.original?.color}
                sx={{
                  background:
                    row?.original?.color === "success"
                      ? green["100"]
                      : row?.original?.color === "error"
                      ? red["100"]
                      : blue["100"],
                  borderRadius: "20px",
                  margin: "10px",
                }}
                checked={row?.original?.isAssetActive}
                onChange={(event) =>
                  handleAssetStatusToggle(
                    row?.original?.assetId,
                    event?.target?.checked
                  )
                }
                name={`AssetStatusToggle_${row?.original?.assetId}`}
                disabled={
                  row?.original?.svUploadAssetUploadStatus ===
                  UPLOAD_ASSET_UPLOAD_STATUS?.WARNING
                    ? false
                    : true
                }
              />
            }
          />
        </Grid>
      ),
      // ) : (
      //   <></>
      // ),
    },
  ];
  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensures the overlay is above everything
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && visibleFileContent ? (
        <>
          <InputLabel
            htmlFor="fileInput"
            sx={{
              background: theme.palette.background.default,
              py: 3.75,
              px: 0,
              textAlign: "center",
              height: "150px",
              borderRadius: "4px",
              border: "1px dashed gray",
              cursor: "pointer",
              mb: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CloudUploadIcon sx={{ color: theme.palette.error.main }} /> Drop
            file here to upload
            <Input
              onChange={(event) => {
                setSelectedFile(event?.target?.value);
              }}
              type="file"
              id="fileInput"
              sx={{ display: "none" }}
            ></Input>
          </InputLabel>
          <Box>
            {selectedFile ? (
              <Typography variant="subtitle2" display={"flex"} gap={0.5}>
                {selectedFile}
              </Typography>
            ) : (
              <>
                <Typography variant="subtitle2" display={"flex"} gap={0.5}>
                  Use this
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "blue", cursor: "pointer" }}
                    onClick={() => handleDownloadTemplate(normalUpload)}
                  >
                    sample.csv
                  </Typography>
                  file for reference.
                </Typography>
              </>
            )}
          </Box>

          <Stack
            mt={2}
            direction={"row"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{
                boxShadow: theme.customShadows.secondary,
                ":hover": {
                  boxShadow: "none",
                },
              }}
              onClick={() => handleUpload()}
              disabled={!selectedFile || isLoading} // Disable button when loading
            >
              Upload
            </Button>
          </Stack>
          <Box my={2}>
            <Divider />
          </Box>
          {nessusUploadFileList?.length > 0 ? (
            <VmTable
              tableTitle={"Uploaded Asset"}
              data={fileDetail}
              columns={columns}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <VmTable
          tableTitle={"Vulnerability"}
          // data={nessusUploadAssetSummaryList}
          data={demoData}
          columns={vulColumns}
          headerAction={
            <ActionButton
              setChangeAssetStatus={setChangeAssetStatus}
              setVisibleFileContent={setVisibleFileContent}
              setSelectFileUploadId={setSelectFileUploadId}
              handleApprove={handleApprove}
              handleReject={handleReject}
              // handleAction={props.handleAction}
              // selectedIds={props.selectedIds}
              // handleCloseUserGroupDialog={handleCloseUserGroupDialog}
            />
          }
        />
      )}
    </>
  );
};

export default NessusUpload;
