import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";

// routing
import router from "routes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import Locales from "ui-component/Locales";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";

import ThemeCustomization from "themes";

// auth provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import GlobalLoader from "views/Common/GlobalLoader";

// ==============================|| APP ||============================== //

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <NavigationScroll>
            <AuthProvider>
              <>
                <GlobalLoader />
                <Suspense fallback={<div>Loading...</div>}>
                  <RouterProvider router={router} />
                </Suspense>
                <Snackbar />
                <Toaster position="top-center" reverseOrder={false} />
              </>
            </AuthProvider>
          </NavigationScroll>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
