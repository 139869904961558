import { Card, darken, lighten, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { download, generateCsv, mkConfig } from "export-to-csv";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const VmTable = (props) => {
  const [rowSelection, setRowSelection] = useState({});
  const { setDataloading } = useSelector((state) => state?.userState);
  const theme = useTheme();
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(props.data);
    download(csvConfig)(csv);
  };
  useEffect(() => {
    if (rowSelection) {
      const selectedIds = Object.keys(rowSelection);
      // ?.map(
      //   (item) => props?.data[item]?.[props?.selectedIdsField]
      // );
      props?.handleChangeRowSelect && props?.handleChangeRowSelect(selectedIds);
    }
  }, [rowSelection]);
  useEffect(() => {
    if (Object.keys(rowSelection)?.length && !props?.selectedIds?.length) {
      setRowSelection({});
    }
  }, [props?.selectedIds]);

  const baseBackgroundColor =
    theme.palette.mode === "dark" ? "rgb(246 246 246)" : "#ffffff";

  const columns = useMemo(() => props.columns, [props.columns]);

  const table = useMaterialReactTable({
    columns,
    data: props.data,
    enableStickyHeader: true,
    initialState: { density: "compact", columnPinning: { right: ["actions"] } },
    enableRowSelection: props.enableRowSelection,
    enableRowActions: props.tableActions,
    getRowId: (row) => row?.[props?.selectedIdsField],
    renderRowActions: () => props.tableActions,
    onRowSelectionChange: setRowSelection,
    // state: { rowSelection, isLoading: setDataloading },
    state: { rowSelection },
    positionActionsColumn: "last",
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "0",
        border: "none",
        // height: "75vh",
        overflow: "auto",
      },
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.1),
          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: lighten(baseBackgroundColor, 0.1),
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
          },
      }),
    },
    enablePagination: props?.enablePagination,
    enableBottomToolbar: props?.enableBottomToolbar,
    enableGlobalFilter: false, // Disable search options
  });

  return (
    <>
      <Card sx={{ marginLeft: "0px" }}>
        {(props.headerAction || props.tableTitle) && (
          <Stack
            direction={"column"}
            p={1}
            sx={{
              borderBottom: `1px solid #eeeeee`,
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={3}
            >
              <Typography variant="h2">{props.tableTitle}</Typography>
              {props.headerAction && (
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {props.headerAction}
                </Stack>
              )}
            </Stack>

            {props.customAccordion}
          </Stack>
        )}

        <MaterialReactTable table={table} />
      </Card>
    </>
  );
};

export default VmTable;
