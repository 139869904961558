// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { PORTAL_TYPE } from "constant";
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  setDataloading: false,
  error: null,
  userList: [],
  singleUser: {},
  addUser: {},
  updateUser: {},
  loginUserDetail: {},
  orgDetail: {},
};

const slice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // ADD LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
    isLoading(state, action) {
      state.setDataloading = action.payload;
    },
    // GET USER LIST
    getUserListSuccess(state, action) {
      state.userList = action.payload;
    },

    // GET SINGLE USER
    getSingleUserSuccess(state, action) {
      state.singleUser = action.payload;
    },

    // ADD NEW_USER
    addNewUserSucces(state, action) {
      state.addUser = action.payload;
    },

    // UPDATE NEW_USER
    updateUserInfoSucces(state, action) {
      state.updateUser = action.payload;
    },
    // GET USER DETAIL
    getUserDetailSuccess(state, action) {
      state.loginUserDetail = action.payload;
    },
    // GET ORG DETAIL
    getOrgDetailSuccess(state, action) {
      state.orgDetail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  setLoading,
  isLoading,
  getOrgDetailSuccess,
  getSingleUserSuccess,
} = slice.actions;

export const fetchUserList = (params) => {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    dispatch(slice.actions.isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.get(`${prefix}/list`, {
        params,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization:
            localStorage.getItem("serviceToken") &&
            `Bearer ${localStorage.getItem("serviceToken")}`,
        },
      });
      dispatch(slice.actions.getUserListSuccess(response.data));
      dispatch(slice.actions.getSingleUserSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
      dispatch(slice.actions.isLoading(false));
    }
  };
};

export const fetchSingleUser = (userId) => {
  return async () => {
    dispatch(slice.actions.setLoading(true));

    try {
      dispatch(slice.actions.getSingleUserSuccess({}));
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.get(`${prefix}/${userId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization:
            localStorage.getItem("serviceToken") &&
            `Bearer ${localStorage.getItem("serviceToken")}`,
        },
      });
      dispatch(slice.actions.getSingleUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
};

export const addNewUser = (payload) => {
  return async () => {
    dispatch(isLoading(true));
    dispatch(slice.actions.setLoading(true));

    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.post(
        `${prefix}`,
        { ...payload },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization:
              localStorage.getItem("serviceToken") &&
              `Bearer ${localStorage.getItem("serviceToken")}`,
          },
        }
      );
      dispatch(slice.actions.addNewUserSucces(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(slice.actions.setLoading(false));
    }
  };
};

export const updateUserInfo = (payload) => {
  return async () => {
    dispatch(slice.actions.setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.put(
        `${prefix}`,
        { ...payload },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization:
              localStorage.getItem("serviceToken") &&
              `Bearer ${localStorage.getItem("serviceToken")}`,
          },
        }
      );
      dispatch(slice.actions.updateUserInfoSucces(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const getUserDetail = () => {
  return async () => {
    dispatch(slice.actions.setLoading(true));

    dispatch(slice.actions.isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.get(`${prefix}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization:
            localStorage.getItem("serviceToken") &&
            `Bearer ${localStorage.getItem("serviceToken")}`,
        },
      });
      dispatch(slice.actions.getUserDetailSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.isLoading(false));
      dispatch(slice.actions.setLoading(false));
    }
  };
};

export const getOrgDetail = () => {
  return async () => {
    dispatch(slice.actions.isLoading(true));
    dispatch(slice.actions.setLoading(true));

    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/organization"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/organization"
          : "/client/v1/organization";
      const response = await axios.get(`${prefix}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization:
            localStorage.getItem("serviceToken") &&
            `Bearer ${localStorage.getItem("serviceToken")}`,
        },
      });
      dispatch(slice.actions.getOrgDetailSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.isLoading(false));
      dispatch(slice.actions.setLoading(false));
    }
  };
};
