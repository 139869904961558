import { createSlice } from "@reduxjs/toolkit";

// project imports
import { PORTAL_TYPE } from "constant";
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";

// initial state
const initialState = {
  error: null,
  departmentList: [],
  departmentMyList: [],
  addDepartmentRes: {},
  selectedDepartment: {},
  departmentSpocList: [],
  departmentHeadList: [],
  updateDepartmentRes: [],
  assetGroupListForDepartment: [],
  deleteDepartmentRes: {},
  departmentInsightData: {},
  departmentRiskComplianceScore: {},
  departmentPublishedVulnerabilitiesList: [],
  departmentDownloadVulReportRes: {},
  departmentPublishedScansList: [],
  deleteAssetGroupInDepartmentRes: {},
};

// ==============================|| SLICE - DOWNLOAD REPORT ||============================== //
const clientDepartmentApiEndpoint = "/client/v1/department";
const consultantDepartmentApiEndpoint = "/consultant/v1/department";
const platformApiEndpoint = "/paltform/v1/department";

const clientDepartment = createSlice({
  name: "clientDepartment",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    fetchDepartmentSuccess(state, action) {
      state.departmentList = action.payload;
    },
    fetchDepartmentMyListSuccess(state, action) {
      state.departmentMyList = action.payload;
    },
    addDepartmentSuccess(state, action) {
      state.addDepartmentRes = action.payload;
    },
    fetchSelectedDepartmentSuccess(state, action) {
      state.selectedDepartmentDetail = action.payload;
    },
    fetchSelectedDepartmentSuccess(state, action) {
      state.selectedDepartmentDetail = action.payload;
    },
    fetchDepartmentSpocListSuccess(state, action) {
      state.departmentSpocList = action.payload;
    },
    fetchDepartmentHeadListSuccess(state, action) {
      state.departmentHeadList = action.payload;
    },
    updateDepartmentSuccess(state, action) {
      state.updateDepartmentRes = action.payload;
    },
    fetchAssetGroupForDepartmentSuccess(state, action) {
      state.assetGroupListForDepartment = action.payload;
    },
    deleteDepartmentSuccess(state, action) {
      state.deleteDepartmentRes = action.payload;
    },
    fetchDepartmentInsightDataSuccess(state, action) {
      state.departmentInsightData = action.payload;
    },
    fetchDepartmentRiskComplianceScoreSuccess(state, action) {
      state.departmentRiskComplianceScore = action.payload;
    },
    fetchDepartmentPublishedVulnerabilitiesListSuccess(state, action) {
      state.departmentPublishedVulnerabilitiesList = action.payload;
    },
    departmentDownloadVulReportSuccess(state, action) {
      state.departmentDownloadVulReportRes = action.payload;
    },
    fetchDepartmentPublishedScansListSuccess(state, action) {
      state.departmentPublishedScansList = action.payload;
    },
    deleteAssetGroupInDepartmentSuccess(state, action) {
      state.deleteAssetGroupInDepartmentRes = action.payload;
    },
  },
});
export default clientDepartment.reducer;

export function fetchDepartmentMyList() {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientDepartmentApiEndpoint}/my-list`
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentMyListSuccess(response.data)
      );
    } catch (error) {
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function fetchDepartmentList() {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(`${clientDepartmentApiEndpoint}/list`);
      dispatch(clientDepartment.actions.fetchDepartmentSuccess(response.data));
    } catch (error) {
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export const addDeparment = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.post(`${prefix}`, { ...payload });
      dispatch(clientDepartment.actions.addDepartmentSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const fetchSelectedDepartment = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.get(
        `${prefix}/details/{dmId}?dmId=${payload?.dmId}`
      );
      dispatch(
        clientDepartment.actions.fetchSelectedDepartmentSuccess(response.data)
      );
      // notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentSpocList = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.get(`${prefix}/department-spoc-user-list`);
      dispatch(
        clientDepartment.actions.fetchDepartmentSpocListSuccess(response.data)
      );
      // notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentHeadList = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.get(`${prefix}/department-head-user-list`);
      dispatch(
        clientDepartment.actions.fetchDepartmentHeadListSuccess(response.data)
      );
      // notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const updateDeparment = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.put(`${prefix}`, { ...payload });
      dispatch(clientDepartment.actions.updateDepartmentSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const fetchAssetGroupForDepartment = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.get(
        `${prefix}/department-asset-group-list?departmentId=${payload?.departmentId}`
      );
      dispatch(
        clientDepartment.actions.fetchAssetGroupForDepartmentSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const deleteDepartment = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.delete(`${prefix}`, {
        data: payload,
      });
      dispatch(clientDepartment.actions.deleteDepartmentSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchDepartmentInsightData = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/vulnerability-count/${payload?.departmentId}`
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentInsightDataSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentPublishedScansList = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/published-scans/${payload?.departmentId}`,
        { params: payload }
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentPublishedScansListSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentPublishedVulnerabilitiesList = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/published-vulnerabilities/${payload?.departmentId}`,
        { params: payload }
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentPublishedVulnerabilitiesListSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentRiskComplianceScore = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/risk-compliance-score/${payload?.departmentId}`
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentRiskComplianceScoreSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const departmentDownloadVulReport = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/published-vulnerabilities/export/${payload?.departmentId}`,
        { params: payload }
      );
      dispatch(
        clientDepartment.actions.departmentDownloadVulReportSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(clientDepartment.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const deleteAssetGroupInDepartment = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const organizationType = localStorage.getItem("organizationType");

    // Determine the correct API endpoint based on organization type
    const prefix =
      organizationType === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : organizationType === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      // Make delete request using axios with payload data passed in config
      const response = await axios.delete(`${prefix}/department-asset-group`, {
        params: { dmId: payload?.dmId }, // Pass dmId as query param
        data: payload?.data, // Pass any additional data in the delete request
      });

      // Dispatch success action
      dispatch(
        clientDepartment.actions.deleteAssetGroupInDepartmentSuccess(
          response.data
        )
      );

      // Manage notification for the successful response
      notificationManager(response);

      return response;
    } catch (error) {
      // Dispatch error-related actions
      dispatch(openSnackbar(error.message || "An error occurred"));
      dispatch(clientDepartment.actions.hasError(error));

      return Promise.reject(error); // Return rejected promise for further error handling
    } finally {
      dispatch(setLoading(false));
    }
  };
};
