import { createSlice } from "@reduxjs/toolkit";

// project imports
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";

// initial state
const initialState = {
  error: null,
  downloadReportList: [],
  ReportContent: {},
};

// ==============================|| SLICE - DOWNLOAD REPORT ||============================== //
const downloadReportApiEndpoint = "/client/v1/reports/";
const downloadReport = createSlice({
  name: "downloadReport",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    downloadReportListSuccess(state, action) {
      state.downloadReportList = action.payload;
    },

    ReportContentSuccess(state, action) {
      state.ReportContent = action.payload;
    },
  },
});
export default downloadReport.reducer;
export const { ReportContentSuccess } = downloadReport.actions;

export function fetchDownloadReportList() {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${downloadReportApiEndpoint}asset-group-report-list`
      );
      dispatch(downloadReport.actions.downloadReportListSuccess(response.data));
    } catch (error) {
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function getReportContent(payload) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${downloadReportApiEndpoint}asset-group/${payload?.reportCode}/${payload?.rawData}`
      );
      dispatch(downloadReport.actions.ReportContentSuccess(response.data));
      dispatch(notificationManager(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}
