import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CustomCircularProgressWithLabel = (props) => {
  const theme = useTheme();

  const { value, primaryColor, secondaryColor, size, label } = props;

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const textColor = theme.palette.text.primary;

  const getSize = () => {
    if (isXs) return size * 0.5;
    if (isSm) return size * 0.7;
    if (isMd) return size * 0.85;
    return size;
  };

  const adjustedSize = getSize();

  const bgColor =
    theme.palette.mode === "dark" ? theme.palette.background.paper : "#ffffff";
  const borderColor = theme.palette.mode === "dark" ? "#455f95" : "#EEEEEE";
  const boxShadowColor =
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(0, 0, 0, 0.1)";

  return (
    <Grid
      bgcolor={bgColor}
      sx={{
        borderRadius: "8px",
        border: `1px solid ${borderColor}`,
        alignContent: "center",
        textAlign: "center",
        justifyContent: "center",
        boxShadow: `7px 13px 24px 1px ${boxShadowColor}`,
        p: { xs: 2, sm: 3, md: 3 },
      }}
    >
      <Grid>
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: adjustedSize,
            height: adjustedSize,
          }}
        >
          <CircularProgress
            alignItems="center"
            variant="determinate"
            value={100}
            sx={{
              color: secondaryColor,
              position: "absolute",
            }}
            size={adjustedSize}
            thickness={4}
          />
          <CircularProgress
            variant="determinate"
            alignItems="center"
            value={value}
            sx={{
              color: primaryColor,
              position: "absolute",
            }}
            size={adjustedSize}
            thickness={4}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: adjustedSize * 0.25,
            }}
          >
            {Math.round(value)}%
          </Box>
        </Box>
        <Divider />
        <Typography variant="h4" pt={0.5} textAlign="center" color={textColor}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CustomCircularProgressWithLabel;
