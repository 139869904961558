export const NO_VALUE = "N.A";

export const SCAN_TYPE = {
  VAPT: "VAPT",
  VULNERABILITY_ASSESSMENT: "VA",
  CONFIGURATION_AUDIT: "CA",
  SECURE_CODE_REVIEW: "SCR",
  BLACKBOX: "BLACKBOX",
  GREYBOX: "GREYBOX",
};

export const rowsPerPageOptions = [10, 20, 30, 40, 50, 100];

export const pendingWithScanFilterCode = {
  CLIENT: "CL",
  CONSULTANT: "CO",
};

export const complianceStatus = [
  {
    complianceStatusLabel: "Failed",
    complianceStatusCode: "F",
  },
  {
    complianceStatusLabel: "Passed",
    complianceStatusCode: "P",
  },
];
