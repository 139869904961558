import { DialogContent } from '@mui/material';
import React from 'react';
import VmTable from 'ui-component/table';

const VulnerabilityDialogContent = (props) => {
  return (
    <>
      <VmTable {...props} />
    </>
  );
};

export default VulnerabilityDialogContent;
