import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  IconButton,
  Chip,
  TablePagination,
} from "@mui/material";
import { useTheme } from "@mui/system";
import VmTable from "ui-component/table";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VulnerabilityDialogContent from "./vulnerabilityAssets/index.js";
import DialogBox from "ui-component/dialogbox";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useSelector } from "react-redux";
import { dispatch } from "store/index.js";
import { ACTION_TYPE, NO_VALUE } from "constant";
import {
  deleteAssetsToScan,
  getAssetsForScan,
  getScanDetails,
} from "store/slices/scanReducer.js";
import Delete from "ui-component/delete/index.js";
import DeleteIcon from "@mui/icons-material/Delete";
import ScanAssetDetails from "./scanAssetAdditionalInfo/index.js";
import { getChipDesign, userActionAccess } from "utils/helpers.js";
import { rowsPerPageOptions } from "../../constant.js";

const AssetsActionButton = (props) => {
  const params = useParams();
  const location = useLocation().pathname.split("/")[1];
  const theme = useTheme();
  const handleDeleteAssets = () => {
    props?.handleDeleteAssetDialog();
  };
  return (
    <>
      <Link
        to={{
          pathname: `/scan/${params?.scanId}/assets/add`,
          state: { orgId: props?.orgId }, // Pass the additional value here
        }}
      >
        {props?.scanDetails?.displayScanAssetAction?.addAsset ? (
          <IconButton
            title="Add Asset"
            variant="contained"
            size="large"
            color="inherit"
            aria-label="live customize"
            sx={{ color: "grey" }}
          >
            <AddIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Link>
      {props?.scanDetails?.displayScanAssetAction?.deleteAsset ? (
        <IconButton
          title="Delete"
          variant="contained"
          disabled={!props?.selectedIds?.length}
          size="large"
          color="inherit"
          onClick={handleDeleteAssets}
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        <></>
      )}
    </>
  );
};

const ScanAssets = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation()?.pathname?.split("/")[1];
  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const [openDeleteAssetDialog, setOpenDeleteAssetDialog] = useState();
  const [selectAssetsId, setSelectAssetsId] = useState();
  const [selectedIds, setSelectedIds] = useState();
  const [openAdditionalInfoDialog, setOpenAdditionalInfoDialog] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const { scanAssetsVulnerability } = useSelector((state) => state?.scansState);
  const clientOrgId = sessionStorage.getItem("clientOrgId");
  const { setDataloading } = useSelector((state) => state?.userState);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_ADDED_ASSET)) {
    dispatch(
      getAssetsForScan({
        scanId: params?.scanId,
        page,
        size: rowsPerPage,
        clientOrgId,
      })
    );
    // }
  }, [page, rowsPerPage, params?.scanId]);

  useEffect(() => {
    dispatch(getScanDetails(params?.scanId, clientOrgId));
  }, [props?.assetsForScan]);

  const handleClickOpen = (cell) => {
    setSelectAssetsId(cell?.row?.original?.assetId);
    setOpen(true);
  };
  const handleClickAddVulnerability = (cell) => {
    navigate(
      `/scan/${params?.scanId}/asset/${cell?.row?.original?.assetId}/vulnerability/add`
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAdditionalInfoDialog = () => {
    setOpenAdditionalInfoDialog(true);
  };

  const handleCloseScanAssetDetailsDialog = () => {
    setOpenAdditionalInfoDialog(false);
  };

  const handleSelectedRowData = (data) => {
    setSelectedRow(data);
    setOpenAdditionalInfoDialog(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "assetName",
        header: "Asset Name",
        size: 150,
      },
      {
        accessorKey: "assetTypeName",
        header: "Asset Type",
        size: 150,
      },
      {
        accessorKey: "assetSubTypeName",
        header: "Asset Sub Type",
        Cell: ({ row }) => (
          <>
            {row?.original?.assetSubTypeName
              ? row?.original?.assetSubTypeName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "cloudProviderName",
        header: "Cloud Type",
        Cell: ({ cell, row }) => (
          <>
            {row?.original?.cloudProviderName
              ? row?.original?.cloudProviderName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "assetURL",
        header: "IP Address/URL",
        Cell: ({ row }) => (
          <>{row?.original?.assetURL ? row?.original?.assetURL : NO_VALUE}</>
        ),
      },
      {
        accessorKey: "totalVulnerabilities",
        header: "Total Vulnerabilities",
        size: 150,
      },
      {
        accessorKey: "decommissionedName",
        header: "Decommissioned",
        size: 150,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 50,
        // muiTableBodyCellProps: ({ row }) => ({}),
        Cell: ({ cell, row }) => (
          <>
            {/* <IconButton onClick={() => handleClickOpen(cell)}>
              <VisibilityIcon sx={{ color: theme.palette.secondary.main }} />
            </IconButton> */}

            {props?.scanDetails?.displayScanVulnerabilityAction
              ?.addVulnerability && (
              <IconButton onClick={() => handleClickAddVulnerability(cell)}>
                <AddCircleIcon />
              </IconButton>
            )}

            <IconButton
              onClick={() => {
                handleSelectedRowData(row?.original);
              }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </>
        ),
      },
    ],
    []
  );
  //

  // table Column
  const visibilityColumns = useMemo(
    () => [
      {
        accessorKey: "vulnerabilityName",
        header: "Vulnerability",
        size: 150,
      },
      {
        accessorKey: "easeOfExploitationName",
        header: "Ease of Exploitation",
        size: 150,
      },
      {
        accessorKey: "riskRatingName",
        header: "Risk Rating",
        Cell: ({ row }) => {
          return row?.original?.riskRatingName ? (
            <Chip
              size="small"
              label={row?.original?.riskRatingName}
              variant="outlined"
              sx={{
                color:
                  getChipDesign(row?.original?.riskRatingName)?.fontColor ||
                  "black",
                border:
                  "1.5px solid " +
                  getChipDesign(row?.original?.riskRatingName)?.fontColor,
              }}
            />
          ) : (
            ""
          );
        },
      },
    ],
    []
  );

  const handleChangeRowSelect = (selectedIds) => {
    setSelectedIds(selectedIds);
  };
  const handleDeleteAssetDialog = () => {
    setOpenDeleteAssetDialog(!openDeleteAssetDialog);
  };
  const handleAssetDeleteAction = () => {
    const payload = {
      deleteIdList: selectedIds,
    };
    setSelectedIds([]);
    dispatch(deleteAssetsToScan(payload, params?.scanId, clientOrgId))
      .then((response) => {
        if (response?.status >= 200 && response?.status < 400) {
          // if (userActionAccess(ACTION_TYPE?.GET_SCAN_ADDED_ASSET)) {
          dispatch(
            getAssetsForScan({
              scanId: params?.scanId,
              page,
              size: rowsPerPage,
              clientOrgId
            })
          );
          // }
        }
      })
      .catch(() => {});
    handleDeleteAssetDialog();
    handleClose();
  };

  // useEffect(() => {
  let assetList = [];
  assetList = props?.assetsForScan?.data?.map((val) => {
    return {
      ...val?.assetDetails,
      totalVulnerabilities: val?.totalVulnerabilities,
    };
  });
  // }, [props?.assetsForScan])

  return (
    <>
      <VmTable
        enableRowSelection={true}
        enablePagination={false}
        enableBottomToolbar={false}
        headerAction={
          <AssetsActionButton
            selectedIds={selectedIds}
            handleDeleteAssetDialog={handleDeleteAssetDialog}
            scanDetails={props?.scanDetails}
            orgId={clientOrgId}
          />
        }
        columns={columns}
        data={assetList || []}
        selectedIdsField={"assetId"}
        selectedIds={selectedIds}
        handleChangeRowSelect={handleChangeRowSelect}
      />

      <TablePagination
        component="div"
        count={
          props?.assetsForScan?.totalRecords
            ? props?.assetsForScan?.totalRecords
            : 0
        }
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
      <DialogBox
        maxWidth="md"
        open={open}
        handleClose={handleClose}
        modelTitle={"All Vulnerabilities"}
        dialogContent={
          <VulnerabilityDialogContent
          // columns={visibilityColumns}
          // data={scanAssetsVulnerability}
          />
        }
      />

      <DialogBox
        maxWidth="sm"
        modelTitle={"Delete Asset"}
        open={openDeleteAssetDialog}
        dialogContent={
          <Delete
            handleDeleteAction={handleAssetDeleteAction}
            handleClose={handleDeleteAssetDialog}
            loading={setDataloading}
          />
        }
      />

      <DialogBox
        maxWidth="md"
        open={openAdditionalInfoDialog}
        handleClose={handleCloseScanAssetDetailsDialog}
        modelTitle={"Additional Information"}
        dialogContent={
          <ScanAssetDetails
            handleOpenDialog={handleOpenAdditionalInfoDialog}
            data={selectedRow}
          />
        }
      />
    </>
  );
};

export default ScanAssets;
