import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, InputLabel, useTheme, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormControlSelect from "ui-component/extended/Form/FormControlSelect";
import { actionType } from "../../../constant";

const ActionModal = ({
  data,
  roleList,
  handleClose,
  statusDetail,
  setStatusDetail,
  handleSubmitStatus,
  selectedIds,
  displayActionList,
}) => {
  const { userRoles } = useSelector((state) => state.menu);
  const [actionData, setActionData] = useState([]);
  const theme = useTheme();
  const { loading } = useSelector((state) => state?.userState);
  const [errors, setErrors] = useState({
    data: "",
  });

  // Map for action types
  const keyToActionValueMap = {
    showMarkAsOpen: "O",
    showMarkAsClose: "C",
    showMarkAsFalsePositive: "FP",
    showMarkAsRetest: "RT",
    showMarkAsReview: "RV",
    showMarkAsWIP: "WIP",
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { data: "" };
    if (statusDetail.data === "selected" && selectedIds?.length <= 0) {
      newErrors.data = "Please select a vulnerability.";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setStatusDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        await handleSubmitStatus();
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        handleClose();
      }
    }
  };

  useEffect(() => {
    let filteredActions = [];
    actionType.forEach((action) => {
      const displayKey = Object.keys(keyToActionValueMap).find(
        (key) => keyToActionValueMap[key] === action.value
      );

      if (displayKey && displayActionList[displayKey]) {
        filteredActions.push(action);
      }
    });

    if (userRoles?.length === 1) {
      if (userRoles[0] === "CO_SECURITY_MANAGER") {
        filteredActions = filteredActions.filter(
          (action) => action.value !== "WIP"
        );
      } else {
        filteredActions = filteredActions.filter(
          (action) => action.value !== "RV"
        );
      }
    }

    setActionData(filteredActions);
  }, [userRoles]);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={6}>
              <InputLabel>Data :</InputLabel>
              <FormControlSelect
                itemsList={data || []}
                optionLabel={"label"}
                optionValue={"value"}
                selected={statusDetail?.data}
                value={statusDetail?.data}
                onChange={handleOnChange}
                name="data"
                autoComplete="on"
              />
              {errors.data && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.data}
                </span>
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel>Mark For :</InputLabel>
              <FormControlSelect
                itemsList={roleList || []}
                defaultValue={statusDetail?.markFor}
                optionLabel={"label"}
                optionValue={"value"}
                onChange={handleOnChange}
                name="markFor"
                selected={statusDetail?.markFor}
                value={statusDetail?.markFor}
                autoComplete="on"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel>Mark As :</InputLabel>
              <FormControlSelect
                itemsList={actionData}
                defaultValue={statusDetail?.status}
                optionLabel={"label"}
                optionValue={"value"}
                onChange={handleOnChange}
                selected={statusDetail?.status}
                value={statusDetail?.status}
                name="status"
                autoComplete="on"
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            my={2}
          >
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                size="large"
                variant="contained"
                color="secondary"
                sx={{
                  boxShadow: theme.customShadows.secondary,
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
                type="submit"
                loading={loading}
                onClick={handleSubmit}
                disabled={
                  !(
                    statusDetail?.status &&
                    statusDetail?.markFor &&
                    statusDetail?.data
                  )
                }
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ActionModal;