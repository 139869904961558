import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";

const Delete = (props) => {
  return (
    <>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
        mt={3}
      >
        <Typography variant="h3">
          Are you sure you want to delete selected record?
        </Typography>
        <Typography variant="body1">
          By deleting column, all task inside that column will also be deleted.
        </Typography>
      </Stack>

      <Stack direction={"row"} justifyContent={"flex-end"} gap={2} mt={4}>
        <Button size="large"  onClick={props?.handleClose}>
          Cancel
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          autoFocus
          onClick={props?.handleDeleteAction}
          loading={props?.setDataLoading}
          color="error"
        >
          Delete
        </LoadingButton>
      </Stack>
    </>
  );
};

export default Delete;
