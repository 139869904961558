// third-party
import { createSlice } from "@reduxjs/toolkit";
import { notificationManager } from "ui-component/notificationManager";
import { openSnackbar } from "./snackbarReducer";

// project imports
import axios from "utils/axios";
import { dispatch } from "../index";
import { setLoading } from "./user";

const initialState = {
  error: null,
  consultantorgList: [],
  consultantClientUnmappedList: [],
  consultantClientMappedList: [],
  consultantClientMappingData: [],
  selectedClientMappingDetails: {},
};

const slice = createSlice({
  name: "clientconsultantmap",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // --------------------- GET APIs --------------------------------
    getConsultantOrganizationSuccess(state, action) {
      state.consultantorgList = action.payload;
    },

    getConsultantClientUnmappedList(state, action) {
      state.consultantClientUnmappedList = action.payload;
    },

    getConsultantClientMappedList(state, action) {
      state.consultantClientMappedList = action.payload;
    },

    getSelectedClientMappingDetails(state, action) {
      state.selectedClientMappingDetails = action.payload;
    },

    //--------------------POST APIs-------------------------------------
    addconsultantClientMappingPost(state, action) {
      state.consultantClientMappingData = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

//----------------------------------------------------------
export const getConsultantOrganization = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get("/platform/v1/consultant-org/list");
      dispatch(slice.actions.getConsultantOrganizationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getUnmappedClientOrganization = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/platform/v1/consultant-org/unmapped-clients/${payload.orgId}`
      );
      dispatch(slice.actions.getConsultantClientUnmappedList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMappedClientOrganization = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/platform/v1/consultant-org/mapped-clients/${payload.orgId}`
      );
      dispatch(slice.actions.getConsultantClientMappedList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addUnmappedClientOrganization = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `/platform/v1/consultant-org/map-clients/${payload.orgId}`,
        payload.data
      );
      dispatch(slice.actions.addconsultantClientMappingPost(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchSelectedClientOrganizationDetails = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/platform/v1/consultant-org/details/${payload.orgId}`
      );
      dispatch(slice.actions.getSelectedClientMappingDetails(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};