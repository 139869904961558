import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "axios";
import { PORTAL_TYPE } from "constant";
import { dispatch } from "../index";
import { setLoading } from "./user";

// initial state
const initialState = {
  selectedItem: [""],
  selectedID: null,
  drawerOpen: false,
  error: null,
  menu: [],
  userRoles: [],
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.selectedItem = action.payload;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // get dashboard menu
    getMenuSuccess(state, action) {
      state.menu = action.payload;
    },

    // get user-roles
    getRoles(state, action) {
      state.userRoles = action.payload;
    },
  },
});

export default menu.reducer;

export const { activeItem, openDrawer, activeID, getMenuSuccess } =
  menu.actions;

export function getMenu() {
  return async () => {
    dispatch(setLoading(true));

    try {
      if (localStorage.getItem("serviceToken")) {
        const prefix =
          localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
            ? "consultant"
            : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
            ? "platform"
            : "client";
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/${prefix}/v1/user/menus-actions`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization:
                localStorage.getItem("serviceToken") &&
                `Bearer ${localStorage.getItem("serviceToken")}`,
            },
          }
        );
        dispatch(menu.actions.getMenuSuccess(response.data.menus));
        localStorage.setItem("actions", response?.data?.actions?.toString());
      }
    } catch (error) {
      dispatch(menu.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function getUsersRoles() {
  return async () => {
    dispatch(setLoading(true));

    try {
      if (localStorage.getItem("serviceToken")) {
        const prefix =
          localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
            ? "consultant"
            : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
            ? "platform"
            : "client";
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/${prefix}/v1/user/assigned-roles`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization:
                localStorage.getItem("serviceToken") &&
                `Bearer ${localStorage.getItem("serviceToken")}`,
            },
          }
        );
        dispatch(menu.actions.getRoles(response.data));
      }
    } catch (error) {
      dispatch(menu.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}
