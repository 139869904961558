const LAYOUT_CONST = {
  VERTICAL_LAYOUT: "vertical",
  HORIZONTAL_LAYOUT: "horizontal",
  DEFAULT_DRAWER: "default",
  MINI_DRAWER: "mini-drawer",
};

export { LAYOUT_CONST };
export const PORTAL_TYPE = {
  CONSULTANT: "CO",
  PLATFORM: "PT",
  CLIENT: "CL",
  VENTOR_PORTAL: "Vendor portal",
  PLATFORM_PORTAL: "Platform portal",
  CLIENT_PORTAL: "Client portal",
  VENTOR_DASHBOARD: "Vendor Dashboard",
  PLATFORM_DASHBOARD: "Platform Dashboard",
  CLIENT_DASHBOARD: "Client Dashboard",
};
export const validateField = {
  WEB: [
    "assetName",
    "ipAddress",
    "operatingSystem",
    "version",
    "decommissioned",
  ],
  APPLICATION: [
    "assetName",
    "ipAddress",
    "operatingSystem",
    "version",
    "decommissioned",
  ],
  DATABASE: [
    "assetName",
    "ipAddress",
    "operatingSystem",
    "version",
    "decommissioned",
  ],
  INFRASTRUCTURE: [
    "assetName",
    "operatingSystem",
    "ipAddress",
    "version",
    "decommissioned",
  ],
  OTHERS: [
    "assetName",
    "ipAddress",
    "maker",
    "version",
    "decommissioned",
    "operatingSystem",
  ],
  ROUTER: [
    "assetName",
    "maker",
    "ipAddress",
    "operatingSystem",
    "version",
    "decommissioned",
  ],
  SWITCH: [
    "assetName",
    "maker",
    "ipAddress",
    "version",
    "decommissioned",
    "operatingSystem",
  ],
  FIREWALL: [
    "assetName",
    "maker",
    "ipAddress",
    "version",
    "decommissioned",
    "operatingSystem",
  ],
  "WEB APPLICATION": ["assetName", "decommissioned", "version", "ipAddress"],
  "ANDROID APPLICATION": [
    "assetName",
    "packageLink",
    "decommissioned",
    "version",
  ],
  "IOS APPLICATION": ["assetName", "packageLink", "decommissioned", "version"],
  API: ["assetName", "collectionFile", "ipAddress", "version"],
  "THICK CLIENT": ["assetName", "language", "decommissioned", "version"],
  "CLOUD_N.A": [
    "assetName",
    "cloudType",
    "ipAddress",
    "decommissioned",
    "version",
  ],
  "OTHERS_N.A": [
    "assetName",
    "operatingSystem",
    "ipAddress",
    "version",
    "decommissioned",
    "packageLink",
    "collectionFile",
    "language",
    "maker",
  ],
  "ENDPOINTS_N.A": [
    "assetName",
    "operatingSystem",
    "ipAddress",
    "version",
    "decommissioned",
  ],
};
export const NO_VALUE = "N.A";
export const ACTION_TYPE = {
  UPD_USER_DTL: "UPD_USER_DTL",
  ADD_USER: "ADD_USER",
  UPD_UG: "UPD_UG",
  ADD_ASSET_AG: "ADD_ASSET_AG",
  GET_ASSET_MST: "GET_ASSET_MST",
  REMOVE_USER_UG: "REMOVE_USER_UG",
  UPD_ASSET_GRP: "UPD_ASSET_GRP",
  ADD_USER_UG: "ADD_USER_UG",
  REMOVE_ASSET_AG: "REMOVE_ASSET_AG",
  DEL_ASSET_MST: "DEL_ASSET_MST",
  UPD_ASSET_MST: "UPD_ASSET_MST",
  CREATE_ASSET_GRP: "CREATE_ASSET_GRP",
  // DEL_UG: "DEL_UG",
  CREATE_UG: "CREATE_UG",
  GET_USER_DTL: "GET_USER_DTL",
  // DEL_ASSET_GRP: "DEL_ASSET_GRP",
  ADD_ASSET_MST: "ADD_ASSET_MST",
  GET_ASSET_AG: "GET_ASSET_AG",
  GET_USER_GRP_DTL: "GET_USER_GRP_DTL",
  ADD_ORG: "ADD_ORG",
  UPD_ORG: "UPD_ORG",
  GET_ORG: "GET_ORG",
  UPD_PROFILE_DTL: "UPD_PROFILE_DTL",
  UPLOAD_ASSET: "UPLOAD_ASSET",
  UPD_REPORT_CONFI: "UPD_REPORT_CONFI",
  ASSIGN_SM: "ASSIGN_SM",
  GET_SM: "GET_SM",
  // UPD_SCAN: "UPD_SCAN",
  GET_SCAN: "GET_SCAN",
  DEL_SCAN: "DEL_SCAN",
  ADD_SCAN_ASSET: "ADD_SCAN_ASSET",
  DEL_SCAN_ASSET: "DEL_SCAN_ASSET",
  ADD_ASSET_VUL: "ADD_ASSET_VUL",
  GET_SCAN_ADDED_ASSET: "GET_SCAN_ADDED_ASSET",
  // GET_VUL_DETAILS: "GET_VUL_DETAILS",
  // DEL_VUL_DETAILS: "DEL_VUL_DETAILS",
  // UPD_VUL_DETAILS: "UPD_VUL_DETAILS",
  // ADD_VUL_CMT: "ADD_VUL_CMT",
  // UPD_VUL_CMT: "UPD_VUL_CMT",
  // DEL_VUL_CMT: "DEL_VUL_CMT",
  // UPD_REPORT_CONFIG: "UPD_REPORT_CONFIG",
  GET_REPORT_CONFIG: "GET_REPORT_CONFIG",
  CREATE_SCAN_REPORT: "CREATE_SCAN_REPORT",
  GET_SCAN_REPORT: "GET_SCAN_REPORT",
  ADD_SCAN_CMT: "ADD_SCAN_CMT",
  // UPD_SCAN_CMT: "UPD_SCAN_CMT",
  // DEL_SCAN_CMT: "DEL_SCAN_CMT",
  UPD_SCAN_TESTER: "UPD_SCAN_TESTER",
  GET_PROFILE_DTL: "GET_PROFILE_DTL",
  ADD_ASSET_GRP: "ADD_ASSET_GRP",
  ADD_ASSIGN_CONS: "ADD_ASSIGN_CONS",
  GET_ASSIGN_CONS_DTL: "GET_ASSIGN_CONS_DTL",
  GET_SCAN_LIST: "GET_SCAN_LIST",
  GET_SCAN_DTL: "GET_SCAN_DTL",
  ADD_SCAN: "ADD_SCAN",
  GET_SCAN_REPORT_CONFIG: "GET_SCAN_REPORT_CONFIG",
  UPD_SCAN_REPORT_CONFIG: "UPD_SCAN_REPORT_CONFIG",
  DEL_SCAN_CMT: "DEL_SCAN_CMT",
  GET_SCAN_CMT_LIST: "GET_SCAN_CMT_LIST",
  GET_SCAN_PUB_VUL_LIST: "GET_SCAN_PUB_VUL_LIST",
  GET_PUB_VUL_LIST: "GET_PUB_VUL_LIST",
  GET_RESCAN_VUL_LIST: "GET_RESCAN_VUL_LIST",
  GET_SCAN_VUL_DTL: "GET_SCAN_VUL_DTL",
  UPD_RESCAN_PUB_Vul: "UPD_RESCAN_PUB_Vul",
  GET_VUL_STATUS_HIS: "GET_VUL_STATUS_HIS",
  GET_PUB_VUL_LIST: "GET_PUB_VUL_LIST",
  GET_ASSET_GRP_REP: "GET_ASSET_GRP_REP",
  GET_AGE_ANALYSIS: "GET_AGE_ANALYSIS",
  GET_VUL_EVD_LIST: "GET_VUL_EVD_LIST",
  UPD_SLA_DTL: "UPD_SLA_DTL",
  GET_SLA_DTL: "GET_SLA_DTL",
  ADD_CO_ORG: "ADD_CO_ORG",
  GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
  CREATE_ADMIN_CRED: "CREATE_ADMIN_CRED",
  GET_CUSTOMER_DTL: "GET_CUSTOMER_DTL",
  GET_ORG_DTL: "GET_ORG_DTL",
  UPD_ORG: "UPD_ORG",
  ADD_CUSTOMER_ORG: "ADD_CUSTOMER_ORG",
};
export const RISK_RATING = {
  CRITICAL: "Critical",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
  INFO: "Info",
  CRITICAL_COLOR: "red",
  CRITICAL_FONT_COLOR: "red",
  HIGH_COLOR: "purple",
  HIGH_FONT_COLOR: "purple",
  MEDIUM_COLOR: "yellow",
  MEDIUM_FONT_COLOR: "yellow",
  LOW_COLOR: "green",
  LOW_FONT_COLOR: "green",
  INFO_COLOR: "blue",
  INFO_FONT_COLOR: "blue",
};
export const SCAN_STATUS_CODE = {
  SCHEDULED: "SCHEDULED",
  HOLD: "HOLD",
  WIP: "WIP",
  ABORT: "ABORT",
  REVIEW: "REVIEW",
  CHANGE_REQUESTED: "CHANGE_REQUESTED",
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  INITIAL: "I",
  COMFORMATORY: "C",
  REVIEW: "REVIEW",
  APPROVED: "APPROVED",
};

export const USER_ROLES = {
  CO_SECURITY_MANAGER: "CO_SECURITY_MANAGER",
  CO_TESTER: "CO_TESTER",
};

export const CISO_DASHBOARD_TYPE = {
  OVERALL_SUMMARY: "Overall Summary",
  RISK_SUMMARY: "Risk Summary",
  SCANS_AND_PATCH_SUMMARY: "Scans And Patch Summary",
};

export const OVERSALL_SUMMARY_DASHBOARD = {
  TOTAL_ASSETS: "Total Assets",
  TOTAL_VULNERABILITIES: "Total Vulnerabilities",
  ACCTEPTED_RISKS: "Accepted Risks",
  ONGOING_SCANS: "Ongoing Scans",
  ONGOING_RESCANS: "Ongoing Rescans",
};

export const RISK_SUMMARY_DASHBOARD = {
  TOTAL_VULNERABILITIES: "Total Vulnerabilities",
  ACCTEPTED_RISKS: "Accepted Risks",
  ONE_YEAR_PLUS_VULNERABILITIES: "1y+ Vulnerabilities",
  SIX_MONTHS_TO_ONE_YEAR_VULNERABILITIES: "6m-1y+ Vulnerabilities",
  THREE_MONTHS_TO_SIX_MONTHS_VULNERABILITIES: "3m-6m Vulnerabilities",
  ASSET_LAST_SCANNED_SIX_MONTHS_TO_ONE_YEAR: "Asset Scan 6m-2m",
  ASSET_LAST_SCANNED_ONE_YEAR_TO_TWO_YEARS: "Asset Scan 1y-2y",
  ASSET_LAST_SCANNED__TWO_PLUS_YEARS: "Asset Scan 2y+",
  TOTAL_UNIQUE_COMPLIANCE_ISSUES: "Unique Compliance Issues",
};

export const SCANS_AND_PATCH_SUMMARY_DASHBOARD = {
  ONGOING_SCANS: "Ongoing Scans",
  ONGOING_RESCANS: "Ongoing Rescans",
  THIRTY_DAYS_PLUS_SCANS: "30+ Days Scans",
};

export const ACCTEPTED_RISK = "Accepted RisK";

export const graph_backgroud = "#f4eced";

export const CLIENT_VULNERABILITY_STATUS_CODE = {
  FIXED: "FIXED",
  FALSE_POSITIVE: "FALSE_POSITIVE",
};

export const ASSET_TYPE = {
  SERVER: "Servers",
  NETWORK_DEVICES: "Network Devices",
  APPLICATION: "Application",
  ENDPOINTS: "Endpoints",
  CLOUD: "Cloud",
  OTHERS: "Others",
};

const ASSET_LEVEL_SCAN = {};
