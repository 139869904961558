import { createSlice } from "@reduxjs/toolkit";

// project imports
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";

// initial state
const initialState = {
  error: null,
  otp: {},
  forgetPasswordReponse: {},
  getRsaCredentialEncryptionKeyRes: {},
};

// ==============================|| SLICE - FORGOT PASSOWORD ||============================== //

const forgotPassword = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getOtp(state, action) {
      state.otp = action.payload;
    },

    forgotPasswordSuccess(state, action) {
      state.changePasswordResponce = action.payload;
    },

    getRsaCredentialEncryptionKeySuccess(state, action) {
      state.getRsaCredentialEncryptionKeyRes = action.payload;
    },
  },
});

export default forgotPassword.reducer;

export function getOtpToForgotPassword(loginName) {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(false));
    try {
      const response = await axios.post(`/forgot-password-otp/${loginName}`);
      dispatch(forgotPassword.actions.getOtp(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(forgotPassword.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function ForgotPasswordEvent(payload) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(false));
    try {
      const response = await axios.post(`/new-password`, payload);
      dispatch(forgotPassword.actions.forgotPasswordSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(forgotPassword.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}
export function getRsaCredentialEncryptionKey() {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(false));
    try {
      const response = await axios.get(`/credential-encryption-key`);
      dispatch(
        forgotPassword.actions.getRsaCredentialEncryptionKeySuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(forgotPassword.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}
