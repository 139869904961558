export const NO_VALUE = "N.A";

export const rowsPerPageOptions = [10, 20, 30, 40, 50, 100];
export const SCAN_SUB_TYPE = {
  mobile: "Mobile",
  thick_client: "Thick Client",
};

export const vulnerabilityCategory = {
  custom_label: "Custom",
  custom_value: "custom",
  defined_label: "Defined",
  defined_value: "defined",
};
