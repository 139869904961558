import React, { useEffect } from "react";
// import {
//   Cell,
//   Legend,
//   Pie,
//   PieChart,
//   ResponsiveContainer,
//   Tooltip,
// } from "recharts";

import { Divider, Grid, Typography, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownloadOutlined"; // Import the export/download icon
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Box } from "@mui/system";

am4core.addLicense("CH199370475"); // Replace with your actual license key


const AssetDetailPiechart = ({ label, data, displayLegend, lastPublished }) => {
  const theme = useTheme();
  const bgColor =
    theme.palette.mode === "dark" ? theme.palette.background.paper : "#ffffff";
  const borderColor = theme.palette.mode === "dark" ? "#455f95" : "#EEEEEE";
  const boxShadowColor =
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(0, 0, 0, 0.1)";
  const textColor = theme.palette.text.primary;

  useEffect(() => {
    am4core.useTheme(am4themes_animated);

    const chart = am4core.create(`chartdiv-${label}`, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;

    if (displayLegend) {
      chart.legend = new am4charts.Legend();
      chart.legend.maxWidth = 1;
      chart.legend.useDefaultMarker = false;

      var markerTemplate = chart.legend.markers.template;
      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 1;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#ccc");

      // Set legend label color based on theme
      chart.legend.labels.template.fill = am4core.color(
        theme.palette.mode === "dark" ? "#FFFFFFB2" : "#000000B2"
      );

      chart.legend.labels.template.text = "{category}";
      chart.legend.valueLabels.template.disabled = true;

      markerTemplate.width = 10;
      markerTemplate.height = 10;
    }

    chart.data = data;
    chart.hiddenState.properties.opacity = 0;

    chart.innerRadius = 60;

    const colors = [
      "#75F993",  // Open
      "#7086FD",  // Close
      "#FFAE4C",  // Won't Fix
      "#07DBFA",  // Accepted Risk
      "#ff9595",  // Won't Fix
      "#988AFC",  // False Positive
      "#DB4635",  // Fixed
    ];
    const series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.slices.template.strokeWidth = 0;
    series.slices.template.strokeOpacity = 0;
    series.ticks.template.stroke = "color";
    series.ticks.template.strokeOpacity = 1;
    series.slices.template.stroke = am4core.color("transparent"); // This sets the stroke color to transparent

    series.ticks.template.propertyFields.stroke = "color";
    series.slices.template.propertyFields.fill = "color";
    series.labels.template.propertyFields.fill = "color";
    series.labels.template.text = "{category}: {value.value}";
    series.ticks.template.stroke = "colors";

    series.slices.template.events.on("over", (event) => {
      const slice = event.target;
      const tick = slice.dataItem?.components?.ticks;
      if (tick) {
        tick.fill = slice.fill;
      }
    });

    return () => {
      chart.dispose();
    };
  }, [data, displayLegend, label]);

  return (
    <Grid
      bgcolor={bgColor}
      sx={{
        borderRadius: "8px",
        border: `1px solid ${borderColor}`,
        boxShadow: `7px 13px 24px 1px ${boxShadowColor}`,
      }}
      p={2}
    >
      <Grid>
        <Typography variant="h5" pl={3} pb={2} color={textColor}>
          {label}
        </Typography>
        <Divider />
      </Grid>
      {data?.length && data?.some((item) => item.value > 0) ? (
        <>
 
          <Grid pt={2} pb={2} width="100%" height="370px">
          <div
            id={`chartdiv-${label}`}
            style={{ width: "100%", height: "100%" }}
          />
          </Grid>
        </>
      ) : (
        <Grid
          alignContent={"center"}
          textAlign={"center"}
          xs={12}
          width="100%"
          height="380px"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
          gap={0.5}
        >
          <ErrorIcon color={textColor} />
          <Typography color={textColor}>No Vulnerability Found</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AssetDetailPiechart;




// import ErrorIcon from "@mui/icons-material/Error";
// import { Divider, Grid, Typography, useTheme } from "@mui/material";
// import moment from "moment";
// import {
//   Cell,
//   Legend,
//   Pie,
//   PieChart,
//   ResponsiveContainer,
//   Tooltip,
// } from "recharts";

// const AssetDetailPiechart = ({ label, data, displayLegend, lastPublished }) => {
//   const theme = useTheme();
//   const bgColor =
//     theme.palette.mode === "dark" ? theme.palette.background.paper : "#ffffff";
//   const borderColor = theme.palette.mode === "dark" ? "#455f95" : "#EEEEEE";
//   const boxShadowColor =
//     theme.palette.mode === "dark"
//       ? "rgba(255, 255, 255, 0.1)"
//       : "rgba(0, 0, 0, 0.1)";
//   const textColor = theme.palette.text.primary;
//   const CustomLegend = () => {
//     return (
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         {data?.map((value) => (
//           <div key={value?.name} style={{ marginRight: 10 }}>
//             <span style={{ color: value?.color, fontSize: "12px" }}>■</span>
//             <span style={{ color: textColor }}>{value?.name}</span>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <Grid
//       bgcolor={bgColor}
//       sx={{
//         borderRadius: "8px",
//         border: `1px solid ${borderColor}`,
//         boxShadow: `7px 13px 24px 1px ${boxShadowColor}`,
//       }}
//       p={2}
//     >
//       <Grid>
//         <Typography variant="h5" pl={3} pb={2} color={textColor}>
//           {label}
//         </Typography>
//         <Divider />
//       </Grid>
//       {data?.length && data?.some((item) => item.value > 0) ? (
//         <>
//           <Grid xs={12} textAlign={"end"}>
//             {lastPublished ? (
//               <Typography
//                 variant="h6"
//                 float="end"
//                 fontSize={{ xs: 7, md: 9 }}
//                 color="grey"
//               >
//                 Last Publish :{moment(lastPublished).format("LLL")}
//               </Typography>
//             ) : (
//               ""
//             )}
//           </Grid>
//           <Grid pt={2} pb={2} width="100%" height="370px">
//             <ResponsiveContainer>
//               <PieChart>
//                 <Pie
//                   data={data}
//                   dataKey="value"
//                   outerRadius={120}
//                   fill="#8884d8"
//                   label
//                 >
//                   {data?.map((value, index) => (
//                     <Cell key={`cell-${index}`} fill={value?.color} />
//                   ))}
//                 </Pie>
//                 <Tooltip />
//                 {displayLegend && <Legend />}
//               </PieChart>
//             </ResponsiveContainer>
//           </Grid>
//         </>
//       ) : (
//         <Grid
//           alignContent={"center"}
//           textAlign={"center"}
//           xs={12}
//           width="100%"
//           height="380px"
//           display={"flex"}
//           justifyContent={"center"}
//           alignItems={"center"}
//           alignSelf={"center"}
//           gap={0.5}
//         >
//           <ErrorIcon color={textColor} />
//           <Typography color={textColor}>No Vulnerability Found</Typography>
//         </Grid>
//       )}
//     </Grid>
//   );
// };

// export default AssetDetailPiechart;
