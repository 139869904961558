import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch } from "store";
import {
  addVulnerability,
  getComplianceStatusList,
  getEaseOfExploitation,
  getOWASPCategories,
  getOWASPCategoriesByScanId,
  getOWASPCategoriesSuccess,
  getRiskRating,
  getVulnerabilitiesMaster,
  getVulnerbilitySans25,
} from "store/slices/vulnerabilityReducer";
import DialogBox from "ui-component/dialogbox";
import FormAutoComplete from "ui-component/extended/Form/FormControlAutoComplete";
import FormControlSelect from "ui-component/extended/Form/FormControlSelect";
import * as yup from "yup";
import { SCAN_SUB_TYPE, vulnerabilityCategory } from "../../constant";
import AddEvidenceOnVulnerabilityPage from "../addEvidanceOnVulnerabilityPage";

const AddVulnerabilityDetails = (props) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const [references, setReferences] = useState();
  const [impact, setImpact] = useState();
  const [affectedUrl, setAffectedUrl] = useState();
  const [solution, setSolution] = useState();
  const [description, setDescription] = useState();
  const [pluginOutput, setPluginOutput] = useState();
  const [isValid, setIsValid] = useState(false);
  const [vulnerabilityName, setVulnerabilityName] = useState("");
  const [port, setPort] = useState("");
  const [protocol, setProtocol] = useState("");
  const [proofOfConcepts, setProofOfConcepts] = useState("");
  const [CVEId, setCVEId] = useState("");
  const [selectedVmId, setSelectedVmId] = useState();
  const [synopsis, setSynopsis] = useState();
  const [seeAlso, setSeeAlso] = useState();
  const [identifiedOn, SetIdentifiedOn] = useState();
  const [policyValue, setPolicyValue] = useState();
  const [actualValue, setActualValue] = useState();
  const [fileName, setFileName] = useState();
  const [lineNo, setLineNo] = useState();
  const [selectedRiskRating, setSelectedRiskRating] = useState("");
  const [selectedOWASP, setSelectedOWASP] = useState("");
  const [selectedSans25, setSelectedSans25] = useState("");
  const [selectedEaseOfExploitation, setSelectedEaseOfExploitation] = useState("");
  const [identifiedOnDate, setIdentifiedOnDate] = useState();
  const [selectedCompolianceStatus, setComplianceStatus] = useState();
  const { setDataloading } = useSelector((state) => state?.userState);
  const displayFieldData = sessionStorage.getItem("displayVulnerabilityField");
  const displayVulnerabilityField = JSON.parse(displayFieldData);
  const clientOrgId = sessionStorage.getItem("clientOrgId");

  // console.log(displayVulnerabilityField, displayFieldData, "lols");

  const { scanDetails } = useSelector((state) => state?.scansState);
  const handleEffectIpValidation = [
    SCAN_SUB_TYPE.mobile,
    SCAN_SUB_TYPE.thick_client,
  ].includes(scanDetails?.scanSubTypeName);
  const [open, setOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [evidenceName, setEvidenceName] = useState();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitEvidence, setSubmitEvidence] = useState(false);
  const [evidenceList, setEvidenceList] = useState([]);
  const [alignment, setAlignment] = useState(
    vulnerabilityCategory.defined_value
  );

  const handleVulnerabilityModeChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    reset();
    setSelectedRiskRating("");
    setSelectedEaseOfExploitation("");
    setSelectedOWASP("");
    setSelectedSans25("");
    setDescription("");
    setImpact("");
    setReferences("");
    setSolution("");

    setValue("riskRating", "");
    setValue("easeOfExploitation", "");
    setValue("port", "");
    setValue("owaspCategory", "");
    setValue("description", "");
    setValue("solution", "");
    setValue("protocol", "");
    setValue("svReference", "");
    setValue("impact", "");
    setValue("affectedUrl", "");
    setValue("vulnerabilityName", "");
    setValue("cve_cwe_id", "");
    setValue("sourceFile", "");
    setValue("proofOfConcept", "");
    setValue("lineNo", "");
    setValue("synopsis", "");
    setValue("policyValue", "");
    setValue("actualValue", "");
    setValue("pluginOutput", "");
    // setValue("identifiedOnDate", props?.vulnerabilityDetails?.identifiedOnDate);
    setValue("protocol", "");
    setValue("easeOfExploitation", "");
    setValue("SANS25Category", "");
    setValue("affectedUrl", "");
    setValue("solution", "");
    setValue("description", "");
    setValue("pluginOutput", "");
    setValue("impact", "");
    setValue("svReference", "");
    setValue("affectedUrl", "");
  };

  const selectedToggleColor = {
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    padding: "2px",
    paddingX: "10px",
  };
  const handleCloseDialog = () => {
    setOpen(false);
    setFile("");
    setEvidenceName("");
    if (submitEvidence) {
      setSubmitEvidence(!submitEvidence);
      setEvidenceList([]);
      navigate(-1);
    }
    // setOpenImage(false);
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  const validationSchema = yup.object().shape({
    vulnerabilityName: displayVulnerabilityField?.vulnerabilityName
      ? yup.string().required("Vulnerability Name is required")
      : "",
    vulnerabilityId: displayVulnerabilityField?.vulnerabilityId
      ? yup.string().required("Vulnerability is required")
      : "",
    riskRating: displayVulnerabilityField?.riskRating
      ? yup.string().required("Risk Rating is required")
      : "",
    easeOfExploitation: displayVulnerabilityField?.easeOfExploitation
      ? yup.string().required("Ease Of Exploitation is required")
      : "",
    complianceStatus: displayVulnerabilityField?.complianceStatus
      ? yup.string().required("Compliance Status is required")
      : "",
    // port: displayVulnerabilityField?.port
    //   ? yup
    //       .string()
    //       .matches(
    //         /^(0|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
    //         "Enter valid Port"
    //       )
    //   : "",
    // protocol: displayVulnerabilityField?.protocol
    //   ? yup.string().required("Protocol is required")
    //   : "",
    // cve_cwe_id: displayVulnerabilityField?.cve_cwe_id
    //   ? yup
    //       .string()
    //       .matches(/\b(CWE|CVE)-\d+\b/, "Enter valid CVE/ CWE ID")
    //       .required("CVE ID / CWE ID is required")
    //   : "",
    // svReference: yup.string().required('Reference is required'),
    impact: displayVulnerabilityField?.impact
      ? yup.string().required("Impact is required")
      : "",
    affectedUrl:
      displayVulnerabilityField?.affectedURL && !handleEffectIpValidation
        ? yup
            .string()
            // .matches(
            //   /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|((https?|ftp):\/\/(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?))$/,
            //   "Enter valid IP or URL"
            // )
            .required("Affected Asset (IP/URL) is required")
        : "",
    description: displayVulnerabilityField?.description
      ? yup.string().required("Description is required")
      : "",
    solution: displayVulnerabilityField?.solution
      ? yup.string().required("Solution is required")
      : "",
    svReference: displayVulnerabilityField?.reference
      ? yup.string().required("Reference is required")
      : "",
    pluginOutput: displayVulnerabilityField?.pluginOutput ? yup.string() : "",
    synopsis: displayVulnerabilityField?.synopsis ? yup.string() : "",
    owaspCategory: displayVulnerabilityField?.owaspCategory
      ? yup.string().required("OWASP Category is required")
      : "",
    // SANS25Category: displayVulnerabilityField?.SANS25Category
    // ? yup.string().required("SANS25 Category is required")
    // : "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    setValue("riskRating", props?.vulnerabilityDetails?.riskRating);
    setValue(
      "easeOfExploitation",
      props?.vulnerabilityDetails?.easeOfExploitation
    );
    setValue("port", props?.vulnerabilityDetails?.port);
    setValue("owaspCategory", props?.vulnerabilityDetails?.owaspcategory);
    setValue("SANS25Category", props?.vulnerabilityDetails?.SANS25Category);
    setValue("description", props?.vulnerabilityDetails?.description);
    setValue("solution", props?.vulnerabilityDetails?.solution);
    setValue("protocol", props?.vulnerabilityDetails?.protocol);
    setValue("svReference", props?.vulnerabilityDetails?.svReference);
    setValue("impact", props?.vulnerabilityDetails?.impact);
    setValue("affectedUrl", props?.vulnerabilityDetails?.affectedUrl);
    setValue(
      "vulnerabilityName",
      props?.vulnerabilityDetails?.vulnerabilityName
    );
    setValue("cve_cwe_id", props?.vulnerabilityDetails?.cve_cwe_id);
    setValue("sourceFile", props?.vulnerabilityDetails?.sourceFile);
    setValue("proofOfConcept", props?.vulnerabilityDetails?.proofOfConcept);
    setValue("lineNo", props?.vulnerabilityDetails?.lineNo);
    setValue("synopsis", props?.vulnerabilityDetails?.synopsis);
    setValue("policyValue", props?.vulnerabilityDetails?.policyValue);
    setValue("actualValue", props?.vulnerabilityDetails?.actualValue);
    setValue("pluginOutput", props?.vulnerabilityDetails?.pluginOutput);
    // setValue("identifiedOnDate", props?.vulnerabilityDetails?.identifiedOnDate);
    setReferences(props?.vulnerabilityDetails?.svReference);
    setVulnerabilityName(props?.vulnerabilityDetails?.vulnerabilityName);
    setImpact(props?.vulnerabilityDetails?.impact);
    setAffectedUrl(props?.vulnerabilityDetails?.affectedUrl);
    setSolution(props?.vulnerabilityDetails?.solution);
    setDescription(props?.vulnerabilityDetails?.description);
    setProtocol(props?.vulnerabilityDetails?.protocol);
    setPort(props?.vulnerabilityDetails?.port);
    setCVEId(props?.vulnerabilityDetails?.cve_cwe_id);
    setProofOfConcepts(props?.vulnerabilityDetails?.proofOfConcept);
    setSynopsis(props?.vulnerabilityDetails?.synopsis);
    setSeeAlso(props?.vulnerabilityDetails?.seeAlso);
    setIdentifiedOnDate(props?.vulnerabilityDetails?.identifiedOn);
    setPolicyValue(props?.vulnerabilityDetails?.policyValue);
    setActualValue(props?.vulnerabilityDetails?.actualValue);
    setFileName(props?.vulnerabilityDetails?.sourceFile || null);
    setLineNo(props?.vulnerabilityDetails?.lineNo);
    setPluginOutput(props?.vulnerabilityDetails?.pluginOutput);
    setSelectedRiskRating(props?.vulnerabilityDetails?.riskRating);
    setSelectedEaseOfExploitation(
      props?.vulnerabilityDetails?.easeOfExploitation
    );
    setComplianceStatus(props?.vulnerabilityDetails?.complianceStatus);
    setSelectedOWASP(props?.vulnerabilityDetails?.owaspCategory);
    setSelectedSans25(props?.vulnerabilityDetails?.SANS25Category);
  }, []);

  // const onClickNext = (data) => {
  //   if (!props?.vulnerabilityDetails?.vulnerabilityId && !vulnerabilityName) {
  //     setIsValid(true);
  //   } else {
  //     if (params?.assetId) {
  //       const payload = {
  //         ...data,
  //         ...props?.vulnerabilityDetails,
  //         solution: data?.solution,
  //         description: data?.description,
  //         pluginOutput: data?.plugInOutput,
  //         impact: data?.impact,
  //         svReference: data?.svReference,
  //         affectedUrl: data?.affectedUrl,
  //         assetIdList: [params?.assetId],
  //         scanId: params?.scanId,
  //         port: data?.port,
  //         protocol: data?.protocol,
  //         identifiedOn: data?.identifiedOnDate,
  //         proofOfConcept: data?.proofOfConcepts,
  //         vmId: selectedVmId,
  //         cve_cwe_id: data?.cve_cwe_id,
  //         clientOrgId,
  //         riskRating: data?.riskRating,
  //         easeOfExploitation: data?.easeOfExploitation,
  //         owaspCategory: data?.owaspCategory,
  //       };
  //       dispatch(addVulnerability(payload))
  //         .then((res) => {
  //           if (res.status === 200) {
  //             navigate(-1);
  //           }
  //         })
  //         .catch(() => {});
  //     } else {
  //       let passData = {
  //         ...data,
  //         ...props?.vulnerabilityDetails,
  //         riskRating: data?.riskRating,
  //         easeOfExploitation: data?.easeOfExploitation,
  //         owaspCategory: data?.owaspCategory,
  //         solution: data?.solution,
  //         description: data?.description,
  //         pluginOutput: data?.pluginOutput,
  //         impact: data?.impact,
  //         svReference: data.svReference,
  //         identifiedOn: data?.identifiedOnDate,
  //         affectedUrl: data?.affectedUrl,
  //         vulnerabilityName: vulnerabilityName ? vulnerabilityName : null,
  //         port: data?.port,
  //         protocol: data?.protocol,
  //         proofOfConcept: data?.proofOfConcepts,
  //         cve_cwe_id: data?.CVEId,
  //         clientOrgId,
  //         vulnerabilityId:
  //           selectedVmId || props.vulnerabilityDetails?.vulnerabilityId,
  //       };
  //       props?.setVulnerabilityDetails(passData);
  //       props?.handleChangeTab();
  //     }
  //   }
  // };

  const onClickNext = (data, event) => {
    event.preventDefault();
    if (params?.assetId || displayVulnerabilityField?.singleAsset) {
      // Logic for handling vulnerability details when assetId is present
      const payload = {
        ...data,
        ...props?.vulnerabilityDetails,
        solution: data?.solution,
        description: data?.description,
        pluginOutput: data?.plugInOutput ?? data?.pluginOutput,
        impact: data?.impact,
        svReference: data?.svReference,
        affectedUrl: data?.affectedUrl,
        assetIdList: params?.assetId ? [params?.assetId] : null,
        scanId: params?.scanId,
        port: data?.port,
        protocol: data?.protocol,
        identifiedOn: data?.identifiedOnDate,
        proofOfConcept: data?.proofOfConcept,
        vmId: selectedVmId,
        cve_cwe_id: data?.cve_cwe_id,
        clientOrgId,
        lineNo: data?.lineNo,
        riskRating: data?.riskRating,
        identifiedOn: identifiedOnDate,
        easeOfExploitation: data?.easeOfExploitation,
        owaspCategory: data?.owaspCategory,
        // SANS25Category: data?.SANS25Category
      };
      dispatch(addVulnerability(payload))
        .then((res) => {
          if (res.status === 200) {
            if (evidenceList?.length > 0) {
              setOpen(true);
              setSubmitEvidence(true);
            } else {
              navigate(-1);
            }
          }
        })
        .catch(() => {});
    } else {
      // Logic for handling vulnerability details when assetId is not present
      let passData = {
        ...data,
        ...props?.vulnerabilityDetails,
        riskRating: data?.riskRating,
        easeOfExploitation: data?.easeOfExploitation,
        owaspCategory: data?.owaspCategory,
        SANS25Category: data?.SANS25Category,
        solution: data?.solution,
        description: data?.description,
        pluginOutput: data?.pluginOutput,
        impact: data?.impact,
        svReference: data.svReference,
        identifiedOn: identifiedOnDate,
        affectedUrl: data?.affectedUrl,
        sourceFile: data?.sourceFile ? data?.sourceFile : null,
        lineNo: data?.lineNo,
        // vulnerabilityName: vulnerabilityName ? vulnerabilityName : null,
        vulnerabilityName: data?.vulnerabilityName
          ? data?.vulnerabilityName
          : null,
        port: data?.port,
        // synopsis : data?.synopsis,
        protocol: data?.protocol,
        proofOfConcept: data?.proofOfConcept,
        cve_cwe_id: data?.cve_cwe_id,
        clientOrgId,
        vulnerabilityId:
          selectedVmId || props.vulnerabilityDetails?.vulnerabilityId,
        complianceStatus: data?.complianceStatus,
      };
      props?.setVulnerabilityDetails(passData);
      props?.handleChangeTab();
    }
  };

  const {
    allVulnerabilities,
    complianceStatusList,
    easeOfExploitation,
    OWASPCategories,
    riskRating,
    VulnerabiltySans25Success,
    addVulnerabilityRepose,
  } = useSelector((state) => state?.vulnerabilityState);

  // console.log(OWASPCategories, "OWASPCategories")

  useEffect(() => {
    dispatch(getVulnerabilitiesMaster(params?.scanId, clientOrgId));
    dispatch(getRiskRating());
    dispatch(getComplianceStatusList());
    dispatch(getEaseOfExploitation());
    if (!props?.vulnerabilityDetails?.vulnerabilityId) {
      dispatch(getOWASPCategoriesSuccess([]));
    }
  }, []);

  const handleSelectedValId = (e, selectedValue) => {
    if (selectedValue?.vulnerabilityId && allVulnerabilities?.length) {
      let selectedObj = allVulnerabilities.find(
        (item) => item?.vulnerabilityId === selectedValue?.vulnerabilityId
      );
      let obj = {
        ...props?.vulnerabilityDetails,
        ...selectedObj,
      };
      setSelectedVmId(obj?.vulnerabilityId);
      props?.setVulnerabilityDetails(obj);
      if (selectedValue?.serviceType) {
        dispatch(getOWASPCategories(selectedValue?.serviceType));
      }
      if (selectedValue?.serviceType) {
        dispatch(getVulnerbilitySans25(selectedValue?.serviceType));
      }
      setValue("protocol", obj?.protocol);
      setValue("riskRating", obj?.riskRating);
      setValue("easeOfExploitation", obj?.easeOfExploitation);
      setValue("owaspCategory", obj?.owaspcategory);
      setValue("affectedUrl", obj?.affectedUrl);
      setValue("solution", obj?.solution);
      setValue("description", obj?.vulnerabilityDesc);
      setValue("pluginOutput", obj?.pluginOutput);
      setValue("impact", obj?.impact);
      setValue("svReference", obj?.vulnerabilityRef);
      setValue("affectedUrl", obj?.affectedUrl);
      setSelectedRiskRating(obj?.riskRating);
      setSelectedEaseOfExploitation(obj?.easeOfExploitation);
      setComplianceStatus(obj?.complianceStatus);
      setSolution(obj?.solution);
      setDescription(obj?.vulnerabilityDesc);
      setPluginOutput(obj?.pluginOutput);
      setImpact(obj?.impact);
      setReferences(obj?.vulnerabilityRef);
      setSelectedOWASP(obj?.owaspcategory);
      setSelectedSans25(obj?.SANS25Category);
      setAffectedUrl(obj?.affectedUrl);
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "vulnerabilityName":
        const inputValue = value;
        if (inputValue?.length <= 100) {
          setVulnerabilityName(inputValue);
        }
        break;
      case "port":
        setPort(value);
        break;
      case "protocol":
        setProtocol(value);
        break;
      case "proofOfConcept":
        setProofOfConcepts(value);
        break;
      case "cve_cwe_id":
        setCVEId(value);
        break;
      case "synopsis":
        setSynopsis(value);
        break;
      case "seeAlso":
        setSeeAlso(value);
        break;
      case "identifiedOn":
        SetIdentifiedOn(value);
        break;
      case "policyValue":
        setPolicyValue(value);
        break;
      case "actualValue":
        setActualValue(value);
        break;
      case "sourceFile":
        setFileName(value);
        break;
      case "lineNo":
        setLineNo(value);
        break;
      case "riskRating":
        setSelectedRiskRating(value);
        // trigger("riskRating");
        break;
      case "easeOfExploitation":
        setSelectedEaseOfExploitation(value);
        // trigger("easeOfExploitation");
        break;
      case "owaspCategory":
        setSelectedOWASP(value);
        // trigger("owaspCategory");
        break;
      case "SANS25Category":
        setSelectedSans25(value);
        // trigger("owaspCategory");
        break;
      case "complianceStatus":
        setComplianceStatus(value);
        // trigger("owaspCategory");
        break;
      default:
        break;
    }
  };

  // const uploadImage = async () => {
  //   setBtnLoading(true)
  //   const fileInput = document.getElementById("fileInput");
  //   const selectedFile = fileInput?.files[0];
  //   const formData = new FormData();
  //   const payload = {
  //     vulnerabilityId: valId,
  //     clientOrgId: clientOrgId,
  //   };
  //   formData.append("vulId", valId);
  //   formData.append("title", evidenceName);
  //   formData.append("clientOrgId", clientOrgId);
  //   formData.append("file", selectedFile);
  //   dispatch(uploadNewEvidence(formData))
  //     .then((res) => {
  //       if (res.status === 200) {
  //         dispatch(getEvidenceList(payload));
  //         handleClose();
  //         setBtnLoading(false)
  //       }
  //     })
  //     .catch(() => {
  //       setBtnLoading(false)
  //     });
  // };

  // useEffect(() => {
  //   const fileInput = document.getElementById("fileInput");
  //   const selectedFile = fileInput?.files[0];
  //   if (addVulnerabilityRepose?.scanVulnerabilityDTO?.scanVulnerabilityId && evidenceName && selectedFile) {

  //     setBtnLoading(true)
  //     const formData = new FormData();
  //     const payload = {
  //       vulnerabilityId: addVulnerabilityRepose?.scanVulnerabilityDTO?.scanVulnerabilityId,
  //       clientOrgId: clientOrgId,
  //     };
  //     formData.append("vulId", addVulnerabilityRepose?.scanVulnerabilityDTO?.scanVulnerabilityId);
  //     formData.append("title", evidenceName);
  //     formData.append("clientOrgId", clientOrgId);
  //     formData.append("file", selectedFile);
  //     dispatch(uploadNewEvidence(formData))
  //       .then((res) => {
  //         if (res.status === 200) {

  //           // dispatch(getEvidenceList(payload));
  //           handleCloseDialog();
  //           setBtnLoading(false)
  //           setFile('')
  //           setEvidenceName('')
  //           navigate(-1);
  //         }
  //         // }
  //       })
  //       .catch(() => {
  //         setBtnLoading(false)
  //       });
  //   }
  // }, [addVulnerabilityRepose?.scanVulnerabilityDTO])

  useEffect(() => {
    let data = {};
    watch((value, { name, type }) => {
      data = value;
      if (selectedVmId) {
        let obj = {
          ...props?.vulnerabilityDetails,
          ...value,
          vulnerabilityId: selectedVmId,
        };
        props?.setVulnerabilityDetails(obj);
        return;
      }
    });
  }, [props]);
  useEffect(() => {
    if (alignment === vulnerabilityCategory.custom_value) {
      dispatch(getOWASPCategoriesByScanId(params?.scanId, clientOrgId));
    }
    reset();
  }, [alignment]);
  return (
    <>
      {!displayVulnerabilityField?.vulnerabilityName && (
        <Grid m={0.5} display="flex" justifyContent="end">
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleVulnerabilityModeChange}
            aria-label="Platform"
            size="small"
            sx={{
              padding: "2px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <ToggleButton
              value={vulnerabilityCategory?.defined_value}
              sx={selectedToggleColor}
            >
              {vulnerabilityCategory?.defined_label}
            </ToggleButton>
            <ToggleButton
              value={vulnerabilityCategory?.custom_value}
              sx={selectedToggleColor}
            >
              {vulnerabilityCategory?.custom_label}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      <form onSubmit={handleSubmit(onClickNext)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg={12}>
            <InputLabel>Vulnerability:</InputLabel>
            {displayVulnerabilityField?.vulnerabilityName ||
            alignment === vulnerabilityCategory.custom_value ? (
              <TextField
                fullWidth
                name="vulnerabilityName"
                placeholder="Vulnerability"
                value={vulnerabilityName}
                {...register("vulnerabilityName")}
                error={Boolean(errors.vulnerabilityName)}
                helperText={
                  errors.vulnerabilityName
                    ? errors.vulnerabilityName.message
                    : ""
                }
                // onChange={handleChange}
                autoComplete="off"
              />
            ) : (
              <FormAutoComplete
                options={allVulnerabilities}
                defaultValue={() => {
                  return allVulnerabilities.find(
                    (item) =>
                      item?.vulnerabilityId ===
                      props?.vulnerabilityDetails?.vulnerabilityId
                  );
                }}
                optionLabel={"vulnerabilityName"}
                {...register("vulnerabilityId")}
                error={Boolean(errors.vulnerabilityId)}
                helperText={
                  errors.vulnerabilityId ? errors.vulnerabilityId.message : ""
                }
                name="VulnerabilityId"
                placeholder={"Vulneraility"}
                onChange={handleSelectedValId}
                clearIcon={false}
              />
            )}
          </Grid>
          <>
            {displayVulnerabilityField?.riskRating && (
              <Grid item xs={6} lg={6}>
                <InputLabel>Risk Rating:</InputLabel>
                <FormControlSelect
                  itemsList={riskRating}
                  value={selectedRiskRating}
                  optionLabel={"riskRatingDesc"}
                  optionValue={"riskRatingCode"}
                  {...register("riskRating")}
                  error={Boolean(errors.riskRating)}
                  helperText={
                    errors.riskRating ? errors.riskRating.message : ""
                  }
                  name="riskRating"
                  onChange={handleChange}
                />
              </Grid>
            )}
          </>
          {displayVulnerabilityField?.easeOfExploitation && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Ease of Exploitation:</InputLabel>
              <FormControlSelect
                itemsList={easeOfExploitation}
                optionLabel={"easeOfExploitationDesc"}
                optionValue={"easeOfExploitationCode"}
                {...register("easeOfExploitation")}
                value={selectedEaseOfExploitation}
                error={Boolean(errors.easeOfExploitation)}
                helperText={
                  errors.easeOfExploitation
                    ? errors.easeOfExploitation.message
                    : ""
                }
                onChange={handleChange}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.port && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Port:</InputLabel>
              <TextField
                fullWidth
                defaultValue={port}
                {...register("port")}
                placeholder="Port"
                autoComplete="off"
                // error={Boolean(errors.port)}
                // helperText={errors.port ? errors.port.message : ""}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.protocol && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Protocol:</InputLabel>
              <TextField
                fullWidth
                defaultValue={protocol}
                {...register("protocol")}
                // error={Boolean(errors.protocol)}
                // helperText={errors.protocol ? errors.protocol.message : ""}
                autoComplete="off"
                placeholder="Protocol"
              />
            </Grid>
          )}
          {displayVulnerabilityField?.owaspCategory && (
            <Grid item xs={6}>
              <InputLabel>OWASP Category:</InputLabel>
              <FormControlSelect
                disabled={!OWASPCategories?.length}
                itemsList={OWASPCategories || []}
                optionLabel={"owaspcategoryDesc"}
                optionValue={"owaspcategoryId"}
                {...register("owaspCategory")}
                value={selectedOWASP}
                onChange={handleChange}
                name="owaspCategory"
                error={Boolean(errors.owaspCategory)}
                helperText={
                  errors.owaspCategory ? errors.owaspCategory.message : ""
                }
              />
            </Grid>
          )}

          {/* {displayVulnerabilityField?.SANS25Category && ( */}
          {displayVulnerabilityField?.SANS25Category && (
            <Grid item xs={6}>
              <InputLabel>SANS Top 25 Category:</InputLabel>
              <FormControlSelect
                disabled={!VulnerabiltySans25Success?.length}
                itemsList={VulnerabiltySans25Success || []}
                optionLabel={"SANS25Desc"}
                optionValue={"SANS25Category"}
                {...register("SANS25Category")}
                value={selectedSans25}
                onChange={handleChange}
                name="SANS25Category"
                error={Boolean(errors.SANS25Category)}
                helperText={
                  errors.SANS25Category ? errors.SANS25Category.message : ""
                }
              />
            </Grid>
          )}

          {displayVulnerabilityField?.affectedURL && (
            <Grid item xs={12}>
              <InputLabel>Affected Asset (IP/URL) :</InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                defaultValue={affectedUrl}
                name="affectedUrl"
                {...register("affectedUrl")}
                error={Boolean(errors.affectedUrl)}
                helperText={
                  errors.affectedUrl ? errors.affectedUrl.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.description && (
            <Grid item xs={12}>
              <InputLabel>Description:</InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                name="description"
                defaultValue={description}
                {...register("description")}
                error={Boolean(errors.description)}
                helperText={
                  errors.description ? errors.description.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.impact && (
            <Grid item xs={12}>
              <InputLabel>Impact:</InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                name="impact"
                defaultValue={impact}
                {...register("impact")}
                error={Boolean(errors.impact)}
                helperText={errors.impact ? errors.impact.message : ""}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.plugInOutput && (
            <Grid item xs={12}>
              <InputLabel>Plugin Output:</InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                name="pluginOutput"
                defaultValue={pluginOutput}
                {...register("pluginOutput")}
                error={Boolean(errors.pluginOutput)}
                helperText={
                  errors.pluginOutput ? errors.pluginOutput.message : ""
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputLabel>Solution:</InputLabel>
            <TextField
              multiline
              rows={3}
              fullWidth
              name="solution"
              defaultValue={solution}
              {...register("solution")}
              error={Boolean(errors.solution)}
              helperText={errors.solution ? errors.solution.message : ""}
            />
          </Grid>
          {displayVulnerabilityField?.reference && (
            <Grid item xs={12}>
              <InputLabel>
                Reference (Please Use Coma “ , “ for adding Multiple
                References):
              </InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                defaultValue={references}
                name="svReference"
                {...register("svReference")}
                error={Boolean(errors.svReference)}
                helperText={
                  errors.svReference ? errors.svReference.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.complianceStatus && (
            <Grid item xs={6} lg={4}>
              <InputLabel>Compliance Status:</InputLabel>
              <FormControlSelect
                itemsList={complianceStatusList}
                optionLabel={"desc"}
                optionValue={"code"}
                {...register("complianceStatus")}
                name="complianceStatus"
                value={selectedCompolianceStatus}
                error={Boolean(errors.complianceStatus)}
                helperText={
                  errors.complianceStatus ? errors.complianceStatus.message : ""
                }
                onChange={handleChange}
              />
            </Grid>
          )}

          {displayVulnerabilityField?.policyValue && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Policy Value:</InputLabel>
              <TextField
                fullWidth
                {...register("policyValue")}
                defaultValue={policyValue}
                error={Boolean(errors.policyValue)}
                autoComplete="off"
                placeholder="Policy Value"
                helperText={
                  errors.policyValue ? errors.policyValue.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.actualValue && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Actual Value:</InputLabel>
              <TextField
                fullWidth
                {...register("actualValue")}
                defaultValue={actualValue}
                error={Boolean(errors.actualValue)}
                autoComplete="off"
                placeholder="Actual Value"
                helperText={
                  errors.actualValue ? errors.actualValue.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.sourceFile && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Source File:</InputLabel>
              <TextField
                fullWidth
                defaultValue={fileName}
                {...register("sourceFile")}
                error={Boolean(errors.sourceFile)}
                autoComplete="off"
                placeholder="Source File"
                helperText={errors.sourceFile ? errors.sourceFile.message : ""}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.lineNo && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Line Number:</InputLabel>
              <TextField
                fullWidth
                {...register("lineNo")}
                defaultValue={lineNo}
                error={Boolean(errors.lineNo)}
                autoComplete="off"
                placeholder="Line Number"
                helperText={errors.lineNo ? errors.lineNo.message : ""}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.proofOfConcept && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Proof Of Concepts:</InputLabel>
              <TextField
                fullWidth
                defaultValue={proofOfConcepts}
                {...register("proofOfConcept")}
                error={Boolean(errors.proofOfConcept)}
                autoComplete="off"
                placeholder="Proof Of Concepts"
                helperText={
                  errors.proofOfConcept ? errors.proofOfConcept.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.identifiedOn && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Identified On :</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{
                      width: "100%",
                    }}
                    format="YYYY-MM-DD"
                    defaultValue={identifiedOnDate}
                    onChange={(date) => {
                      setIdentifiedOnDate(dayjs(date?.$d).format("YYYY-MM-DD"));
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {/* <InputLabel>Identified On :</InputLabel>
                <TextField
                  fullWidth
                  {...register('identifiedOn')}
                  value={identifiedOn}
                  error={Boolean(errors.identifiedOn)}
                  autoComplete="off"
                  placeholder="Identified On"
                  helperText={
                    errors.identifiedOn ? errors.identifiedOn.message : ''
                  }
                /> */}
            </Grid>
          )}

          {displayVulnerabilityField?.cve && (
            <Grid item xs={6} lg={6}>
              <InputLabel>CVE ID / CWE ID:</InputLabel>
              <TextField
                fullWidth
                defaultValue={CVEId}
                {...register("cve_cwe_id")}
                // name="cve_cwe_id"
                // onChange={handleChange}
                error={Boolean(errors.cve_cwe_id)}
                placeholder="CVE ID / CWE ID"
                helperText={errors.cve_cwe_id ? errors.cve_cwe_id.message : ""}
              />
            </Grid>
          )}

          {displayVulnerabilityField?.synopsis && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Synopsis:</InputLabel>
              <TextField
                fullWidth
                {...register("synopsis")}
                defaultValue={synopsis}
                error={Boolean(errors.synopsis)}
                helperText={errors.synopsis ? errors.synopsis.message : ""}
                placeholder="Synopsis"
                autoComplete="off"
              />
            </Grid>
          )}
          {/* {displayVulnerabilityField?.singleAsset ? (
            <Grid item xs={12} lg={12}>
              <Button
                fullWidth
                component="label"
                role={undefined}
                // variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onClick={() => setOpen(true)}
              >
                Add Evidence
              </Button>
            </Grid>
          ) : (
            ""
          )} */}

          <DialogBox
            maxWidth="md"
            modelTitle={"Add Evidence"}
            open={open}
            handleClose={handleCloseDialog}
            dialogContent={
              <AddEvidenceOnVulnerabilityPage
                handleClose={handleCloseDialog}
                valId={params?.valId}
                clientOrgId={clientOrgId}
                setBtnLoading={setBtnLoading}
                btnLoading={btnLoading}
                file={file}
                setFile={setFile}
                evidenceName={evidenceName}
                setEvidenceName={setEvidenceName}
                handleFileChange={handleFileChange}
                submitEvidence={submitEvidence}
                setEvidenceList={setEvidenceList}
                evidenceList={evidenceList}
              />
            }
          />

          {/* {displayVulnerabilityField?.seeAlso && (
            <Grid item xs={6} lg={6}>
              <InputLabel>See Also:</InputLabel>
              <TextField
                fullWidth
                {...register('seeAlso')}
                value={seeAlso}
                error={Boolean(errors.seeAlso)}
                helperText={errors.seeAlso ? errors.seeAlso.message : ''}
                placeholder="See Also"
                autoComplete="off"
              />
            </Grid>
          )} */}

          {/* {displayVulnerabilityField?.port && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Port:</InputLabel>
              <TextField
                fullWidth
                defaultValue={port}
                {...register("port")}
                placeholder="Port"
                autoComplete="off"
                error={Boolean(errors.port)}
                helperText={errors.port ? errors.port.message : ""}
              />
            </Grid>
          )} */}
        </Grid>
        <Stack
          mt={3}
          justifyContent={"flex-end"}
          width={"100%"}
          direction={"row"}
          spacing={2}
        >
          <Button size="large" variant="outlined" onClick={handleGoBack}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              boxShadow: theme.customShadows.secondary,
              ":hover": {
                boxShadow: "none",
              },
            }}
            loading={setDataloading}
          >
            {params?.assetId || displayVulnerabilityField?.singleAsset
              ? "Save"
              : "Next"}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};

export default AddVulnerabilityDetails;
