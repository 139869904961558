import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "store";

const ConfirmChangeScanStatus = (props) => {
  const theme = useTheme(); // Access theme object
  const { loading } = useSelector((state) => state?.userState);

  const handleDescriptionChange = (event) => {
    props.setDescription(event.target.value);
  };
  const isStatusChange = props?.selectedScanStatus; // Determine if it's a status change based on selectedScanStatus

  return (
    <Dialog
      open={props?.open}
      onClose={props?.handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        elevation: 10, // Increased shadow for a more prominent look
        sx: {
          borderRadius: 8, // More rounded corners for a modern look
          padding: "20px", // Padding around the entire dialog content
        },
      }}
    >
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          textAlign: "center",
          fontSize: 22,
          fontWeight: 700,
          color: theme.palette.text.primary, // Dynamic text color
          marginBottom: 1,
        }}
      >
        {isStatusChange ? "Confirm Status Change" : "Confirm Publish Scan"}
      </DialogTitle>

      <DialogContent sx={{ paddingX: 4, paddingBottom: 1 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            textAlign: "center",
            marginBottom: 2,
            color: theme.palette.text.primary, // Dynamic text color
          }}
        >
          {isStatusChange
            ? "Are you sure you want to change the scan status?"
            : "Are you sure you want to publish this scan?"}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: theme.palette.text.secondary, // Dynamic secondary text color
            marginBottom: 3,
          }}
        >
          {isStatusChange
            ? "Changing the status may affect ongoing tasks related to the scan."
            : "Publishing the scan will make it available for review."}
        </Typography>

        {/* Description Field */}
        <TextField
          label="Description (Optional)"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={props?.description}
          onChange={handleDescriptionChange}
          placeholder="Add an optional description..."
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#616161" : "#eceff1", // Adjust based on mode
            borderRadius: 2,
            marginBottom: 2,
          }}
        />
      </DialogContent>

      <DialogActions
        sx={{ justifyContent: "center", paddingX: 4, paddingBottom: 3 }}
      >
        <Stack
          direction={"row"}
          justifyContent={"center"}
          gap={2}
          sx={{ width: "100%" }}
        >
          <Button
            fullWidth
            size="small" // Smaller button size for compact design
            onClick={props?.handleClose}
            sx={{
              color: theme.palette.text.secondary, // Neutral color for cancel
              border: `1px solid ${
                theme.palette.mode === "dark" ? "#b0bec5" : "#90a4ae"
              }`, // Subtle border
              backgroundColor: "transparent",
              borderRadius: 2,
              padding: "6px 16px", // Compact padding for smaller button
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="small" // Smaller button size
            variant="contained"
            autoFocus
            onClick={() => props?.handleChangeStatusAction(props?.description)}
            loading={loading}
            sx={{
              borderRadius: 2,
              padding: "6px 16px", // Compact padding
            }}
          >
            {isStatusChange ? "Submit" : "Publish"}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChangeScanStatus;
