import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbarReducer';

export const notificationManager = (res, successAlert) => {
  let alert;
  switch (res?.status) {
    case 200:
      alert = {
        color: 'success',
        message:
          res?.data?.responseMessage ||
          successAlert ||
          'operation execute successfully ',
      };
      break;
    case 401:
      alert = {
        color: 'error',
        message: res?.data?.responseMessage,
      };
    case 400:
      alert = {
        color: 'error',
        message: res?.message,
      };
  }
  dispatch(
    openSnackbar({
      open: true,
      message: alert?.message,
      transition: 'Fade',
      variant: 'alert',
      alert: {
        color: alert?.color,
      },
    })
  );
};
