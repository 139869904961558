// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  userInfo: {},
};

const slice = createSlice({
  name: 'user-info',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // Add USER_INFO
    addUserInfo(state, action) {
      state.userInfo = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { addUserInfo } = slice.actions;


// ----------------------------------------------------------------------
