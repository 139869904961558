import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(
  lazy(() => import('views/pages/common/authentication/authentication/Login'))
);

// forgot password routing
const ForgotPassword = Loadable(
  lazy(() => import('views/pages/common/authentication/authentication/forgotPassword'))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/login',
      element: <AuthLogin />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
  ],
};

export default LoginRoutes;
