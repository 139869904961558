// material-ui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
// import LogoImg from "../assets/images/logo.png";
// import LogoImg from "../assets/images/logo_white_1 (1).png";
import LogoImg from "../assets/images/auth/logo_HD.png";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <Box
      component="img"
      src={LogoImg}
      alt="qSEAp"
      // width={100}
      width={90}
      sx={{
        // width: "12em",
        // height: "12em",
        // backgroundColor: "white",
        // paddingTop: "45px",
        // padding: "35px 15px 15px 15px",
        // padding: "12px",
        // marginRight: "20px",
      }}
    />
  );
};

export default Logo;
