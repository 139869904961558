import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { dispatch } from "store";

import SaveIcon from "@mui/icons-material/Save";
import { NO_VALUE, USER_ROLES } from "constant";
import {
  getScanDetails,
  getSecurityManagerList,
  getTesterList,
  updateScan
} from "store/slices/scanReducer";
import { handleUserRoles } from "utils/helpers";

const ScanDetails = (props) => {
  const location = useLocation()?.pathname?.split("/")[1];
  const params = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const { securityManagerList } = useSelector((state) => state?.scansState);
  const { testerList } = useSelector((state) => state?.scansState);
  const [testerName, setTesterName] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [securityManagerListName, setSecurityManagerListName] = useState([]);
  const clientOrgId = sessionStorage.getItem("clientOrgId");
  const { userRoles } = useSelector((state) => state?.menu);

  useEffect(() => {
    if (props?.scanDetails) {
      if (handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER)) {
        dispatch(getTesterList(props?.scanDetails?.clientOrgId));
      } else {
        userRoles?.length > 0 &&
          dispatch(getSecurityManagerList(props?.scanDetails?.clientOrgId));
      }
      setSelectedUser(
        handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER)
          ? props?.scanDetails?.testerId
          : props?.scanDetails?.smId
      );
    }
  }, [props?.scanDetails, userRoles]);
  useEffect(() => {
    const list =
      securityManagerList?.length &&
      securityManagerList?.map((item) => {
        return {
          ...item,
          name: `${item?.firstName} ${item?.lastName} (${item?.loginName})`,
        };
      });
    setSecurityManagerListName(list || []);
  }, [securityManagerList]);
  useEffect(() => {
    const list =
      testerList?.length &&
      testerList?.map((item) => {
        return {
          ...item,
          name: `${item?.firstName} ${item?.lastName} (${item?.loginName})`,
        };
      });
    setTesterName(list || []);
  }, [testerList]);
  const handleEdit = () => {
    if (isEdit) {
      dispatch(
        updateScan(
          {
            scanId: parseInt(params?.scanId),
            consultantId: selectedUser,
            clientOrgId: props?.scanDetails?.clientOrgId,
          },
          "tester"
        )
      ).then(() => {
        dispatch(getScanDetails(params?.scanId, clientOrgId));
      });
    }
    setIsEdit(!isEdit);
  };
  const handleChange = (e, value) => {
    setSelectedUser(value?.userId);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              sx={{
                "& td": {
                  borderBottom: "none",
                  padding: "4px 4px 4px", // Adjust the padding here
                },
              }}
              size="small"
            >
              <TableBody size="small">
                {/* First Row */}
                <TableRow>
                  <TableCell variant="head" size="small">
                    Type
                  </TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.scanTypeName}</TableCell>

                  <TableCell variant="head">Sub Type</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    {props?.scanDetails?.scanSubTypeName || NO_VALUE}
                  </TableCell>

                  <TableCell variant="head">Credential Scan</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    {/* {props?.scanDetails?.isCredential || NO_VALUE} */}
                    {props?.scanDetails?.isCredential ? "Yes" : "No"}
                  </TableCell>
                </TableRow>

                {/* Second Row */}
                <TableRow>
                  <TableCell variant="head">Created On</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    {props?.scanDetails?.createdOn &&
                      moment(props.scanDetails?.createdOn)?.format(
                        "Do MMM YYYY"
                      )}
                  </TableCell>
                  <TableCell variant="head">Expected Start Date</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    {props?.scanDetails?.expectedStartDate &&
                      moment(props.scanDetails?.expectedStartDate)?.format(
                        "Do MMM YYYY"
                      )}
                  </TableCell>
                  <TableCell variant="head">Expected End Date</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    {props?.scanDetails?.expectedEndDate &&
                      moment(props.scanDetails.expectedEndDate).format(
                        "Do MMM YYYY"
                      )}
                  </TableCell>
                </TableRow>

                {/* Third Row */}
                <TableRow>
                  <TableCell variant="head">Status</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.status}</TableCell>
                  <TableCell variant="head">Mode</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.scanModeDesc}</TableCell>
                  <TableCell variant="head">Category</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.scanCategoryDesc}</TableCell>
                </TableRow>

                {/* Fourth Row */}
                <TableRow>
                  <TableCell variant="head">Created By</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.createdByName}</TableCell>
                  <TableCell variant="head">
                    {handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER)
                      ? "Security Manager"
                      : "Tester"}
                  </TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                      <Box>
                        {handleUserRoles(
                          userRoles,
                          USER_ROLES?.CO_SECURITY_MANAGER
                        )
                          ? props?.scanDetails?.smName
                          : props?.scanDetails?.testerName}
                      </Box>
                    </Stack>
                  </TableCell>

                  <TableCell variant="head">
                    {handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER)
                      ? "Tester"
                      : "Security Manager"}
                  </TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                      {isEdit ? (
                        <Box sx={{ width: "50%" }}>
                          {handleUserRoles(
                            userRoles,
                            USER_ROLES?.CO_SECURITY_MANAGER
                          ) ? (
                            <>
                              <Grid item>
                                <Autocomplete
                                  fullWidth
                                  options={testerName}
                                  getOptionLabel={(option) => option.name}
                                  defaultValue={() =>
                                    testerName.find(
                                      (i) => i.userId === selectedUser
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  name="testerId"
                                  onChange={handleChange}
                                  clearIcon={false}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>{props?.scanDetails?.smName}</>
                          )}
                        </Box>
                      ) : (
                        <Box>
                          {handleUserRoles(
                            userRoles,
                            USER_ROLES?.CO_SECURITY_MANAGER
                          )
                            ? props?.scanDetails?.testerName
                            : props?.scanDetails?.smName}
                        </Box>
                      )}
                      {props?.scanDetails?.updateTesterAllowed && (
                        <Box>
                          {handleUserRoles(
                            userRoles,
                            USER_ROLES?.CO_SECURITY_MANAGER
                          ) && (
                            <>
                              <Button onClick={handleEdit}>
                                {!isEdit ? <EditIcon /> : <SaveIcon />}
                              </Button>
                              {isEdit && (
                                <Button onClick={() => setIsEdit(!isEdit)}>
                                  <CloseIcon />
                                </Button>
                              )}
                            </>
                          )}
                        </Box>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
                {/* Fifth Row */}
                <TableRow>
                  {props?.scanDetails?.parentScanName && (
                    <>
                      <TableCell variant="head">Parent Scan Name</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {props?.scanDetails?.parentScanName}
                      </TableCell>
                    </>
                  )}
                  {props?.scanDetails?.parentScanRefNo && (
                    <>
                      <TableCell variant="head">Parent Scan Ref Code</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {props?.scanDetails?.parentScanRefNo}
                      </TableCell>
                    </>
                  )}
                  <TableCell variant="head">Department</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.departmentName}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default ScanDetails;
