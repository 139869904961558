import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { IconDashboard } from '@tabler/icons-react';
import HomeIcon from '@mui/icons-material/Home';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import { LAYOUT_CONST } from 'constant';
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';
import useConfig from 'hooks/useConfig';
import Transitions from 'ui-component/extended/Transitions';
import { dispatch, useSelector } from 'store';
import { activeID } from 'store/slices/menu';
import BugReportIcon from "@mui/icons-material/BugReport";

// assets
import {
  IconChevronDown,
  IconChevronRight,
  IconMinusVertical,
} from '@tabler/icons-react';

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 5,
    left: 32,
    width: 12,
    height: 12,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderWidth: '6px',
    borderStyle: 'solid',
    borderColor: `${theme.palette.background.paper}  transparent transparent ${theme.palette.background.paper}`,
  },
}));

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, lastItem, remItems, lastItemId }) => {
  const theme = useTheme();

  const { pathname } = useLocation();
  const { drawerOpen, selectedID } = useSelector((state) => state.menu);
  const { layout, borderRadius } = useConfig();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItem, setCurrentItem] = useState(item);
  const iconMapping = {
    AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
    DocumentScannerIcon: <DocumentScannerIcon />,
    PeopleOutlineIcon: <PeopleOutlineIcon />,
    CorporateFareIcon: <CorporateFareIcon />,
    HomeIcon: <HomeIcon />,
    IconDashboard: <IconDashboard />,
    TextSnippetIcon: <TextSnippetIcon />,
    HomeRepairServiceOutlinedIcon: <HomeRepairServiceOutlinedIcon />,
    CropFreeOutlinedIcon: <CropFreeOutlinedIcon />,
    BugReportIcon: <BugReportIcon />,
  };
  const openMini = Boolean(anchorEl);

  useEffect(() => {
    if (lastItem) {
      if (item.menuCode === lastItemId) {
        const localItem = { ...item };
        const elements = remItems?.map((ele) => ele.elements);
        localItem.menuChildren = elements.flat(1);
        setCurrentItem(localItem);
      } else {
        setCurrentItem(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, lastItem, layout, matchDownMd]);

  const checkOpenForParent = (child, menuCode) => {
    child.forEach((ele) => {
      if (ele.menuChildren?.length) {
        checkOpenForParent(ele.menuChildren, currentItem.menuCode);
      }
      if (ele.menuPath === pathname) {
        dispatch(activeID(menuCode));
      }
    });
  };

  const checkSelectedOnload = (data) => {
    const childrens = data.menuChildren ? data.menuChildren : [];
    childrens.forEach((itemCheck) => {
      if (itemCheck?.menuChildren?.length) {
        checkOpenForParent(itemCheck.menuChildren, currentItem.menuCode);
      }
      if (itemCheck?.menuPath === pathname) {
        dispatch(activeID(currentItem.menuCode));
      }
    });
  };

  // keep selected-menu on page load and use for horizontal menu close on change routes
  useEffect(() => {
    checkSelectedOnload(currentItem);
    if (openMini) setAnchorEl(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentItem]);

  const handleClick = (event) => {
    if (!openMini) {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Icon = currentItem?.menuIcon;
  const itemIcon = currentItem?.menuIcon ? (
    <Icon stroke={1.5} size="20px" />
  ) : null;

  // menu list collapse & items
  const items = [item]?.map((menu) => {
    if (menu?.menuChildren?.length) {
      return (
        <NavCollapse
          key={menu.menuCode}
          menu={menu}
          level={1}
          parentId={currentItem.menuCode}
        />
      );
    } else {
      return (
        <>
          <NavItem
            key={menu.menuCode}
            item={menu}
            level={1}
            parentId={currentItem.menuCode}
          />
        </>
      );
    }
  });

  const moreItems = remItems?.map((itemRem, i) => (
    <Fragment key={i}>
      {itemRem.menuPath ? (
        <NavItem item={itemRem} level={1} />
      ) : (
        itemRem.menuDesc && (
          <Typography variant="caption" sx={{ pl: 2 }}>
            <FormattedMessage id={itemRem.menuDesc} />
            {itemRem.menuPath}
          </Typography>
        )
      )}
      {itemRem?.elements?.map((menu) => {
        if (menu?.menuChildren?.length) {
          return (
            <NavCollapse
              key={menu.menuCode}
              menu={menu}
              level={1}
              parentId={currentItem.menuCode}
            />
          );
        } else {
          return (
            <NavItem
              key={menu.menuCode}
              item={menu}
              level={1}
              parentId={currentItem.menuCode}
            />
          );
        }
      })}
    </Fragment>
  ));

  const popperId = openMini ? `group-pop-${item.menuCode}` : undefined;

  return (
    <>
      {/* {drawerOpen &&
        item?.menuChildren?.length <= 1 &&
        !item?.menuChildren[0]?.menuChildren?.length && (
          <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        )} */}

      {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
      (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <>
          <List
            disablePadding={!drawerOpen}
            subheader={
              currentItem.menuDesc &&
              drawerOpen && 
              ""
              // (
              //   <Typography
              //     variant="caption"
              //     sx={{ ...theme.typography.menuCaption }}
              //     display="block"
              //     gutterBottom
              //   >
              //     {/* <FormattedMessage id={currentItem.menuDesc} /> */}
              //     {currentItem.caption && (
              //       <Typography
              //         variant="caption"
              //         sx={{ ...theme.typography.subMenuCaption }}
              //         display="block"
              //         gutterBottom
              //       >
              //         {currentItem.caption}
              //       </Typography>
              //     )}
              //   </Typography>
              // )
            }
          >
            {items}
          </List>

          {/* group divider */}
          {drawerOpen && <Divider sx={{ mt: 0.25, mb: 1.25 }} />}
        </>
      ) : (
        <List>
          <ListItemButton
            selected={selectedID === currentItem.menuCode}
            sx={{
              borderRadius: `${borderRadius}px`,
              p: 1,
              my: 0.5,
              mr: 1,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'inherit',
            }}
            onMouseEnter={handleClick}
            onClick={handleClick}
            onMouseLeave={handleClose}
            aria-describedby={popperId}
          >
            {itemIcon && (
              <ListItemIcon
                sx={{
                  my: 'auto',
                  minWidth: !item?.menuIcon ? 18 : 36,
                }}
              >
                {iconMapping[item.menuIcon]}
              </ListItemIcon>
            )}
            <ListItemText
              sx={{ mr: 1 }}
              primary={
                <Typography
                  variant={selectedID === currentItem.menuCode ? 'h5' : 'body1'}
                  color="inherit"
                >
                  {currentItem.menuCode === lastItemId ? (
                    <FormattedMessage id="more-items" />
                  ) : (
                    <FormattedMessage id={currentItem.menuDesc} />
                  )}
                </Typography>
              }
            />
            {openMini ? (
              <IconChevronDown stroke={1.5} size="16px" />
            ) : (
              <IconChevronRight stroke={1.5} size="16px" />
            )}

            {anchorEl && (
              <PopperStyled
                id={popperId}
                open={openMini}
                anchorEl={anchorEl}
                placement="bottom-start"
                style={{
                  zIndex: 2001,
                }}
              >
                {({ TransitionProps }) => (
                  <Transitions in={openMini} {...TransitionProps}>
                    <Paper
                      sx={{
                        mt: 0.5,
                        py: 1.25,
                        boxShadow: theme.shadows[8],
                        backgroundImage: 'none',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <Box
                          sx={{
                            minWidth: 200,
                            maxHeight: 'calc(100vh - 170px)',
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                              opacity: 0,
                              width: 4,
                              '&:hover': {
                                opacity: 0.7,
                              },
                            },
                            '&::-webkit-scrollbar-track': {
                              background: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: theme.palette.divider,
                              borderRadius: 4,
                            },
                          }}
                        >
                          {currentItem.menuCode !== lastItemId
                            ? items
                            : moreItems}
                        </Box>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
                )}
              </PopperStyled>
            )}
          </ListItemButton>
        </List>
      )}
    </>
  );
};

NavGroup.propTypes = {
    item: PropTypes.object,
    lastItem: PropTypes.number,
    remItems: PropTypes.array,
    lastItemId: PropTypes.string,
    parentId: PropTypes.string
};

export default NavGroup;
