import PropTypes from 'prop-types';

// material-ui
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ==============================|| FORM CONTROL SELECT ||============================== //

const FormAutoComplete = ({
  placeholder,
  captionLabel,
  itemsList,
  formState,
  iconPrimary,
  iconSecondary,
  selected,
  textPrimary,
  textSecondary,
  onChange,
  name,
  optionLabel,
  optionValue,
  errors,
  ...props
}) => {
  const theme = useTheme();
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? (
    <IconPrimary fontSize="small" sx={{ color: theme.palette.grey[700] }} />
  ) : null;

  const IconSecondary = iconSecondary;
  const secondaryIcon = iconSecondary ? (
    <IconSecondary fontSize="small" sx={{ color: theme.palette.grey[700] }} />
  ) : null;

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...props}
        onChange={onChange}
        getOptionLabel={(options) => options[optionLabel]}
        renderInput={(params) => (
          <TextField placeholder={name} {...params} {...props} />
        )}
        clearIcon={props.clearIcon}
      />
    </FormControl>
  );
};

FormAutoComplete.propTypes = {
  captionLabel: PropTypes.string,
  itemsList: PropTypes.array,
  formState: PropTypes.string,
  iconPrimary: PropTypes.object,
  iconSecondary: PropTypes.object,
  selected: PropTypes.string,
  textPrimary: PropTypes.string,
  textSecondary: PropTypes.string,
};

export default FormAutoComplete;
