import React, { useState } from 'react';
import { PreviewAttachmentList } from './PreviewAttachmentList';
import { Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@emotion/react';
import { userActionAccess } from 'utils/helpers';
import { ACTION_TYPE } from 'constant';

const Comment = ({
  item = {},
  handleManageDeleteModel,
  setSelectedCommentId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const avatarUrl = "https://cdn.iconscout.com/icon/free/png-512/free-avatar-370-456322.png?f=webp&w=512";

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <Box
              component="img"
              src={avatarUrl}
              alt="User 1"
              height={40}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography align="left" variant="h4" component="div" color={theme.palette.text.primary}>
                  {item?.commentedByName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="left" variant="subtitle1" color={theme.palette.text.secondary}>
                  {item?.createdOn &&
                    moment(item?.createdOn).format('- Do MMM YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box direction={'row'} alignItems={'center'} gap={1}>
              <IconButton onClick={() => setIsOpen(!isOpen)}>
                <AttachFileIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              {userActionAccess(ACTION_TYPE?.DEL_SCAN_CMT) ?
                <IconButton
                  onClick={() => {
                    setSelectedCommentId(item?.scanCommentId);
                    handleManageDeleteModel();
                  }}
                >
                  <DeleteIcon sx={{ color: theme.palette.error.main }} />
                </IconButton>
             :'' }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* post - content */}
      <Grid
        item
        xs={12}
        sx={{
          p: 1,
          background: theme.palette.background.paper,
          borderRadius: '8px',
          my: 2,
          '& > p': {
            ...theme.typography.body1,
            color: theme.palette.text.primary,
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: item?.commentDesc }}></div>
      </Grid>

      <Grid item xs={12}>
        {isOpen && (
          <PreviewAttachmentList
            item={item}
            fieldName="scanCommentId"
            endPoint="scan"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Comment;
