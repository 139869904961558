// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { isLoading, setLoading } from "store/slices/user";
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";

// ----------------------------------------------------------------------
const consultantAssetsGroupApiEndpoint = `/consultant/v1/asset/group`;
const consultantAssetsApiEndpoint = `/consultant/v1/asset`;
const clientAssetsApiEndpoint = `/client/v1/asset`;
const clientAssetsGroupApiEndpoint = `/client/v1/asset/group`;
const initialState = {
  error: null,
  allAssets: [],
  clientOrgList: [],
  OrgAssetsList: [],
  operatingSystemList: [],
  cloudProvidersList: [],
  assetTypeList: [],
  addAsset: {},
  assetGroupList: [],
  addedAssetGroup: {},
  updatedAssetGroup: {},
  groupMappedAsset: [],
  groupUnmappedAsset: [],
  updatedAssets: {},
  selectedAsset: [],
  mapNewAsset: {},
  unmapAsset: {},
  exposureList: [],
  sensitivityList: [],
  uploadAsset: {},
  deleteAssetResponse: {},
  downloadAssetTemplateResponse: {},
  selectAssetGroupDetails: {},
  assetGroupManager: [],
  assetDetail: [],
  RiskComplianceScore: {},
  PublishedScansList: [],
  PublishedVulnerabilitiesList: [],
  riskComplianceScoreList: {},
  downloadVulReportRes: {},
  downloadScanReportRes: {},
  amList: [],
  spocList: [],
  GhUserList: [],
  exportAssetDataRes: {},
  insightData: [],
  OrgAssetsListForFilter: [],
};

const slice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // --------------------- GET APIs --------------------------------

    // GET All_ASSETS
    getAllAssetsSuccess(state, action) {
      state.allAssets = action.payload;
    },

    // GET ORG_OF_CLIENT
    getOrgOfClientList(state, action) {
      state.clientOrgList = action.payload;
    },

    // GET ORG_ASSETS
    getOrgAssetsList(state, action) {
      state.OrgAssetsList = action.payload;
    },
    // GET ORG_ASSETS_FOR_FILTER
    getOrgAssetsListForFilter(state, action) {
      state.OrgAssetsListForFilter = action.payload;
    },

    // GET OPERATING_SYSTEM
    operatingSystemList(state, action) {
      state.operatingSystemList = action.payload;
    },

    // GET CLOUD_PROVIDER LIST
    cloudProvidersSuccess(state, action) {
      state.cloudProvidersList = action.payload;
    },

    // GET AASET_TYPE
    assetTypeSuccess(state, action) {
      state.assetTypeList = action.payload;
    },

    // ADD ASSET
    addAssetsSuccess(state, action) {
      state.addAsset = action.payload;
    },

    // GET ASSET_GROUP
    AssetGroupSuccess(state, action) {
      state.assetGroupList = action.payload;
    },

    // ADD ASSET_GROUP
    addAssetGroupSuccess(state, action) {
      state.addedAssetGroup = action.payload;
    },

    // ADD ASSET_GROUP
    updateAssetGroupSuccess(state, action) {
      state.updatedAssetGroup = action.payload;
    },

    // GET GROUP_MAPPED_ASSET
    groupMappedAssetSuccess(state, action) {
      state.groupMappedAsset = action.payload;
    },

    // GET GROUP_UNMAPPED_ASSET
    groupUnmappedAssetSuccess(state, action) {
      state.groupUnmappedAsset = action.payload;
    },

    // UPDATE ASSET
    updateAssetsSuccess(state, action) {
      state.updatedAssets = action.payload;
    },

    // GET SINGLE_ASSET
    getSingleAssetSuccess(state, action) {
      state.selectedAsset = action.payload;
    },

    // ADD NEW_ASSET
    mapNewAssetsSuccess(state, action) {
      state.mapNewAsset = action.payload;
    },

    // REMOVE ASSET
    unmapNewAssetsSuccess(state, action) {
      state.unmapAsset = action.payload;
    },

    // GET Exposure_List
    exposureListSuccess(state, action) {
      state.exposureList = action.payload;
    },

    // GET Sensitivity_List
    sensitivityListSuccess(state, action) {
      state.sensitivityList = action.payload;
    },

    // UPLOAD ASSET
    uploadAssetSuccess(state, action) {
      state.uploadAsset = action.payload;
    },

    // DELETE ASSET
    deleteAssetSuccess(state, action) {
      state.deleteAssetResponse = action.payload;
    },

    // DOWNLOAD ASSET TEMPLATE
    downloadAssetTemplateSuccess(state, action) {
      state.downloadAssetTemplateResponse = action.payload;
    },

    // SINGLE ASSET GROUP DETAIL
    selectAssetGroupDetailsSuccess(state, action) {
      state.selectAssetGroupDetails = action.payload;
    },

    // GET ASSET GROUP MANAGER
    assetGroupManagerSuccess(state, action) {
      state.assetGroupManager = action.payload;
    },
    // GET ASSET DETAIL
    fetchAssetDetailSuccess(state, action) {
      state.assetDetail = action.payload;
    },

    // GET ASSET DETAIL
    fetchRiskComplianceScoreSuccess(state, action) {
      state.RiskComplianceScore = action.payload;
    },
    // GET PUBLISHED SCAN
    fetchPublishedScansListSuccess(state, action) {
      state.PublishedScansList = action.payload;
    },

    // GET PUBLISHED VULNERABILITIES
    fetchPublishedVulnerabilitiesListSuccess(state, action) {
      state.PublishedVulnerabilitiesList = action.payload;
    },

    // GET COMPLIANCE AND RISK SCORE
    riskComplianceScoreListSuccess(state, action) {
      state.riskComplianceScoreList = action.payload;
    },
    // DOWNLOAD SCAN VULNERABILITIES REPORT
    downloadScanReportSuccess(state, action) {
      state.downloadScanReportRes = action.payload;
    },
    // DOWNLOAD PUBLISHED VULNERABILITIES REPORT
    downloadVulReportSuccess(state, action) {
      state.downloadVulReportRes = action.payload;
    },
    // ASSET MANAGER LIST
    fetchAmListSuccess(state, action) {
      state.amList = action.payload;
    },
    // ASSET MANAGER LIST
    fetchSpocListSuccess(state, action) {
      state.spocList = action.payload;
    },
    // GH USER LIST
    GhUserListSuccess(state, action) {
      state.GhUserList = action.payload;
    },
    // EXPORT ASSET DATA
    exportAssetDataSuccess(state, action) {
      state.exportAssetDataRes = action.payload;
    },
    // EXPORT ASSET DATA
    fetchInsightDataSuccess(state, action) {
      state.insightData = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  downloadAssetTemplateSuccess,
  downloadVulReportSuccess,
  downloadScanReportSuccess,
  exportAssetDataSuccess,
  assetGroupManagerSuccess,
  AssetGroupSuccess,
} = slice.actions;

export const fetchOrgOfClient = () => {
  return async () => {
    dispatch(isLoading(true));
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/client-org/list`
      );
      dispatch(slice.actions.getOrgOfClientList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchAssetOfOrg = (params) => {
  return async () => {
    dispatch(isLoading(true));
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/list/${params.orgId}`,
        { params }
      );
      dispatch(slice.actions.getOrgAssetsList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchOperatingSystemList = () => {
  return async () => {
    dispatch(setLoading(true));
    const prefix =
      localStorage.getItem("organizationType") == "CL"
        ? "client/v1/asset"
        : "consultant/v1/asset";
    try {
      const response = await axios.get(`${prefix}/operation-systems`);
      dispatch(slice.actions.operatingSystemList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchCloudProvidersList = () => {
  return async () => {
    dispatch(setLoading(true));
    const prefix =
      localStorage.getItem("organizationType") == "CL"
        ? "client/v1/asset"
        : "consultant/v1/asset";
    try {
      const response = await axios.get(`${prefix}/cloud-providers`);
      dispatch(slice.actions.cloudProvidersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAssetTypeList = () => {
  return async () => {
    dispatch(setLoading(true));
    const prefix =
      localStorage.getItem("organizationType") === "CL"
        ? "client/v1/asset"
        : "consultant/v1/asset";
    try {
      const response = await axios.get(`${prefix}/asset-types`);
      dispatch(slice.actions.assetTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addNewAssets = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.post(
        `${consultantAssetsApiEndpoint}`,
        payload
      );
      dispatch(slice.actions.addAssetsSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const updateAssets = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));

    const url =
    localStorage.getItem("organizationType") === "CL"
      ? `${clientAssetsApiEndpoint}`
      : `${consultantAssetsApiEndpoint}`;

    try {
      const response = await axios.put(url, payload);
      dispatch(slice.actions.updateAssetsSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fecthSingleAssetDetail = (assetId, orgId) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
    localStorage.getItem("organizationType") === "CL"
      ? `${clientAssetsApiEndpoint}/${assetId}`
      : `${consultantAssetsApiEndpoint}/${orgId}/${assetId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getSingleAssetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAssetGroupList = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/group/list/${payload.orgId}`
      );
      dispatch(slice.actions.AssetGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const addNewAssetGroup = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        `${consultantAssetsApiEndpoint}/group/${payload.orgId}`,
        payload.data
      );
      dispatch(slice.actions.addAssetGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateAssetGroup = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.put(
        `${consultantAssetsApiEndpoint}/group/${payload.orgId}`,
        payload.data
      );
      dispatch(slice.actions.updateAssetGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchGroupMappedAsset = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/group/mapped-asset/${
          payload?.clientOrgId
        }/${payload?.assetGroupId}?${
          payload?.assetType ? `assetType=${payload?.assetType}` : ""
        }`
      );
      dispatch(slice.actions.groupMappedAssetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const mapNewAssetInGroup = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        `${consultantAssetsApiEndpoint}/group/mapped-asset/${payload?.clientOrgId}/${payload?.assetGroupId}`,
        { idList: payload?.idList }
      );
      dispatch(slice.actions.mapNewAssetsSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const unmapNewAssetInGroup = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        `${consultantAssetsApiEndpoint}/group/remove-asset/${payload?.clientOrgId}/${payload?.assetGroupId}`,
        { idList: payload?.idList }
      );
      dispatch(slice.actions.unmapNewAssetsSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchGroupUnmappedAsset = (params) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/group/unmapped-asset/${params.clientOrgId}/${params.assetGroupId}`,
        { params }
      );
      dispatch(slice.actions.groupUnmappedAssetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const fetchExposureList = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    const prefix =
      localStorage.getItem("organizationType") == "CL"
        ? "client/v1/asset"
        : "consultant/v1/asset";
    try {
      const response = await axios.get(`${prefix}/exposure-list`);
      dispatch(slice.actions.exposureListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchSensitivityList = (payload) => {
  return async () => {
    dispatch(setLoading(true));
    const prefix =
      localStorage.getItem("organizationType") == "CL"
        ? "client/v1/asset"
        : "consultant/v1/asset";
    try {
      const response = await axios.get(`${prefix}/sensitivity-list`);
      dispatch(slice.actions.sensitivityListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const uploadAssetFile = (clientOrgId, file) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        `${consultantAssetsApiEndpoint}/upload/${clientOrgId}`,
        file,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(slice.actions.uploadAssetSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteAssets = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") == "CL"
        ? "client/v1/asset"
        : `consultant/v1/asset/${payload?.clientOrgId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.delete(`${url}`, {
        data: payload?.scanIds,
      });
      dispatch(slice.actions.deleteAssetSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const downloadAssetTemplate = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/asset-template/download`
      );
      dispatch(slice.actions.downloadAssetTemplateSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchSelectAssetGroupDetails = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/group/{assetGroupId}/{clientOrgId}?assetGroupId=${payload?.assetGroupId}&clientOrgId=${payload?.clientOrgId}`
      );
      dispatch(slice.actions.selectAssetGroupDetailsSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchAssetGroupManager = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/group/asset-managers/${payload?.assetGroupId}`
        : `${consultantAssetsApiEndpoint}/group/asset-managers/${payload?.assetGroupId}/${payload?.clientOrgId}`;

    dispatch(isLoading(true));
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.assetGroupManagerSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchAssetDetail = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/${payload?.assetId}`
        : `${consultantAssetsApiEndpoint}/${payload?.clientOrgId}/${payload?.assetId}`;
    const assetGroupUrl =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsGroupApiEndpoint}/${payload?.assetId}`
        : `${consultantAssetsGroupApiEndpoint}/${payload?.clientOrgId}/${payload?.assetId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${payload?.assetGroup ? assetGroupUrl : url}`
      );
      dispatch(slice.actions.fetchAssetDetailSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};
export const fetchRiskComplianceScore = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const assetGroup = payload?.assetGroup;

    if (assetGroup) {
      delete payload.assetGroup;
    }

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/risk-compliance-score/${payload?.assetId}`
        : `${consultantAssetsApiEndpoint}/risk-compliance-score/${payload?.assetId}/{clientOrgId}?clientOrgId=${payload?.clientOrgId}`;
    const assetGroupUrl =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsGroupApiEndpoint}/risk-compliance-score/${payload?.assetId}`
        : `${consultantAssetsGroupApiEndpoint}/risk-compliance-score/${payload?.clientOrgId}/${payload?.assetId}`;
    dispatch(isLoading(true));
    try {
      // delete payload?.assetGroup;
      const response = await axios.get(
        `${assetGroup ? assetGroupUrl : url}`
        // `${payload?.assetGroup ? assetGroupUrl : url}`
      );
      dispatch(slice.actions.fetchRiskComplianceScoreSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchPublishedScansList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    const assetGroup = params?.assetGroup;

    if (assetGroup) {
      delete params.assetGroup;
    }

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/published-scans/${params?.assetId}`
        : `${consultantAssetsApiEndpoint}/published-scans/${params?.assetId}/${params?.clientOrgId}?clientOrgId=${params?.clientOrgId}`;
    const assetGroupUrl =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsGroupApiEndpoint}/published-scans/${params?.assetId}`
        : `${consultantAssetsGroupApiEndpoint}/published-scans/${params?.assetId}/${params?.clientOrgId}?clientOrgId=${params?.clientOrgId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${assetGroup ? assetGroupUrl : url}`,
        // `${params?.assetGroup ? assetGroupUrl : url}`,
        { params }
      );
      dispatch(slice.actions.fetchPublishedScansListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchPublishedVulnerabilitiesList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    const assetGroup = params?.assetGroup;

    if (assetGroup) {
      delete params.assetGroup;
    }

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/published-vulnerabilities/${params?.assetId}`
        : `${consultantAssetsApiEndpoint}/published-vulnerabilities/${params?.assetId}/${params?.clientOrgId}?clientOrgId=${params?.clientOrgId}`;
    const assetGroupUrl =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsGroupApiEndpoint}/published-vulnerabilities/${params?.assetId}`
        : `${consultantAssetsGroupApiEndpoint}/published-vulnerabilities/${params?.assetId}/${params?.clientOrgId}?clientOrgId=${params?.clientOrgId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${assetGroup ? assetGroupUrl : url}`,
        // `${params?.assetGroup ? assetGroupUrl : url}`,
        { params }
      );
      dispatch(
        slice.actions.fetchPublishedVulnerabilitiesListSuccess(response.data)
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchRiskComplianceScoreList = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/risk-compliance-score/${payload?.assetId}/{clientOrgId}?clientOrgId=${payload?.clientOrgId}`
      );
      dispatch(slice.actions.riskComplianceScoreListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const downloadScanReport = (payload) => {
  const data = {
    exportReport: "true",
  };
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/published-scans/${payload?.assetId}`
        : `${consultantAssetsApiEndpoint}/published-scans/${payload?.assetId}/{clientOrgId}?clientOrgId=${payload?.clientOrgId}`;
    const assetGroupUrl =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsGroupApiEndpoint}/published-scans/${payload?.assetId}`
        : `${consultantAssetsGroupApiEndpoint}/published-scans/${payload?.assetId}/{clientOrgId}?clientOrgId=${payload?.clientOrgId}`;

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${payload?.assetGroup ? assetGroupUrl : url}`,
        { params: data }
      );
      dispatch(slice.actions.downloadScanReportSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const downloadVulReport = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const assetGroup = payload?.assetGroup;

    if (assetGroup) {
      delete payload.assetGroup;
    }

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/published-vulnerabilities/export/${payload?.assetId}`
        : `${consultantAssetsApiEndpoint}/published-vulnerabilities/export/${payload?.assetId}/{clientOrgId}?clientOrgId=${payload?.clientOrgId}`;
    const assetGroupUrl =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsGroupApiEndpoint}/published-vulnerabilities/export/${payload?.assetId}`
        : `${consultantAssetsGroupApiEndpoint}/published-vulnerabilities/export/${payload?.assetId}/{clientOrgId}?clientOrgId=${payload?.clientOrgId}`;

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${assetGroup ? assetGroupUrl : url}`
        // `${payload?.assetGroup ? assetGroupUrl : url}`
      );
      dispatch(slice.actions.downloadVulReportSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchAmList = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/am-user-list`
        : `${consultantAssetsApiEndpoint}/am-user-list/${payload?.clientOrgId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.fetchAmListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchGhUserList = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/group/gh-user-list/${payload?.clientOrgId}`
      );
      dispatch(slice.actions.GhUserListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchSpocList = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/group/spoc-user-list/${payload?.clientOrgId}`
      );
      dispatch(slice.actions.fetchSpocListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const exportAssetData = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/export/${payload?.clientOrgId}`
      );
      dispatch(slice.actions.exportAssetDataSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const fetchInsightData = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    const assetGroup = payload?.assetGroup;

    if (assetGroup) {
      delete payload.assetGroup;
    }

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsApiEndpoint}/vulnerability-count/${payload?.assetId}`
        : `${consultantAssetsApiEndpoint}/vulnerability-count/${payload?.clientOrgId}/${payload?.assetId}`;
    const assetGroupUrl =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientAssetsGroupApiEndpoint}/vulnerability-count/${payload?.assetId}`
        : `${consultantAssetsGroupApiEndpoint}/vulnerability-count/${payload?.clientOrgId}/${payload?.assetId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${assetGroup ? assetGroupUrl : url}`
        // `${payload?.assetGroup ? assetGroupUrl : url}`
      );
      dispatch(slice.actions.fetchInsightDataSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};
export const fetchAssetOfOrgForFilter = (params) => {
  return async () => {
    dispatch(setLoading(true));

    // dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantAssetsApiEndpoint}/list/${params.orgId}`,
        { params }
      );
      dispatch(slice.actions.getOrgAssetsListForFilter(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      // dispatch(isLoading(false));
    }
  };
};
