import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { dispatch } from "store";
import {
  getScanLinkedReportHistory,
  getScanReportHistory,
  handleDownload,
} from "store/slices/scanReducer";
import DialogBox from "ui-component/dialogbox";
import VmTable from "ui-component/table";
import GenerateReport from "./generateReport";
import UploadReportPdf from "./uploadReportPdf";

const ActionButton = ({
  handleOpenMenu,
  handleOpenUploadDialog,
  showGenerateReportInWord,
  showGenerateReportInExcel,
  showGenerateReportInPDF,
  showUploadReportPDF,
  showGenerateReport,
}) => {
  const theme = useTheme();
  return (
    <>
      {showGenerateReport ||
      showGenerateReportInWord ||
      showGenerateReportInExcel ||
      showGenerateReportInPDF ? (
        <IconButton
          variant="contained"
          title="Generate Report"
          size="large"
          color="inherit"
          aria-label="live customize"
          onClick={handleOpenMenu}
        >
          <DescriptionIcon />
        </IconButton>
      ) : (
        ""
      )}
      {showUploadReportPDF ? (
        <IconButton
          variant="contained"
          title="Upload Report Pdf"
          size="large"
          color="inherit"
          aria-label="live customize"
          onClick={handleOpenUploadDialog}
        >
          <FileUploadIcon />
        </IconButton>
      ) : (
        <></>
      )}
    </>
  );
};

const ReportHistory = ({ scanDetails }) => {
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [allowDownload, setAllowDownload] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const { scanReportHistory, scanReportDownload, scanLinkedReportHistory } =
    useSelector((state) => state?.scansState);
  const [reportFileFormat, setReportFileFormat] = useState("");
  const clientOrgId = scanDetails?.clientOrgId;
  const [anchorEl, setAnchorEl] = useState(null);
  const showGenerateReportInExcel =
    scanDetails?.displayScanReportAction?.showGenerateReportInExcel;
  const showGenerateReportInPDF =
    scanDetails?.displayScanReportAction?.showGenerateReportInPDF;
  const showGenerateReportInWord =
    scanDetails?.displayScanReportAction?.showGenerateReportInWord;
  const showGenerateReport =
    scanDetails?.displayScanReportAction?.showGenerateReport;
  const showUploadReportPDF =
    scanDetails?.displayScanReportAction?.showUploadReportPDF;

  const theme = useTheme();
  useEffect(() => {
    if (scanDetails?.scanModeDesc !== "Initial") {
      dispatch(getScanLinkedReportHistory(params?.scanId, clientOrgId));
    }
  }, [setOpenUploadDialog]);

  const fetchData = () => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT)) {
    dispatch(getScanReportHistory(params?.scanId, clientOrgId));
    // }
  };
  let interval;
  useEffect(() => {
    fetchData();
    interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, [params?.scanId, clientOrgId]);

  useEffect(() => {
    if (
      scanReportHistory &&
      !scanReportHistory?.map((value) => value.status).includes("P")
    ) {
      clearInterval(interval);
    }
  }, [scanReportHistory]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
  };

  const handleOpenUploadDialog = () => {
    setOpenUploadDialog(true);
  };

  const handleGenerateReport = (fileFormat) => {
    setReportFileFormat(fileFormat);
    setOpen(true);
    handleCloseMenu();
  };

  const handleDownloadClick = (fileData, fileName) => {
    // Decode the base64 string into a byte array
    const byteArray = atob(fileData)
      .split("")
      ?.map((s) => s.charCodeAt(0));

    // Create the Blob object with the correct MIME type (adjust if needed)
    const blob = new Blob([new Uint8Array(byteArray)], {
      type: "application/octet-stream",
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (allowDownload && scanReportDownload?.fileName) {
      handleDownloadClick(
        scanReportDownload?.fileContent,
        scanReportDownload?.fileName
      );
    }
    setAllowDownload(false);
  }, [allowDownload, scanReportDownload]);

  const columns = [
    {
      accessorKey: "reportName",
      header: "Report Name",
      size: 150,
    },
    {
      accessorKey: "generatedOn",
      header: "Generated On",
      size: 150,
      Cell: ({ row }) => (
        <>{moment(row.original.createdOn).format("Do MMM YYYY")}</>
      ),
    },
    {
      accessorKey: "generatedByName",
      header: "Generated By",
      size: 200,
    },
    {
      accessorKey: "publishedOn",
      header: "Published On",
      size: 150,
      Cell: ({ row }) => (
        <>
          {row?.original?.publishedOn
            ? moment(row?.original?.publishedOn).format("Do MMMM, YYYY h:mm A")
            : ""}
        </>
      ),
    },
    {
      accessorKey: "view",
      header: "Views",
      size: 100,
      Cell: ({ row }) => (
        <>
          {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
          {row?.original?.status === "F" ? (
            <>
              <IconButton disabled>
                <CloseIcon sx={{ color: theme.palette.secondary.main }} />
              </IconButton>
              {row?.original?.obsolete ? (
                <IconButton title="Report Problem" disabled>
                  <ReportProblemIcon
                    sx={{ color: theme.palette.secondary.main }}
                  />
                </IconButton>
              ) : (
                ""
              )}
            </>
          ) : row?.original?.status === "C" ? (
            <>
              <IconButton>
                <DownloadIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                  onClick={() => {
                    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT)) {
                    dispatch(
                      handleDownload(row?.original?.scanReportId, clientOrgId)
                    ).then(() => {
                      setAllowDownload(true);
                    });
                    // }
                  }}
                />
              </IconButton>
              {row?.original?.obsolete ? (
                <IconButton disabled>
                  <ErrorOutlineIcon
                  // sx={{
                  //   color: 'yellow',
                  // }}
                  />
                </IconButton>
              ) : (
                ""
              )}
            </>
          ) : (
            <HourglassBottomIcon sx={{ color: theme.palette.warning.main }} />
          )}
          {/* </div> */}
        </>
      ),
    },
  ];

  const linkedReportcolumns = [
    {
      accessorKey: "scanName",
      header: "Scan Name",
      size: 150,
    },

    {
      accessorKey: "scanReferenceNo",
      header: "Scan Reference",
      size: 200,
    },
    {
      accessorKey: "publishedOn",
      header: "Published On",
      size: 200,
      Cell: ({ row }) => (
        <>
          {row?.original?.publishedOn
            ? moment(row?.original?.publishedOn).format("Do MMMM, YYYY h:mm A")
            : ""}
        </>
      ),
    },
    {
      accessorKey: "reportName",
      header: "Report Name",
      size: 200,
    },
    {
      accessorKey: "fileFormat",
      header: "Report Type",
      size: 200,
      Cell: ({ row }) => (
        <>{row?.original?.fileFormat === "P" ? "PDF" : "Excel"}</>
      ),
    },
    {
      accessorKey: "view",
      header: "Views",
      size: 100,
      Cell: ({ row }) => (
        <>
          {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
          {row?.original?.status === "F" ? (
            <>
              <IconButton disabled>
                <CloseIcon sx={{ color: theme.palette.secondary.main }} />
              </IconButton>
              {row?.original?.obsolete ? (
                <IconButton title="Report Problem" disabled>
                  <ReportProblemIcon
                    sx={{ color: theme.palette.secondary.main }}
                  />
                </IconButton>
              ) : (
                ""
              )}
            </>
          ) : row?.original?.status === "C" ? (
            <>
              <IconButton>
                <DownloadIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                  onClick={() => {
                    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT)) {
                    dispatch(
                      handleDownload(row?.original?.scanReportId, clientOrgId)
                    ).then(() => {
                      setAllowDownload(true);
                    });
                    // }
                  }}
                />
              </IconButton>
              {row?.original?.obsolete ? (
                <IconButton disabled>
                  <ReportProblemIcon
                    sx={{
                      color: theme.palette.secondary.main,
                    }}
                  />
                </IconButton>
              ) : (
                ""
              )}
            </>
          ) : (
            <HourglassBottomIcon sx={{ color: theme.palette.warning.main }} />
          )}
          {/* </div> */}
        </>
      ),
    },
  ];
  return (
    <>
      <VmTable
        tableTitle="Report"
        columns={columns}
        data={scanReportHistory?.length > 0 ? scanReportHistory : []}
        headerAction={
          <ActionButton
            handleOpenMenu={handleOpenMenu}
            handleOpenUploadDialog={handleOpenUploadDialog}
            handleCloseMenu={handleCloseMenu}
            showGenerateReportInExcel={showGenerateReportInExcel}
            showGenerateReportInPDF={showGenerateReportInPDF}
            showGenerateReportInWord={showGenerateReportInWord}
            showGenerateReport={showGenerateReport}
            showUploadReportPDF={showUploadReportPDF}
          />
        }
      />
      <DialogBox
        maxWidth="sm"
        modelTitle={"Generate Report"}
        open={open}
        handleClose={handleClose}
        dialogContent={
          <GenerateReport
            scanName={scanDetails?.scanName}
            reportFileFormat={reportFileFormat}
            handleCloseDialog={handleCloseDialog}
          />
        }
      />
      <Menu
        id="menu-simple-card"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {showGenerateReportInWord && (
          <MenuItem
            onClick={() => {
              handleGenerateReport("WORD");
            }}
          >
            Generate as Word
          </MenuItem>
        )}
        {showGenerateReportInExcel && (
          <MenuItem
            onClick={() => {
              handleGenerateReport("EXCEL");
            }}
          >
            Generate as Excel
          </MenuItem>
        )}
        {showGenerateReportInPDF && (
          <MenuItem
            onClick={() => {
              handleGenerateReport("PDF");
            }}
          >
            Generate as PDF
          </MenuItem>
        )}
      </Menu>

      <DialogBox
        maxWidth="md"
        modelTitle={"Upload PDF"}
        open={openUploadDialog}
        handleClose={handleCloseUploadDialog}
        dialogContent={
          <UploadReportPdf handleCloseUploadDialog={handleCloseUploadDialog} />
        }
      />
      {scanDetails?.scanModeDesc !== "Initial" ? (
        <Grid>
          <VmTable
            tableTitle="Link Report"
            columns={linkedReportcolumns}
            data={
              scanLinkedReportHistory?.length > 0 ? scanLinkedReportHistory : []
            }
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default ReportHistory;
