import PropTypes from 'prop-types';

// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

// assets
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
  <DialogTitle
    sx={{
      m: 0,
      p: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    {...other}
  >
    {children}
    {onClose ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
);

BootstrapDialogTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};
const DialogBox = (props) => {
  return (
    <>
      {' '}
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          sx: { p: 0 },
        }}
        maxWidth={props.maxWidth}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          sx={{
            textTransform: 'capitalize',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {props.modelTitle}
        </BootstrapDialogTitle>
        <DialogContent dividers>{props?.dialogContent}</DialogContent>

        {props.dialogActions && (
          <DialogActions>{props.dialogActions}</DialogActions>
        )}
      </BootstrapDialog>
    </>
  );
};

export default DialogBox;
