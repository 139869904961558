import { createSlice } from "@reduxjs/toolkit";
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { setLoading } from "./user";
const consultantScanApi = "consultant/v1/scan";

const initialState = {
  error: null,
  scansList: [],
  updateSMDetails: {},
  assignSecurityManager: {},
};

const slice = createSlice({
  name: "ScanManagers",
  initialState,
  reducers: {
    // --------------------HAS ERROR-----------------------
    hasError(state, action) {
      state.error = action.payload;
    },
    // --------------------- GET APIs --------------------------------
    getScansList(state, action) {
      state.scansList = action.payload;
    },
    getAssignSecurityManager(state, action) {
      state.assignSecurityManager = action.payload;
    },
    //---------------------- PUT APIs ------------------------------------
    updateSecurityManagerDetails(state, action) {
      state.updateSMDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// -------------------------------------------------
export const fetchScansList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/${consultantScanApi}/assign-sm-scan-list/${params?.orgId}`,
        { params }
      );
      dispatch(slice.actions.getScansList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAssignSecurityManager = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/${consultantScanApi}/smlist/${payload.orgId}`
      );
      dispatch(slice.actions.getAssignSecurityManager(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateSMData = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(`${consultantScanApi}/sm`, payload);
      dispatch(slice.actions.updateSecurityManagerDetails(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
