import { createSlice } from "@reduxjs/toolkit";
import { isLoading, setLoading } from "store/slices/user";
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";

const initialState = {
  error: null,
  organizationList: [],
  selectedOrganizationDetails: {},
  unmappedConsultantList: [],
  mappedConsultantList: [],
  addUnmappedConsultantDetail: [],
};

const slice = createSlice({
  name: "consultant mapping",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // --------------------- GET APIs --------------------------------
    getOrganizationList(state, action) {
      state.organizationList = action.payload;
    },

    getSelectedOrganizationDetails(state, action) {
      state.selectedOrganizationDetails = action.payload;
    },
    getUnmappedConsultantList(state, action) {
      state.unmappedConsultantList = action.payload;
    },
    getMappedConsultantList(state, action) {
      state.mappedConsultantList = action.payload;
    },

    //--------------------POST APIs-------------------------------------
    addconsultantData(state, action) {
      state.addUnmappedConsultantDetail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

//-------------------------------------------------
export const fetchOrganizationList = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get("/consultant/v1/client-org/list");
      dispatch(slice.actions.getOrganizationList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchSelectedOrganizationDetails = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `/consultant/v1/client-org/${payload.orgId}`
      );
      dispatch(slice.actions.getSelectedOrganizationDetails(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchUnmappedConsultantList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `/consultant/v1/client-org/unmapped-user/${params.orgId}`,
        { params }
      );
      dispatch(slice.actions.getUnmappedConsultantList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchMappedConsultantList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `/consultant/v1/client-org/mapped-user/${params.orgId}`,
        { params }
      );
      dispatch(slice.actions.getMappedConsultantList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const addUnmappedConsultant = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.post(
        `/consultant/v1/client-org/map-consultant/${payload.orgId}`,
        payload.data
      );
      dispatch(slice.actions.addconsultantClientMappingPost(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
