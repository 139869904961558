import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { dispatch } from "store";
import {
  addReportConfigDetails,
  getScanReportConfig,
  updateReportConfig,
} from "store/slices/scanReducer";
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import DialogBox from "ui-component/dialogbox";
import ReportConfigList from "./reportConfigList";
import ReportConfigTable from "./reportConfigTable";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { userActionAccess } from "utils/helpers";
import { ACTION_TYPE } from "constant";

const userNameColumns = ["UserName", "Role"];

const AuditColumns = [
  "Functionality/Module/Page which were not tested",
  "Reason",
];
const portScaningColumns = ["IP Addresses/URLs", "List of open ports"];

const ReportQuillContent = (props) => {
  const theme = useTheme();
  const params = useParams();
  const [content, setContent] = useState(
    props?.reportConfig[props?.selectedReportTitle?.title]
  );
  const handleChangeReportConfig = (e) => {
    setContent(e?.target?.value);
  };
  const handleSubmitReportConfig = () => {
    dispatch(
      updateReportConfig(props?.selectedReportTitle?.endPoint, params?.scanId, {
        ...props?.reportConfig,
        [props?.selectedReportTitle?.title]: content,
      })
    );
    props?.handleClose();
  };
  return (
    <>
      <Stack direction="column" gap={3}>
        <TextField
          multiline
          rows={3}
          fullWidth
          value={content}
          onChange={handleChangeReportConfig}
        />
        <Stack
          justifyContent={"flex-end"}
          alignItems={"center"}
          width={"100%"}
          direction={"row"}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              boxShadow: theme.customShadows.secondary,
              ":hover": {
                boxShadow: "none",
              },
            }}
            onClick={handleSubmitReportConfig}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
const ReportConfiguration = (props) => {
  const params = useParams();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [toolUsed, setToolUsed] = useState("");
  const [editDescription, setEditDescription] = useState(true);
  const [editConclusion, setEditConclusion] = useState(true);
  const [editToolUsed, setEditToolUsed] = useState(true);
  const clientOrgId = sessionStorage.getItem("clientOrgId");
  const [selectedReportTitle, setSelectedReportTitle] = useState({
    title: "",
    endPoint: "",
  });
  const { scanReportConfig } = useSelector((state) => state?.scansState);
  const updateReportConfig = props.updateReportConfig;

  useEffect(() => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT_CONFIG)) {
      dispatch(getScanReportConfig(params?.scanId, clientOrgId));
    // }
  }, []);

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT_CONFIG)) {
        dispatch(getScanReportConfig(params?.scanId, clientOrgId));
      // }
    }, 200);
  };

  const handleOpenReactQuillModel = (title, endPoint) => {
    setSelectedReportTitle({
      title,
      endPoint,
    });

    handleClose();
  };

  const handleSavetextAreaDetail = (label) => {
    if (label) {
      const payload = {
        data: {
          scanId: params?.scanId,
          [label === "software-used" ? "softwareUsed" : label]:
            label === "description"
              ? description
              : label === "conclusion"
                ? conclusion
                : toolUsed,
          clientOrgId,
        },
        url: label,
      };
      dispatch(addReportConfigDetails(payload)).then(() => {
        // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT_CONFIG)) {
          dispatch(getScanReportConfig(params?.scanId, clientOrgId));
        // }
        if (label === "description") {
          // setEditDescription(true);
        } else if (label === "conclusion") {
          // setEditConclusion(true);
        } else {
          setEditToolUsed(true);
        }
      });
    }
  };

  const handleSaveTableData = (name, data, url) => {
    const payload = {
      data: {
        scanId: params?.scanId,
        [name]: data,
        clientOrgId,
      },
      url: url,
    };
    dispatch(addReportConfigDetails(payload)).then(() => {
      // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT_CONFIG)) {
        dispatch(getScanReportConfig(params?.scanId, clientOrgId));
      // }
      // setEditDescription(true)
    });
  };

  useEffect(() => {
    setDescription(scanReportConfig?.description);
    setToolUsed(scanReportConfig?.softwareUsed);
    setConclusion(scanReportConfig?.conclusion);
  }, [scanReportConfig]);

  const strengths = scanReportConfig?.strengths?.map((val) => val.strength);
  const weaknesses = scanReportConfig?.weaknesses?.map((val) => val.weakness);
  const displayReportConmfigField =
    scanReportConfig?.displayScanReportConfigField;

  const getDetail = () => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT_CONFIG)) {
      dispatch(getScanReportConfig(params?.scanId, clientOrgId));
    // }

  }
  return (
    <Stack direction="column" gap={2}>
      {displayReportConmfigField?.description ? (
        <Stack direction="column" gap={1}>
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
            direction={"row"}
          >
            <Typography variant="h2">Description</Typography>
            {updateReportConfig ? (
              <IconButton
                color="inherit"
                title={editDescription ? "Edit" : "Cancel"}
                size="large"
                aria-label="live customize"
                variant="contained"
                onClick={() => {
                  setEditDescription(!editDescription);
                  setDescription(scanReportConfig?.description);
                }}
              >
                {editDescription ? <ModeEditIcon /> : <CloseIcon />}
              </IconButton>
            ) : (
              <></>
            )}
          </Stack>
          <SubCard sx={{ minHeight: 100 }}>
            <TextField
              multiline
              rows={3}
              fullWidth
              value={description}
              onChange={(e) => setDescription(e?.target?.value)}
              disabled={editDescription}
            />
            {!editDescription && (
              <IconButton
                color="inherit"
                title="Save"
                size="large"
                aria-label="live customize"
                variant="contained"
                onClick={() => handleSavetextAreaDetail("description")}
                sx={{
                  alignSelf: "flex-end",
                  marginTop: "10px",
                  marginBottom: "5px",
                  float: "right",
                }}
              >
                <SaveIcon />
              </IconButton>
            )}
          </SubCard>
        </Stack>
      ) : (
        <></>
      )}
      {displayReportConmfigField?.displayUserNameRoles && (
        <ReportConfigTable
          tableName="User Name and Role"
          tableAction="userNameRoleList"
          columns={userNameColumns}
          data={
            scanReportConfig?.userNameRoleList
              ? scanReportConfig?.userNameRoleList
              : []
          }
          cellWidth={"50%"}
          tableWidth={"600px"}
          valueLabel={["userName", "role"]}
          handleSave={handleSaveTableData}
          url="username-roles"
          isEdit={scanReportConfig?.userNameRoleList?.length > 0 ? false : true}
          allowUpdate={updateReportConfig}
        />
      )}
      {displayReportConmfigField?.displayAuditConstraints && (
        <ReportConfigTable
          tableName="Audit Constraint"
          tableAction="auditConstraints"
          columns={AuditColumns}
          data={
            scanReportConfig?.auditConstraints
              ? scanReportConfig?.auditConstraints
              : []
          }
          cellWidth={"50%"}
          tableWidth={"95%"}
          valueLabel={["functionality", "reason"]}
          handleSave={handleSaveTableData}
          url="audit-constraints"
          isEdit={scanReportConfig?.auditConstraints?.length > 0 ? false : true}
          allowUpdate={updateReportConfig}
        />
      )}
      {displayReportConmfigField?.displayStrengths &&
        displayReportConmfigField?.displayWeaknesses && (
          <>
            <Typography variant="h2">Vulnerability Overview</Typography>
            {displayReportConmfigField?.displayStrengths && (
              <ReportConfigList
                data={strengths}
                title="Strength"
                handleSave={handleSaveTableData}
                url="strengths"
                listAction="strengths"
                isEdit={scanReportConfig?.strengths?.length > 0 ? false : true}
                label="strength"
                allowUpdate={updateReportConfig}
                getDetail={getDetail}
              />
            )}
            {displayReportConmfigField?.displayWeaknesses && (
              <ReportConfigList
                data={weaknesses}
                title="Weakness"
                handleSave={handleSaveTableData}
                url="weaknesses"
                listAction="weaknesses"
                isEdit={scanReportConfig?.weaknesses?.length > 0 ? false : true}
                label="weakness"
                allowUpdate={updateReportConfig}
                getDetail={getDetail}

              />
            )}
          </>
        )}
      {displayReportConmfigField?.displayPortScanningResult && (
        <ReportConfigTable
          tableName="Port Scanning Result"
          tableAction="portScanningResult"
          columns={portScaningColumns}
          data={
            scanReportConfig?.portScanningResult
              ? scanReportConfig?.portScanningResult
              : []
          }
          cellWidth={"50%"}
          tableWidth={"85%"}
          valueLabel={["url", "openPorts"]}
          handleSave={handleSaveTableData}
          url="port-scan-result"
          isEdit={
            scanReportConfig?.portScanningResult?.length > 0 ? false : true
          }
          allowUpdate={updateReportConfig}
        />
      )}
      {displayReportConmfigField?.displaySoftwareUsed && (
        <Stack direction="column" gap={3}>
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
            direction={"row"}
          >
            <Typography variant="h2">Tool Used</Typography>
            {updateReportConfig && (
              <IconButton
                variant="contained"
                onClick={() => {
                  setEditToolUsed(!editToolUsed);
                  setToolUsed(scanReportConfig?.softwareUsed);
                }}
                title={editToolUsed ? "Edit" : "Cancel"}
                color="inherit"
                size="large"
                aria-label="live customize"
              >
                {editToolUsed ? <ModeEditIcon /> : <CloseIcon />}
              </IconButton>
            )}
            {/* ) : (
              <></>
            )} */}
          </Stack>
          <SubCard sx={{ minHeight: 100 }}>
            <TextField
              multiline
              rows={3}
              fullWidth
              value={toolUsed}
              onChange={(e) => setToolUsed(e?.target?.value)}
              disabled={editToolUsed}
            />
            {/* <Box
            dangerouslySetInnerHTML={{
              __html: scanReportConfig?.conclusion,
            }}
          ></Box> */}

            {!editToolUsed && (
              <IconButton
                variant="contained"
                onClick={() => handleSavetextAreaDetail("software-used")}
                sx={{
                  alignSelf: "flex-end",
                  marginTop: "10px",
                  marginBottom: "5px",
                  float: "right",
                }}
                color="inherit"
                size="large"
                aria-label="live customize"
                title="Save"
              >
                <SaveIcon />
              </IconButton>
            )}
          </SubCard>
        </Stack>
      )}

      {displayReportConmfigField?.displayConclusion && (
        <Stack direction="column" gap={3}>
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
            direction={"row"}
          >
            <Typography variant="h2">Conclusion</Typography>
            {updateReportConfig && (
              <IconButton
                variant="contained"
                onClick={() => {
                  setEditConclusion(!editConclusion);
                  setConclusion(scanReportConfig?.conclusion);
                }}
                title={editConclusion ? "Edit" : "Cancel"}
                color="inherit"
                size="large"
                aria-label="live customize"
              >
                {editConclusion ? <ModeEditIcon /> : <CloseIcon />}
              </IconButton>
            )}
            {/* ) : (
              <></>
            )} */}
          </Stack>
          <SubCard sx={{ minHeight: 100 }}>
            <TextField
              multiline
              rows={3}
              fullWidth
              value={conclusion}
              onChange={(e) => setConclusion(e?.target?.value)}
              disabled={editConclusion}
            />
            {/* <Box
            dangerouslySetInnerHTML={{
              __html: scanReportConfig?.conclusion,
            }}
          ></Box> */}

            {!editConclusion && (
              <IconButton
                variant="contained"
                onClick={() => handleSavetextAreaDetail("conclusion")}
                sx={{
                  alignSelf: "flex-end",
                  marginTop: "10px",
                  marginBottom: "5px",
                  float: "right",
                }}
                color="inherit"
                size="large"
                aria-label="live customize"
                title="Save"
              >
                <SaveIcon />
              </IconButton>
            )}
          </SubCard>
        </Stack>
      )}
      {/* <DialogBox
        maxWidth="sm"
        modelTitle={selectedReportTitle?.title}
        open={open}
        handleClose={handleClose}
        dialogContent={
          <ReportQuillContent
            selectedReportTitle={selectedReportTitle}
            reportConfig={scanReportConfig}
            handleClose={handleClose}
          />
        }
      /> */}
    </Stack>
  );
};

export default ReportConfiguration;
