import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";

// initial state
const initialState = {
  showCustimzeLayoutOption: false,
};

// ==============================|| SLICE - COSTOMIZE LAYOUT ||============================== //

const customizeLayout = createSlice({
  name: "customizeLayout",
  initialState,
  reducers: {
    toggleCustomizeLayout(state, action) {
      state.showCustimzeLayoutOption = action.payload;
    },
  },
});

export default customizeLayout.reducer;
export const { toggleCustomizeLayout } = customizeLayout.actions;
